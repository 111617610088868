import axiosApiInstance from "../../helpers/interceptor";

let getUserData = "users/getUser";
let getOtherUserData = "users/getUserById";
let getRoleOptions = "users/predeterminedRoles";
let getCurrentRoles = "users/currentRoles";
let getFilteredUsers = "users/filteredUsers";
let createUser = "users/create";
let restorePassword = "users/restorePassword";
let resetOwnPassword = "users/resetOwnPassword";
let changeOwnInfo = "users/editOwnInfo";
let changeUserInfo = "users/editUserInfo";
let toggleUserStatus = "users/toggleUserStatus";
let deleteUser = "users/deleteUser";
let changeUserRoles = "users/changeUserRoles";
let changeOwnRoles = "users/changeOwnRoles";

export const getUserDataAPI = async (): Promise<UserData | null> => {
  try {
    let userDataResponse = await axiosApiInstance.get<UserData>(getUserData);
    return userDataResponse.data;
  } catch {
    return null;
  }
};

export const getOtherUserDataAPI = async (
  id: string
): Promise<UserData | null> => {
  try {
    let userDataResponse = await axiosApiInstance.get<UserData>(
      `${getOtherUserData}/${id}`
    );
    return userDataResponse.data;
  } catch {
    return null;
  }
};

export const getRoleOptionsAPI = async (): Promise<PredeterminedRoles[]> => {
  try {
    let rolesResponse = await axiosApiInstance.get<PredeterminedRoles[]>(
      getRoleOptions
    );
    return rolesResponse.data;
  } catch {
    return [];
  }
};

export const getCurrentRolesAPI = async (): Promise<string[]> => {
  try {
    let rolesResponse = await axiosApiInstance.get<string[]>(getCurrentRoles);
    return rolesResponse.data;
  } catch {
    return [];
  }
};

export const createUserAPI = async (req: CreateUserRequest) => {
  try {
    let response = await axiosApiInstance.post<CreateUserResponse>(
      createUser,
      req
    );
    return { data: response.data, error: "" };
  } catch {
    return { data: null, error: "error inesperado" };
  }
};

export const changeOwnInfoAPI = async (
  req: ChangeInfoRequest
): Promise<{ error: string; userData: UserData | null }> => {
  try {
    let response = await axiosApiInstance.post<UserData>(changeOwnInfo, req);
    return { userData: response.data, error: "" };
  } catch {
    return { error: "error", userData: null };
  }
};

export const changeUserInfoAPI = async (
  id: string,
  req: ChangeInfoRequest
): Promise<{ error: string; userData: UserData | null }> => {
  try {
    let response = await axiosApiInstance.post<UserData>(
      `${changeUserInfo}/${id}`,
      req
    );
    return { userData: response.data, error: "" };
  } catch {
    return { error: "error", userData: null };
  }
};

export const toggleUserStatusAPI = async (
  id: string
): Promise<{ error: string; userData: UserData | null }> => {
  try {
    let response = await axiosApiInstance.get<UserData>(
      `${toggleUserStatus}/${id}`
    );
    return { userData: response.data, error: "" };
  } catch {
    return { error: "error", userData: null };
  }
};

export const deleteUserAPI = async (id: string) => {
  try {
    let response = await axiosApiInstance.delete<boolean>(
      `${deleteUser}/${id}`
    );
    return { result: response.data, error: "" };
  } catch {
    return { error: "error", result: false };
  }
};

export const restorePasswordAPI = async (id: string) => {
  try {
    let userDataResponse =
      await axiosApiInstance.post<ResetBackofficePasswordResponse>(
        `${restorePassword}/${id}`
      );
    return userDataResponse.data;
  } catch {
    return null;
  }
};

export const resetOwnPasswordAPI = async (req: ChangeOwnPasswordReq) => {
  try {
    let resetOwnPasswordRes = await axiosApiInstance.post<boolean>(
      `${resetOwnPassword}`,
      req
    );
    return { result: resetOwnPasswordRes.data, message: "" };
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      return { result: false, message: "Contraseña incorrecta" };
    } else if (e.response && e.response.status === 500) {
      return { result: false, message: "Error con servidor. Intenta de nuevo" };
    } else {
      return { result: false, message: "Error inesperado. Intenta de nuevo" };
    }
  }
};

export const changeUserRolesAPI = async (req: ChangeUserRolesReq) => {
  try {
    let result = await axiosApiInstance.post<UserData>(
      `${changeUserRoles}`,
      req
    );
    return { result: result.data, message: "" };
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      return {
        result: null,
        message: "No tiene permiso para realizar esta acción",
      };
    } else if (e.response && e.response.status === 500) {
      return { result: null, message: "Error con servidor. Intenta de nuevo" };
    } else {
      return { result: null, message: "Error inesperado. Intenta de nuevo" };
    }
  }
};

export const changeOwnRolesAPI = async (req: ChangeUserRolesReq) => {
  try {
    let result = await axiosApiInstance.post<UserData>(
      `${changeOwnRoles}`,
      req
    );
    return { result: result.data, message: "" };
  } catch (e: any) {
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      return {
        result: null,
        message: "No tiene permiso para realizar esta acción",
      };
    } else if (e.response && e.response.status === 500) {
      return { result: null, message: "Error con servidor. Intenta de nuevo" };
    } else {
      return { result: null, message: "Error inesperado. Intenta de nuevo" };
    }
  }
};

export const getFilteredUsersAPI = async (
  request: FilteredUsersRequest
): Promise<{
  error: string;
  filteredUsers: FilteredUsersResponse | null;
}> => {
  try {
    let filteredUsers = await axiosApiInstance.post(getFilteredUsers, request);
    return {
      error: "",
      filteredUsers: {
        ...filteredUsers.data,
        currentPage: request.page,
      },
    };
  } catch (e: any) {
    if (e.response.status === 500) {
      return { filteredUsers: null, error: e.response.data };
    } else if (e.response.status === 404) {
      return { filteredUsers: null, error: e.response.data };
    } else {
      return { filteredUsers: null, error: e.response.data };
    }
  }
};

export const getAllUsersAPI = async (): Promise<UserData[] | null> => {
  try {
    let users = await axiosApiInstance.get<UserData[]>("users/allUsers");
    return users.data;
  } catch (e: any) {
    if (e.response.status === 500) {
      return null;
    } else if (e.response.status === 404) {
      return null;
    } else {
      return null;
    }
  }
};

export const getFilteredEventsAPI = async (
  request: GetFilteredEventsRequest
): Promise<{
  error: string;
  filteredEvents: FilteredEventsResponse | null;
}> => {
  try {
    let filteredEvents = await axiosApiInstance.post(
      "history/getFilteredEvents",
      request
    );
    return {
      error: "",
      filteredEvents: {
        ...filteredEvents.data,
        currentPage: request.page,
      },
    };
  } catch (e: any) {
    if (e.response.status === 500) {
      return { filteredEvents: null, error: e.response.data };
    } else if (e.response.status === 404) {
      return { filteredEvents: null, error: e.response.data };
    } else {
      return { filteredEvents: null, error: e.response.data };
    }
  }
};

export interface UserData {
  id: string;
  name: string;
  userName: string;
  email: string;
  roles: string[];
  phoneNumber: string;
  identification: string;
  userId: string;
  active: boolean;
  provitionalPassword: boolean;
  password?: string;
  repeatPassword?: string;
  generatedToken?: string;
}

export interface UserDataErrorMessage {
  nameError: string;
  emailError: string;
  phoneNumberError: string;
  identificationError: string;
}

export interface ChangeOwnPasswordReq {
  newPassword: string;
  oldPassword: string;
}

export interface PasswordData {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface PasswordDataError {
  oldPasswordError: string;
  newPasswordError: string;
  newPasswordRepeatError: string;
}

export interface PredeterminedRoles {
  adminRole: RoleInfo;
  containedRoles: RoleInfo[];
}

export interface RoleInfo {
  role: string;
  roleLabel: string;
}

export interface FilteredUsersRequest {
  searchQuery: string | null;
  page: number;
  role: string | null;
}

export interface FilteredUsersResponse {
  users: UserData[];
  numPages: number;
  currentPage: number;
}

export interface CreateUserRequest {
  userName: string;
  roles: string[];
  password: string;
  name: string;
  email: string;
  userId: string;
  phoneNumber: string;
  identification: string;
}

export interface ChangeInfoRequest {
  name: string;
  email: string;
  phoneNumber: string;
  identification: string;
}

export interface ChangeUserRolesReq {
  userId: string;
  roles: string[];
}

export interface ResetBackofficePasswordResponse {
  generatedPassword: string;
}

export interface CreateUserResponse {
  userName: string;
  roles: string[];
  name: string;
  email: string;
  generatedPassword: string;
}

export interface GetFilteredEventsRequest {
  entityId: string | null;
  service: string | null;
  type: string | null;
  startedDate: string | null;
  finishedDate: string | null;
  userId: string | null;
  completed: boolean | null;
  page: number;
  pageSize?: number;
}

export interface FilteredEventsResponse {
  numPages: number;
  currentPage: number;
  events: EventData[];
}

export interface EventData {
  id: string;
  name: string;
  entityId: string;
  startedDate: string;
  finishedDate: string | null;
  description: string;
  userId: string;
  cliendId: string;
  service:
    | "COREID"
    | "FIRMA"
    | "HYPERFLOW"
    | "SMARTFLOW"
    | "MAGICFORMS"
    | "PAGARES"
    | "CLIENTS"
    | "USERS"
    | "ACCOUNT"
    | "ALERTS"
    | null;
  type: string;
  currentState: string | null;
  previousState: string | null;
  completedAction: boolean;
  metadata: { [key: string]: string };
}
