export interface SimpleInputProps {
  label: string;
  type?: string;
  value?: string;
  placeHolder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}
export const SimpleInput = (props: SimpleInputProps) => {
  return (
    <div>
      <div
        style={{
          fontSize: "12px",
          fontWeight: "600",
          color: "white",
          marginLeft: "3px",
          marginRight: "5px",
        }}
      >
        {props.label}
      </div>
      <div style={{ marginTop: "6px", marginLeft: "6px", marginBottom: "6px" }}>
        <input
          type={props.type || "text"}
          value={props.value}
          placeholder={props.placeHolder || ""}
          disabled={props.disabled}
          style={{
            fontSize: "11px",
            fontWeight: "500",
            color: "rgba(255,255,255,0.8)",
            minWidth: "34%",
            height: "35px",
            maxWidth: "180px",
            background: "rgba(255,255,255,0.15)",
            borderRadius: "6px",
            border: "none",
            paddingLeft: "10px",
          }}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};
export const SimpleInputLarge = (props: SimpleInputProps) => {
  return (
    <div>
      <div
        style={{
          fontSize: "12px",
          fontWeight: "600",
          color: "white",
          marginLeft: "3px",
          marginRight: "5px",
        }}
      >
        {props.label}
      </div>
      <div style={{ marginTop: "6px", marginLeft: "6px", marginBottom: "6px" }}>
        <input
          type={props.type || "text"}
          value={props.value}
          placeholder={props.placeHolder || ""}
          disabled={props.disabled}
          style={{
            fontSize: "11px",
            fontWeight: "500",
            color: "rgba(255,255,255,0.8)",
            minWidth: "34%",
            height: "35px",
            width: "240px",
            background: "rgba(255,255,255,0.15)",
            borderRadius: "6px",
            border: "none",
            paddingLeft: "10px",
          }}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};
