export interface AlertDashboardElementProps {
  dot: JSX.Element;
  title: string;
  subtitle: string;
  show?: boolean;
}

export const AlertDashboardElement = (props: AlertDashboardElementProps) => {
  if (props.show === false) return null;
  return (
    <div className="flex-row" style={{ width: "15%" }}>
      {props.dot}
      <div
        className="flex-col"
        style={{
          justifyContent: "space-evenly",
          alignItems: "start",
          width: "85%",
          margin: "0 0 0 5px",
        }}
      >
        <div
          className="text-medium text-bold primary-color"
          style={{ textTransform: "uppercase" }}
        >
          {props.title}
        </div>

        <div
          className="text-small text-normal primary-color-35"
          style={{ width: "100%" }}
        >
          {props.subtitle}
        </div>
      </div>
    </div>
  );
};
