import { ClipLoader } from "react-spinners";
import {
  LoadingStatus,
  selectActiveServices,
} from "../../../common/commonSlice";
import { PredeterminedRoles } from "../../configurationAPI";
import { ReactComponent as ArrowUp } from "../../../../Assets/common/images/upMenu.svg";
import { ReactComponent as ArrowDown } from "../../../../Assets/common/images/downMenu.svg";
import { Checkbox } from "@material-ui/core";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  addRolesToCurrent,
  removeRolesFromCurrent,
} from "../../configurationSlice";

export interface RolesComponentProps {
  width?: string;
  predeterminedRoles: PredeterminedRoles[];
  currentRoles: string[];
  immutableRoles: string[];
  loading: LoadingStatus;
  cancelChangesFunction: Function;
  changeRolesFunction: Function;
  visibility: "hidden" | "visible";
}

export const RolesComponent = (props: RolesComponentProps) => {
  let dispatch = useAppDispatch();
  const [openList, setOpenList] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [personalizedOpen, setPersonalizedOpen] = useState(false);

  const [inputPersonalized, setInputPersonalized] = useState<string>("");

  const getPersonalizedRoles = () => {
    let personalizedRolesTemp = props.currentRoles;
    props.predeterminedRoles.forEach((prede) => {
      personalizedRolesTemp = personalizedRolesTemp.filter((rolex) => {
        return rolex !== prede.adminRole.role;
      });

      prede.containedRoles.forEach((roleInfoPrede) => {
        personalizedRolesTemp = personalizedRolesTemp.filter((rolex) => {
          return rolex !== roleInfoPrede.role;
        });
      });
    });
    personalizedRolesTemp = personalizedRolesTemp.filter((rolex) => {
      return (
        rolex !== "Client" &&
        rolex !== "User" &&
        rolex !== "Admin" &&
        rolex !== "SuperAdmin"
      );
    });

    return personalizedRolesTemp;
  };

  const didRolesChange = () => {
    let first = props.currentRoles.every((item) =>
      props.immutableRoles.includes(item)
    );

    let second = props.immutableRoles.every((item) =>
      props.currentRoles.includes(item)
    );

    return !first || !second;
  };

  const changeOpenList = (indicator: number) => {
    let tempList = openList;
    let personalizedShouldClose = false;
    for (let i = 0; i < openList.length; i++) {
      if (i == indicator) {
        personalizedShouldClose = true;
        tempList[i] = !tempList[i];
      } else {
        tempList[i] = false;
      }
    }
    setOpenList([...tempList]);
    if (indicator < 0) {
      setPersonalizedOpen(true);
    } else {
      setPersonalizedOpen(!personalizedShouldClose);
    }
  };

  const changePersonalized = (role: string, action: "add" | "remove") => {
    let isPredetermined = false;
    props.predeterminedRoles.every((v) => {
      if (v.adminRole.role === role) {
        isPredetermined = true;
        return false;
      }
      v.containedRoles.every((c) => {
        if (c.role === role) {
          isPredetermined = true;
          return false;
        }
      });
    });

    if (props.currentRoles.includes(role)) {
      isPredetermined = true;
    }

    switch (action) {
      case "add":
        if (!isPredetermined) {
          dispatch(addRolesToCurrent([role]));
        }
        setInputPersonalized("");

        break;
      case "remove":
        dispatch(removeRolesFromCurrent([role]));
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: props.width,
        visibility: props.visibility,
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "8px 0px 8px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: "15px",
          backgroundColor: "rgba(0,0,0,0.4)",
          color: "rgba(255,255,255, 0.35)",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        Roles
      </div>
      <div
        style={{
          width: "100%",
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          fontSize: "13px",
          backgroundColor: "rgba(0,0,0,0.2)",
          minHeight: "280px",
          borderRadius: "0px 0px 8px 8px",
          overflow: "auto",
        }}
      >
        {props.loading === "pending" || openList.length === 0 ? (
          <ClipLoader color="#FFF" size="120px" />
        ) : (
          <div style={{ borderRadius: "8px", overflow: "auto", width: "100%" }}>
            {props.predeterminedRoles.map((roleData, i) => {
              if (
                !props.immutableRoles.includes("users.edit") &&
                !props.immutableRoles.includes("users.admin") &&
                roleData.adminRole.role === "users.admin"
              ) {
                return <div key={i}></div>;
              } else {
                return (
                  <div key={i} style={{ width: "100%", marginBottom: "2px" }}>
                    <RoleItem
                      predeterminedRoles={roleData}
                      currentRoles={props.currentRoles}
                      expanded={openList[i]}
                      expandFunction={() => {
                        changeOpenList(i);
                      }}
                    />
                  </div>
                );
              }
            })}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    padding: "12px 12px 12px 12px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgba(0,0,0,0.1)",
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#FFF",
                      margin: "0",
                    }}
                  >
                    Roles personalizados
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {personalizedOpen ? (
                      <ArrowUp
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonalizedOpen(false);
                        }}
                      />
                    ) : (
                      <ArrowDown
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          changeOpenList(-1);
                        }}
                      />
                    )}
                  </div>
                </div>
                {personalizedOpen && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "2% 2% 1% 2%",
                      background: "rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <form>
                        <input
                          value={inputPersonalized}
                          onChange={(e) => {
                            setInputPersonalized(e.target.value);
                          }}
                          type="text"
                          style={{
                            border: "0.5px solid rgba(255,255,255,0.2)",
                            borderRadius: "6px",
                            background: "rgba(255,255,255,0.05)",
                            padding: "5px 0px 5px 0px",
                            minHeight: "24px",
                            fontSize: "12px",
                            fontWeight: "600",
                            textAlign: "center",
                            color: "#FFF",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            if (inputPersonalized.length > 0) {
                              changePersonalized(inputPersonalized, "add");
                            }
                          }}
                          style={{
                            width: "100%",
                            border: "none",
                            borderRadius: "8px",
                            background: "rgba(0,0,0,0.2)",
                            color: "white",
                            padding: "4px 0px 4px 0px",
                            marginBottom: "20px",
                          }}
                        >
                          Agregar Permiso
                        </button>
                      </form>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto auto",
                        width: "80%",
                      }}
                    >
                      {getPersonalizedRoles().map((role, rl) => {
                        return (
                          <div
                            key={rl}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              margin: "5px 5px 5px 8px",
                              border: "none",
                              borderRadius: "8px",
                              background: "rgba(0,0,0,0.2)",
                              padding: "2px 0 2px 6px",
                              maxHeight: "25px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                padding: "0 5px 0 3px",
                                fontSize: "11px",
                                fontWeight: "700",
                                textAlign: "center",
                                color: "#FFF",
                              }}
                            >
                              {role}
                            </div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                style={{
                                  borderLeft: "1px solid #FFF",
                                  height: "18px",
                                }}
                              ></div>
                              <div
                                onClick={(e) => {
                                  e.preventDefault();
                                  changePersonalized(role, "remove");
                                }}
                                style={{
                                  padding: "0 8px 0 8px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  textAlign: "center",
                                  color: "#FFF",
                                  background: "rgba(0,0,0,0.4)",
                                  borderRadius: "0 8px 8px 0",
                                  cursor: "pointer",
                                }}
                              >
                                X
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {didRolesChange() && (
          <div
            style={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                props.cancelChangesFunction();
              }}
              style={{
                padding: "8px 10px 8px 10px",
                textAlign: "center",
                color: "#FFF",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px",
                border: "none",
              }}
            >
              Cancelar cambios
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                props.changeRolesFunction();
              }}
              style={{
                padding: "8px 10px 8px 10px",
                textAlign: "center",
                color: "#FFF",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px",
                border: "none",
                maxWidth: "120px",
              }}
            >
              Cambiar roles
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const RoleItem = (props: {
  predeterminedRoles: PredeterminedRoles;
  currentRoles: string[];
  expanded: boolean;
  expandFunction: Function;
}) => {
  const dispatch = useAppDispatch();
  const isIndeterminate = () => {
    let counter = 0;
    props.predeterminedRoles.containedRoles.forEach((role) => {
      if (props.currentRoles.includes(role.role)) {
        counter++;
      }
    });
    return (
      counter !== 0 &&
      counter !== props.predeterminedRoles.containedRoles.length
    );
  };

  const checked = (role: string) => {
    return props.currentRoles.includes(role);
  };

  const checkedMain = () => {
    let counter = 0;
    props.predeterminedRoles.containedRoles.forEach((role) => {
      if (props.currentRoles.includes(role.role)) {
        counter++;
      }
    });
    return counter === props.predeterminedRoles.containedRoles.length;
  };

  const almostCheckedMain = () => {
    let counter = 0;
    props.predeterminedRoles.containedRoles.forEach((role) => {
      if (props.currentRoles.includes(role.role)) {
        counter++;
      }
    });
    return counter === props.predeterminedRoles.containedRoles.length - 1;
  };

  const mainChangeRole = () => {
    let tempList: string[] = [];
    props.predeterminedRoles.containedRoles.forEach((role) => {
      tempList.push(role.role);
    });
    tempList.push(props.predeterminedRoles.adminRole.role);
    if (!checkedMain() || isIndeterminate()) {
      dispatch(addRolesToCurrent(tempList));
    } else {
      dispatch(removeRolesFromCurrent(tempList));
    }
  };

  const changeRole = (role: string) => {
    let tempList: string[] = [role];

    if (!checked(role)) {
      if (almostCheckedMain()) {
        tempList.push(props.predeterminedRoles.adminRole.role);
      }
      dispatch(addRolesToCurrent(tempList));
    } else {
      tempList.push(props.predeterminedRoles.adminRole.role);
      dispatch(removeRolesFromCurrent(tempList));
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            padding: "2px 12px 2px 12px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            background: "rgba(0,0,0,0.1)",
            width: "100%",
          }}
        >
          <h4
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "#FFF",
              margin: "0",
            }}
          >
            {props.predeterminedRoles.adminRole.roleLabel}
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Checkbox
              style={{ color: "#FFF" }}
              indeterminate={isIndeterminate()}
              checked={checkedMain()}
              onChange={() => {
                mainChangeRole();
              }}
            />
            {props.expanded ? (
              <ArrowUp
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  props.expandFunction();
                }}
              />
            ) : (
              <ArrowDown
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  props.expandFunction();
                }}
              />
            )}
          </div>
        </div>
        {props.expanded && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2% 5% 1% 5%",
              background: "rgba(0,0,0,0.2)",
            }}
          >
            {props.predeterminedRoles.containedRoles.map((roleDetail, e) => {
              return (
                <div
                  key={e}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0px 8px 0px 8px",
                    background: "rgba(0,0,0,0.1)",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "0 0 8px 0",
                  }}
                >
                  <h5
                    style={{
                      margin: "0",
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#FFF",
                    }}
                  >
                    {roleDetail.roleLabel}
                  </h5>
                  <Checkbox
                    style={{ color: "#FFF" }}
                    checked={checked(roleDetail.role)}
                    onChange={() => {
                      changeRole(roleDetail.role);
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
