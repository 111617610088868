import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Modal, { Styles } from "react-modal";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeActiveComponent,
  cleanCurrentRoles,
  selectActiveConfigurationComponent,
} from "../configurationSlice";
import { useEffect, useState } from "react";
import {
  changePageStateClients,
  getFilteredClients,
  selectClientPage,
  selectClients,
  selectFilteredClientsRequest,
  selectLoadingClients,
  selectNumPagesClients,
} from "./clientsSlice";
import { formatDateStandar } from "../../../helpers/utils";
import { selectIsMetaClient, selectRoles } from "../../common/commonSlice";
import { ReactComponent as DeleteTag } from "../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../Assets/common/images/addTag.svg";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByService,
  selectAllTags,
  selectCurrentService,
  selectCurrentTagCreation,
  selectCurrentTagsByService,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByService,
  setTagCreation,
} from "../../services/tags/tagSlice";

Modal.setAppElement("#root");

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

export const SubClients = () => {
  let clients = useAppSelector(selectClients);
  let currentPage = useAppSelector(selectClientPage);
  let currentNumPages = useAppSelector(selectNumPagesClients);
  let loadingStatus = useAppSelector(selectLoadingClients);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let currentFilteredRequest = useAppSelector(selectFilteredClientsRequest);
  let isMetaClient = useAppSelector(selectIsMetaClient);
  //roles stuff
  let roles = useAppSelector(selectRoles);

  // Tag Stuff
  let currentService = useAppSelector(selectCurrentService);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let loadingTagsByService = useAppSelector(selectLoadingCurrentTagsByService);
  let allTags = useAppSelector(selectAllTags);
  let currentServiceTags = useAppSelector(selectCurrentTagsByService);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState({ entityId: "" });

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages - 1) {
      dispatch(changePageStateClients(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageStateClients(currentPage - 1));
    }
  };

  const canCreate = () => {
    if (isMetaClient && roles.includes("clients.create")) {
      return true;
    }
    return false;
  };

  const getTagsRender = (entityId: string) => {
    if (loadingTagsByService == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gridRowGap: "5px",
          }}
        >
          {currentServiceTags.map((tag) => {
            if (tag.associatedEntities.includes(entityId)) {
              return (
                <div
                  className="flex-row"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.25)",
                    borderRadius: "8px",
                    padding: "3px 8px",
                    marginRight: "5px",
                    marginLeft: "5px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "11px",
                      fontWeight: 600,
                      color: "#fff",
                      marginRight: "5px",
                    }}
                  >
                    {tag.tag}
                  </div>
                  {roles.includes("tags.edit") && (
                    <DeleteTag
                      onClick={() => {
                        dispatch(
                          disassociateTag({
                            tagId: tag.id,
                            entityId: entityId,
                            service: "CLIENTS",
                          })
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              );
            } else return "";
          })}
          <div>
            {roles.includes("tags.edit") && (
              <AddTag
                onClick={() => {
                  if (addTagWindow.entityId === entityId) {
                    setAddTagWindow({ entityId: "" });
                  } else {
                    setAddTagWindow({ entityId: entityId });
                  }
                }}
                style={{ cursor: "pointer", margin: "5px 0 5px 5px" }}
              />
            )}
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow.entityId === entityId && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: entityId,
                              service: "CLIENTS",
                            })
                          );
                        }}
                      />
                      <button
                        style={{
                          height: "28px",
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag === "" ? "hidden" : "visible",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: entityId,
                                service: "CLIENTS",
                                tag: null,
                              })
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: entityId,
                                  service: "CLIENTS",
                                })
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (activeComponent !== "Clients") {
      dispatch(changeActiveComponent("Clients"));
    }

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentService !== "CLIENTS") {
      dispatch(getCurrentTagsByService("CLIENTS"));
    }

    dispatch(getFilteredClients({ ...currentFilteredRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {clients && clients.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStylesModal}
            contentLabel="Error de login"
            shouldCloseOnOverlayClick={true}
          >
            <div
              className="flex-col"
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            ></div>
          </Modal>
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "space-between",
              alignItems: "start",
              margin: "2% 4% 1% 1%",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ margin: "0 0 0 2%" }}
            >
              Subclientes
            </div>
            <div
              className="text-medium text-bold primary-color"
              style={{
                marginTop: "4%",
                marginRight: "2%",
                marginLeft: "1%",
                background: "rgba(0,0,0,0.4)",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "30px",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
                width: "180px",
                visibility: canCreate() ? "visible" : "hidden",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(cleanCurrentRoles());
                navigate(`/configuration/subclients/create`);
              }}
            >
              Crear cliente
            </div>
          </div>

          {clients ? (
            <div
              className="flex-col"
              style={{ height: "80%", margin: "1% 4% 0 0 " }}
            >
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre</th>
                      <th>NIT</th>
                      <th>Fecha de creación</th>
                      {roles.includes("tags.view") && <th>Etiquetas</th>}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {roles.includes("tags.view") && <td></td>}
                    </tr>
                    {clients.map((client, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px" }}
                        >
                          <td></td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`${client.id}`);
                            }}
                          >
                            {client.name}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`${client.id}`);
                            }}
                          >
                            {client.nit || "No registrado"}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`${client.id}`);
                            }}
                          >
                            {formatDateStandar(client.creationDate)}
                          </td>
                          {roles.includes("tags.view") && (
                            <td>{getTagsRender(client.id)}</td>
                          )}
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {roles.includes("tags.view") && <td></td>}
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "pending"
                  ? ""
                  : "No hay más clients en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{ marginRight: "100px", fontSize: "13px" }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {"<"}
              </div>
              Página {currentPage + 1} de {currentNumPages}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="text-medium text-bold primary-color"
            style={{
              marginTop: "4%",
              marginRight: "2%",
              marginLeft: "1%",
              background: "rgba(0,0,0,0.4)",
              paddingRight: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "30px",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
              width: "180px",
              visibility: canCreate() ? "visible" : "hidden",
            }}
            onClick={(e) => {
              e.preventDefault();
              dispatch(cleanCurrentRoles());
              navigate(`/configuration/subclients/create`);
            }}
          >
            Crear cliente
          </div>
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending"
              ? ""
              : "No hay más clients en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
