import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { UserData, UserDataErrorMessage } from "../configurationAPI";
import {
  changeActiveComponent,
  createUser,
  emptyGeneratedPassword,
  getRoleOptions,
  selectActiveConfigurationComponent,
  selectCreateUserLoading,
  selectCurrentRoles,
  selectGeneratedPassword,
  selectRoleOptions,
  selectRoleOptionsLoading,
} from "../configurationSlice";
import Modal, { Styles } from "react-modal";
import { CreateUserForm } from "./components/CreateUserForm";
import { RolesComponent } from "./components/RolesComponent";
import { useNavigate } from "react-router-dom";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const CreateUser = () => {
  let currentRoles = useAppSelector(selectCurrentRoles);
  let roleOptionsLoading = useAppSelector(selectRoleOptionsLoading);
  let roleOptions = useAppSelector(selectRoleOptions);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let createUserLoading = useAppSelector(selectCreateUserLoading);
  let generatedPassword = useAppSelector(selectGeneratedPassword);

  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);

  const closePasswordModal = () => {
    setCopied(false);
    dispatch(emptyGeneratedPassword());
    navigate("/configuration/users");
    setModalPasswordIsOpen(false);
  };

  const [copied, setCopied] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    active: true,
    email: "",
    id: "",
    identification: "",
    name: "",
    phoneNumber: "",
    provitionalPassword: false,
    roles: [],
    userId: "",
    userName: "",
    password: "",
    repeatPassword: "",
  });

  const [userDataError, setUserDataError] = useState<UserDataErrorMessage>({
    emailError: "",
    identificationError: "",
    nameError: "",
    phoneNumberError: "",
  });

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const createDisabled = () => {
    return (
      userDataError.emailError !== "" ||
      userDataError.identificationError !== "" ||
      userDataError.nameError !== "" ||
      userDataError.phoneNumberError !== "" ||
      userData.email.length === 0
    );
  };

  useEffect(() => {
    if (activeComponent !== "Users") {
      dispatch(changeActiveComponent("Users"));
    }
    if (roleOptions.length === 0 && roleOptionsLoading === "idle") {
      dispatch(getRoleOptions());
    }
    if (createUserLoading === "rejected" || createUserLoading === "resolved") {
      setModalPasswordIsOpen(true);
    }
  }, [createUserLoading]);
  return (
    <div
      style={{
        width: "96%",
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        padding: "1%",
      }}
    >
      <Modal
        isOpen={modalPasswordIsOpen}
        onRequestClose={closePasswordModal}
        style={customStylesModal}
        contentLabel="Restablecimiento de contraseña"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "5%",
              paddingRight: "5%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5 className="text-medium text-normal">
              Se ha restaurado la contraseña de manera correcta, a continuación
              se encuentra la contraseña generada.
            </h5>
            <div
              style={{
                border: "0.5px solid  rgba(0,0,0)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "5px 2px 5px 2px",
                margin: "4px 2px 10px 2px",
              }}
            >
              <h6
                style={{
                  margin: "0px 5px 0px 5px",
                  textAlign: "center",
                  fontSize: "22px",
                }}
              >
                {generatedPassword}
              </h6>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  try {
                    if (generatedPassword) {
                      navigator.clipboard.writeText(generatedPassword);
                      setCopied(true);
                    }
                  } catch {
                    setCopied(false);
                  }
                }}
                style={{
                  border: "none",
                  background: "transparent",
                  fontWeight: "700",
                }}
              >
                {copied ? "Copiado" : "Copiar"}
              </button>
            </div>
            <h5 className="text-medium text-normal">
              Se le pedirá al usuario que la restablesca en el primer inicio de
              sesión{" "}
            </h5>
          </div>
          <div></div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              closePasswordModal();
              e.preventDefault();
            }}
          >
            Cerrar
          </button>
        </div>
      </Modal>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "4%",
        }}
      >
        <CreateUserForm
          accountData={userData}
          errorAccountData={userDataError}
          setAccountData={setUserData}
          setErrorAccountData={setUserDataError}
          loading="idle"
          width="48%"
        />
        <RolesComponent
          cancelChangesFunction={() => {}}
          changeRolesFunction={() => {}}
          width="48%"
          immutableRoles={currentRoles}
          currentRoles={currentRoles}
          predeterminedRoles={roleOptions}
          loading={roleOptionsLoading}
          visibility={"visible"}
        />
      </div>
      <button
        disabled={createDisabled()}
        onClick={(e) => {
          e.preventDefault();
          dispatch(createUser(userData));
        }}
        style={{
          width: "115px",
          padding: "5px 0px 5px 0px",
          textAlign: "center",
          color: "#FFF",
          background: "rgba(0,0,0,0.3)",
          borderRadius: "8px",
          border: "none",
          maxWidth: "120px",
          alignSelf: "center",
        }}
      >
        Crear usuario
      </button>
    </div>
  );
};
