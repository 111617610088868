import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./RadioStyles.scss";
export interface RadioBtnProps {
  label: string;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const RadioButton = (props: RadioBtnProps) => {
  return (
    <div style={{ color: "white" }}>
      <FormControlLabel
        style={{ height: "17px" }}
        control={<Radio size="small" onClick={props.onClick} />}
        label={
          <div className="text-small text-normal primary-color">
            {props.label}
          </div>
        }
        checked={props.checked}
        onChange={props.onChange}
      />
    </div>
  );
};

export const RadioButtonMedium = (props: RadioBtnProps) => {
  return (
    <div style={{ color: "white" }}>
      <FormControlLabel
        style={{ height: "30px", width: "20px" }}
        control={<Radio size="medium" onClick={props.onClick} />}
        label={
          <div className="text-small text-normal primary-color">
            {props.label}
          </div>
        }
        checked={props.checked}
        onChange={props.onChange}
      />
    </div>
  );
};
