import React, { useEffect } from "react";
import "../firma/FirmaStyles.scss";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { RadioButton } from "../../common/components/Btns/RadioButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { Outlet, useNavigate } from "react-router-dom";
import {
  changeActiveComponent,
  changeCurrentFilters,
  selectActiveFirmaComponent,
  selectCurrentFilterRequest,
} from "./firmaNewSlice";
import { Category } from "../components/sidePanel/categories/Category";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import {
  changeFirmaFilters,
  resetStateAnalytics,
  selectAnalyticsFirmaRequest,
} from "../analytics/analyticsSlice";
import { DropDown } from "../../common/components/Btns/DropDown";
import { selectCurrentTagsByService } from "../tags/tagSlice";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
export const FirmaElectronicaNew = () => {
  let activeComponent = useAppSelector(selectActiveFirmaComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let analyticsRequest = useAppSelector(selectAnalyticsFirmaRequest);
  let roles = useAppSelector(selectRoles);
  let currentServiceTags = useAppSelector(selectCurrentTagsByService);

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "FirmasNew") {
      const searchInput = (
        <SearchInputFilter
          value={currentFilteredRequest.searchQuery}
          key={"SearchFirma"}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: null,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: e.target.value,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
          placeHolder="No. Identidad"
        />
      );
      let tagsDropdown = <></>;
      if (roles.includes("tags.view")) {
        let tagsOptions: { value: string; label: string }[] = [];
        let tags = currentServiceTags;
        if (tags) {
          for (const tag of tags) {
            let label = tag.tag;
            let value = tag.id;
            if (tag.associatedEntities.length > 0) {
              tagsOptions.push({ label: label!, value });
            }
          }
        }
        tagsDropdown = (
          <DropDown
            key={"Tags"}
            title="Etiqueta"
            options={tagsOptions}
            onChange={(e) => {
              if (e) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    tag: e?.value!,
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    tag: null,
                  })
                );
              }
            }}
          />
        );
      }
      return (
        <FilterComponent
          key={0}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                startDate: null,
                endDate: null,
              })
            );
          }}
          items={[
            <div
              key={2}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por estado
            </div>,
            <div key={3} style={{ alignItems: "center" }}>
              <RadioButton
                label="Firmado"
                checked={currentFilteredRequest.status === "Signed"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Signed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Signed",
                    })
                  );
                }}
              />
              <RadioButton
                label="Empezo proceso"
                checked={currentFilteredRequest.status === "Confirmed"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Confirmed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Confirmed",
                    })
                  );
                }}
              />
              <RadioButton
                label="Fallido"
                checked={currentFilteredRequest.status === "Failed"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Failed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Failed",
                    })
                  );
                }}
              />
            </div>,
            tagsDropdown,
            <div
              key={0}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por identificación
            </div>,
            searchInput,
          ]}
        />
      );
    } else if (activeComponent === "Analytics") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          calendarType="double"
          endDate={
            analyticsRequest.endDate ? new Date(analyticsRequest.endDate) : null
          }
          startDate={
            analyticsRequest.startDate
              ? new Date(analyticsRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeFirmaFilters({
                    ...analyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
                dispatch(
                  changeFirmaFilters({
                    ...analyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeFirmaFilters({
                    ...analyticsRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
        />
      );
    } else {
      return;
    }
  };

  const getCategoryItems = (): JSX.Element[] => {
    let items: JSX.Element[] = [];
    items.push(
      <Category
        key={0}
        name="Firmas realizadas"
        selected={activeComponent === "FirmasNew"}
        onClick={(e) => {
          e.preventDefault();
          navigate("/services/electronicSignatureNew/signatures");
        }}
      />
    );

    if (
      roles.includes("analytics.firma.view") ||
      roles.includes("analytics.admin")
    ) {
      items.push(
        <Category
          key={1}
          name="Analitica"
          last
          selected={activeComponent === "Analytics"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Firma Electrónica"));
            dispatch(changeActiveComponent("Analytics"));
            dispatch(resetStateAnalytics());
            navigate("/services/electronicSignatureNew/analytics");
          }}
        />
      );
    }
    return items;
  };
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    dispatch(changeCurrentProduct("Firma Electrónica"));
    dispatch(changeActiveComponent("FirmasNew"));
  }, []);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "90%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />

        <div
          className="flex-col"
          style={{
            width: "80%",

            justifyContent: "space-between",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
