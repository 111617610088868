export interface InputProps {
  label: string;
  value?: string;
  placeHolder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  invisible?: boolean;
  errorMessage?: string;
}

export const InputText = (props: InputProps) => {
  if (props.invisible) {
    return null;
  }
  return (
    <div style={{ margin: "0px 2% 0px 0px" }}>
      <div
        style={{
          fontSize: "12px",
          fontWeight: "600",
          color: "white",
          margin: "5px 0px 0px 5px",
        }}
      >
        {props.label}
      </div>
      <div style={{ marginTop: "6px", marginLeft: "2px" }}>
        <input
          value={props.value}
          placeholder={props.placeHolder || ""}
          disabled={props.disabled}
          style={{
            fontSize: "12px",
            fontWeight: "500",
            color: "rgba(255,255,255,0.8)",
            minWidth: "34%",
            height: "35px",
            maxWidth: "180px",
            background: "rgba(255,255,255,0.15)",
            borderRadius: "6px",
            border: "none",
            paddingLeft: "10px",
          }}
          onChange={props.onChange}
        />
      </div>
      <p
        style={{
          fontSize: "10px",
          color: "rgba(255, 105, 105, 0.7)",
          fontWeight: "400",
          textAlign: "end",
          margin: "2px 2px 0 0",
          visibility: props.errorMessage ? "visible" : "hidden",
        }}
      >
        {props.errorMessage}
      </p>
    </div>
  );
};
