import React from "react";
import { Link } from "react-router-dom";

export const GeneralHelp = () => {
  return (
    <div
      style={{
        overflow: "auto",
        color: "white",
        padding: "2% 2%",
        width: "100%",
      }}
    >
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "52px",
        }}
      >
        Introducción
      </h2>
      <p>
        El backoffice de anteia es una aplicación web que permite la gestión
        registros realizados en la plataforma de onboarding de anteia.
      </p>
      <p>
        Esta herramienta se divide en varias secciones dependiendo de sus
        necesidades, para entender cada una de las funcionalidades, conceptos y
        terminología utilizada en el backoffice, puede consultar la
        documentación de cada una de las secciones.
        <br />{" "}
        <strong style={{ color: "#C7FF4F" }}>
          Estas las podrá encontrar en el panel izquierdo "Secciones de Ayuda",
          en donde podrá seleccionar sobre que sección desea obtener información
        </strong>
        .
      </p>
      <p>Algunos de los productos sobre los cuales podrá consultar son:</p>
      <ul>
        <li>
          <Link to="/configuration/help/hyperflow">HyperFlow</Link>
        </li>
        <li>
          <Link to="/configuration/help/coreid">CoreId</Link>
        </li>

        <li>
          <Link to="/configuration/help/magicforms">Magic Forms</Link>
        </li>
        <li>Firma Electrónica</li>
        <li>Pagarés</li>
        <li>Configuración y Administración</li>
      </ul>
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "46px",
        }}
      >
        Términos y conceptos
      </h2>
      <p>
        A continuación se describen algunos términos que serán utiles en el
        manejo de la herramienta de backoffice, así como algunos conceptos
        globales utilizados en la plataforma de onboarding de anteia.
      </p>
      <h3 style={{ fontWeight: "bold", fontSize: "26px" }}>
        Identidad digital
      </h3>
      <p>
        Es el conjunto de rasgos propios de un individuo, o de una colectividad,
        que los caracterizan frente a los demás. En seguridad se usa para hablar
        de individuos y los atributos que los hacen únicos. En el caso digital
        se trata de esos elementos que pueden ser representados en activos
        digitales, tales como imagen del documento de identidad, validación de
        correo electrónico, dispositivo, formularios, etc.
      </p>

      <h3 style={{ fontWeight: "bold", fontSize: "26px" }}>Biometría</h3>
      <p>
        Es el método para verificar la identidad de una persona a través de
        mediciones únicas de características biológicas o de comportamiento. Es
        una de las formas más efectivas de confirmar la identidad de una
        persona, ya que los datos biométricos son únicos para cada individuo
      </p>
    </div>
  );
};
