import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useQueryParams } from "../../helpers/utils";
import {
  checkResetPasswordToken,
  resetPassword,
  selectLoadingRecovery,
  selectLoadingResetPassword,
  selectResetPasswordError,
} from "./loginSlice";
import "./loginStyles.scss";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.5%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const ResetPassword = () => {
  let loadingRecovery = useAppSelector(selectLoadingRecovery);
  let loadingChangePassword = useAppSelector(selectLoadingResetPassword);
  let changePasswordError = useAppSelector(selectResetPasswordError);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let query = useQueryParams();
  let token = query.get("token");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!token || token === "") {
      navigate("/login");
    } else if (loadingRecovery === "idle") {
      dispatch(checkResetPasswordToken(token));
    }

    if (changePasswordError !== "" && !modalIsOpen) {
      setIsOpen(true);
    }

    return () => {};
  }, []);

  if (loadingRecovery === "pending") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ClipLoader color="#ffffff" size={"150px"} />
        <h5 style={{ color: "#ffffff", margin: "30px 0 0 0 " }}>Procesando</h5>
      </div>
    );
  } else if (loadingRecovery === "rejected") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal
          isOpen={true}
          onRequestClose={() => {
            navigate("/login");
          }}
          style={customStylesModal}
          contentLabel="Error de token"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              El enlace de restablecimiento de contraseña ya no es válido
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();

                navigate("/login");
              }}
            >
              Intente nuevamente
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStylesModal}
        contentLabel="Error de cambio de contraseña"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "25%",
              paddingRight: "25%",
            }}
          >
            {changePasswordError}
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            Intente nuevamente
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={loadingChangePassword === "resolved"}
        onRequestClose={() => {
          navigate("/login");
        }}
        style={customStylesModal}
        contentLabel="Cambio exitoso"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            ¡La contraseña se ha restablecido de manera exitosa! <br />
            Puede volver a la página de inicio de sesión
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/login");
            }}
          >
            Iniciar sesión
          </button>
        </div>
      </Modal>
      <h2 style={{ color: "#fff", fontWeight: "600", margin: "0 0 40px 0" }}>
        Restablecer contraseña
      </h2>
      <p
        style={{
          color: "#fff",
          fontWeight: "400",
          textAlign: "start",
          width: "350px",
        }}
      >
        A continuación ingresa tu nueva contraseña para poder restablecerla.
      </p>
      <p
        style={{
          color: "rgba(255,255,255,0.5)",
          fontWeight: "400",
          textAlign: "start",
          fontSize: "12px",
          width: "350px",
        }}
      >
        Por su seguridad y la de su compañia recomendamos que ingrese en su
        nueva contraseña números, caracteres en mayúscula, minúscula y símbolos
        como: !\-&$*).
      </p>
      <form
        action="POST"
        onSubmit={(e) => e.preventDefault()}
        style={{
          width: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          margin: "20px 0 0 0",
        }}
      >
        <label
          className="text-normal text-small primary-color "
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
            alignItems: "center",
            margin: "0 0 40px 0",
          }}
        >
          <p style={{ margin: "0", alignSelf: "start" }}>Nueva Contraseña</p>
          <input
            style={{
              width: "100%",
              height: "40px",
              background: "rgba(255,255,255,0.1)",
              border: "0.5px solid rgba(255,255,255,0.1)",
              boxSizing: "border-box",
              borderRadius: "6px",
              textAlign: "center",
            }}
            type="password"
            value={newPassword}
            disabled={loadingChangePassword === "pending"}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            className="text-normal text-small primary-color-80 "
          />
          {newPassword.length > 0 && newPassword.length < 8 && (
            <p
              style={{
                width: "100%",
                fontSize: "10px",
                color: "#cf4259",
                margin: "2px 0 0 0",
                textAlign: "start",
              }}
            >
              Ingrese un mínimo de 8 caracteres
            </p>
          )}
          {newPassword.length >= 8 && newPassword !== confirmPassword && (
            <p
              style={{
                width: "100%",
                fontSize: "10px",
                color: "#cf4259",
                margin: "2px 0 0 0",
                textAlign: "start",
              }}
            >
              Las contraseñas no coinciden
            </p>
          )}
        </label>

        <label
          className="text-normal text-small primary-color "
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
            alignItems: "center",
            margin: "0 0 40px 0",
          }}
        >
          <p style={{ margin: "0", alignSelf: "start" }}>
            Confirmar nueva contraseña
          </p>
          <input
            style={{
              width: "100%",
              height: "40px",
              background: "rgba(255,255,255,0.1)",
              border: "0.5px solid rgba(255,255,255,0.1)",
              boxSizing: "border-box",
              borderRadius: "6px",
              textAlign: "center",
            }}
            type="password"
            value={confirmPassword}
            disabled={loadingChangePassword === "pending"}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            className="text-normal text-small primary-color-80 "
          />
          {confirmPassword.length > 0 && confirmPassword.length < 8 && (
            <p
              style={{
                width: "100%",
                fontSize: "10px",
                color: "#cf4259",
                margin: "2px 0 0 0",
                textAlign: "start",
              }}
            >
              Ingrese un mínimo de 8 caracteres
            </p>
          )}
          {confirmPassword.length >= 8 && newPassword !== confirmPassword && (
            <p
              style={{
                width: "100%",
                fontSize: "10px",
                color: "#cf4259",
                margin: "2px 0 0 0",
                textAlign: "start",
              }}
            >
              Las contraseñas no coinciden
            </p>
          )}
        </label>

        <button
          className="text-bold text-medium primary-color login-button"
          onClick={(e) => {
            e.preventDefault();
            dispatch(resetPassword({ newPassword, token: token! }));
          }}
          disabled={
            loadingChangePassword === "pending" ||
            newPassword.length < 8 ||
            confirmPassword.length < 8 ||
            newPassword !== confirmPassword
          }
        >
          Restablecer contraseña
        </button>
      </form>
    </div>
  );
};
