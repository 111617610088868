import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as Carga } from "../../../../Assets/common/images/carga.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  generateUrl,
  getPagareOptions,
  resetCreatePagare,
  selectActivePagareComponent,
  selectCreatePagareOptions,
  selectCurrentPagareCreate,
  selectGeneratedUrl,
  selectLoadingCreatePagare,
  selectLoadingPagareOptions,
  updatePagareCreate,
} from "../pagareSlice";
import ClipLoader from "react-spinners/ClipLoader";
import Modal, { Styles } from "react-modal";
import { DropDownPagare } from "../../../common/components/Btns/DropDown";
import { arrayBufferToBase64 } from "../utils";
import { SimpleInputLarge } from "./components/SimpleInput";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const PagareCreate = () => {
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let pagareCreateOptions = useAppSelector(selectCreatePagareOptions);
  let loadingOptions = useAppSelector(selectLoadingPagareOptions);
  let loadingCreate = useAppSelector(selectLoadingCreatePagare);
  let createPagareForm = useAppSelector(selectCurrentPagareCreate);
  let generatedUrl = useAppSelector(selectGeneratedUrl);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let { id } = useParams();

  useEffect(() => {
    if (!pagareCreateOptions && loadingOptions === "idle") {
      dispatch(getPagareOptions());
    }

    if (activeComponent == "none") {
      dispatch(changeActiveComponent("none"));
    }
  }, [id]);

  return (
    <LoadingOverlay
      active={loadingOptions === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Error de búsqueda"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Tipo de archivo inválido
          </div>
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Recuerda que para que pueda ser firmado el archivo debe ser de
            formato PDF
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cerrar
          </button>
        </div>
      </Modal>
      <div
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-xx-large text-bold primary-color">
            Crear pagaré
          </div>
        </div>
        <div
          style={{
            width: "95%",
            height: "85%",
            borderRadius: "10px",
            padding: "2%",
            background: "rgba(0,0,0,0.2)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "row",
                    justifyContent: "start",
                    marginBottom: "1%",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      cursor: "pointer",
                      marginRight: "2%",
                    }}
                    htmlFor="fileInput"
                  >
                    <input
                      style={{
                        display: "none",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onChange={async (e) => {
                        let file = e.target.files ? e.target.files[0] : null;
                        let name = "";
                        let type = "";
                        let fileBase64 = "";
                        if (file) {
                          name = file.name;
                          type = file.type;
                          let temp = await file.arrayBuffer();
                          fileBase64 = arrayBufferToBase64(temp);
                        }
                        console.log(type);
                        if (type === "application/pdf") {
                          dispatch(
                            updatePagareCreate({
                              ...createPagareForm,
                              documentBase64: fileBase64,
                              documentName: name,
                            })
                          );
                        } else {
                          setModalIsOpen(true);
                        }
                      }}
                      type="file"
                      id="fileInput"
                    />

                    <Carga />
                    <div
                      style={{
                        fontSize: "18px",
                        lineHeight: "28px",
                        fontWeight: "400",
                        color: "white",
                        margin: "8px",
                      }}
                    >
                      Cargar archivo
                    </div>
                  </label>
                  <SimpleInputLarge
                    onChange={(e) => {
                      let name = e.target.value.replace(".pdf", "");
                      console.log(name);
                      if (
                        !e.target.value.endsWith(".pdf") &&
                        e.target.value !== ""
                      ) {
                        name = `${name.split(".")[0]}.pdf`;
                      } else {
                        name = e.target.value;
                      }
                      dispatch(
                        updatePagareCreate({
                          ...createPagareForm,
                          documentName: name,
                        })
                      );
                    }}
                    label={"Nombre del documento"}
                    disabled={false}
                    value={createPagareForm.documentName}
                  />
                </div>
                <SimpleInputLarge
                  placeHolder="Opcional"
                  onChange={(e) => {
                    dispatch(
                      updatePagareCreate({
                        ...createPagareForm,
                        redirectionUrl: e.target.value,
                      })
                    );
                  }}
                  label={"URL/Página de redirección"}
                  disabled={false}
                  value={createPagareForm.redirectionUrl}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  marginLeft: "5%",
                }}
              >
                <SimpleInputLarge
                  placeHolder=""
                  onChange={(e) => {
                    var reg = /^\d+$/;
                    if (reg.test(e.target.value)) {
                      dispatch(
                        updatePagareCreate({
                          ...createPagareForm,
                          value: Number.parseFloat(e.target.value),
                        })
                      );
                    }
                    if (e.target.value === "") {
                      dispatch(
                        updatePagareCreate({
                          ...createPagareForm,
                          value: 0,
                        })
                      );
                    }
                  }}
                  label={"Valor del pagaré"}
                  disabled={false}
                  value={createPagareForm.value.toString()}
                />
                <SimpleInputLarge
                  placeHolder="COP"
                  onChange={(e) => {
                    dispatch(
                      updatePagareCreate({
                        ...createPagareForm,
                        currency: e.target.value,
                      })
                    );
                  }}
                  label={"Moneda"}
                  disabled={false}
                  value={createPagareForm.currency}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  marginLeft: "5%",
                }}
              >
                {pagareCreateOptions && (
                  <DropDownPagare
                    title="Proyecto"
                    options={pagareCreateOptions.projectOptions.map(
                      (option) => {
                        return { label: option.name, value: option.id };
                      }
                    )}
                    defaultValue={{
                      label: pagareCreateOptions?.projectOptions[0].name || "",
                      value: pagareCreateOptions?.projectOptions[0].id || "",
                    }}
                    onChange={(e) => {
                      dispatch(
                        updatePagareCreate({
                          ...createPagareForm,
                          projectId: e?.value!,
                        })
                      );
                    }}
                  />
                )}
                {pagareCreateOptions && (
                  <DropDownPagare
                    title="Plantilla"
                    options={pagareCreateOptions.pagareTemplateOptions.map(
                      (option) => {
                        return { label: option.name, value: option.id };
                      }
                    )}
                    defaultValue={{
                      label:
                        pagareCreateOptions?.pagareTemplateOptions[0].name ||
                        "",
                      value:
                        pagareCreateOptions?.pagareTemplateOptions[0].id || "",
                    }}
                    onChange={(e) => {
                      dispatch(
                        updatePagareCreate({
                          ...createPagareForm,
                          pagareTemplateId: e?.value!,
                        })
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loadingCreate === "pending" ? (
                <ClipLoader color="white" loading={true} size={50} />
              ) : (
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    visibility:
                      loadingCreate !== "resolved" ? "collapse" : "visible",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      background: "rgba(0,0,0,0.1)",
                      padding: "10px",
                      borderRadius: "8px",
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                      marginRight: "2px",
                    }}
                  >
                    {generatedUrl}
                  </div>
                  <div
                    style={{
                      color: "white",
                      background: "rgba(0,0,0,0.1)",
                      padding: "10px",
                      borderRadius: "8px",
                      borderTopLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      try {
                        navigator.clipboard.writeText(generatedUrl);
                        setCopied(true);
                      } catch {
                        setCopied(false);
                      }
                    }}
                  >
                    {copied ? "Copiado!" : "Copiar"}
                  </div>
                </div>
              )}
              <div
                className="flex-col"
                style={{
                  justifyContent: "end",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  disabled={
                    createPagareForm.projectId.length === 0 ||
                    createPagareForm.pagareTemplateId.length === 0
                  }
                  style={{
                    border: "none",
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "6px",
                    color: "white",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    height: "35px",
                    width: "155px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (loadingCreate === "resolved") {
                      dispatch(resetCreatePagare());
                      navigate(-1);
                    } else {
                      dispatch(generateUrl());
                    }
                  }}
                >
                  {loadingCreate !== "resolved" ? "Confirmar" : "Finalizar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
