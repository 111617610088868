import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import { NumAlerts, getNumberAlerts } from "../alerts/alertsAPI";
import { getSearchQuery, SearchQuery, SearchQueryData } from "./BusquedaAPI";


export interface BusquedaState {
  loadingStatus: LoadingStatus;
  currentFilteredRequest: SearchQuery;
  datos: SearchQueryData;
  
}

const initialState: BusquedaState = {
  loadingStatus: "idle",
  currentFilteredRequest: {
    query: "",
  
  },
  datos: {
    coreIdResults: [],
    magicFormsResult: [],
    hyperFlowResult: [],
    elecSigResult: [],
  },
};

export const getSearch = createAsyncThunk(
  "busqueda/search",
  async (params: SearchQuery, { rejectWithValue }) => {
    try {
      const search = await getSearchQuery(params);
      if (search.registrations) {
        return search;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);


export const busquedaSlice = createSlice({
  name: "busquedaSlice",
  initialState,
  reducers: {
    resetRegistration: (state) => {
      state.loadingStatus = "idle";
      state.currentFilteredRequest ={
        query: "",
      };
      state.datos= {
        coreIdResults: [],
        magicFormsResult: [],
        hyperFlowResult: [],
        elecSigResult: [],
      };
    },
    changeCurrentQuery: (
      state,
      action: PayloadAction<SearchQuery>
    ) => {
      state.currentFilteredRequest = {
        ...action.payload,
      };
    },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearch.fulfilled, (state, action) => {
        let resquest = action.payload;

        if (resquest.registrations) {
          state.datos = resquest.registrations;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getSearch.rejected, (state) => {
        state.loadingStatus = "rejected";
      })
      .addCase(getSearch.pending, (state) => {
        state.loadingStatus = "pending";
      });
  },
});

export const {
  changeCurrentQuery,
  resetRegistration,
} = busquedaSlice.actions;


export const selectBusquedaLoading = (state: RootState) =>
  state.busqueda.loadingStatus;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.busqueda.currentFilteredRequest;
export const selectDatos = (state: RootState) =>
  state.busqueda.datos;

export default busquedaSlice.reducer;
