import axios from "axios";
import localforage from "localforage";

let baseUrl = process.env.REACT_APP_BASE_URL || "";

const axiosApiInstance = axios.create({ baseURL: baseUrl });

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const currentToken = await localforage.getItem("token");
    if (currentToken && currentToken !== "") {
      config.headers = {
        Authorization: `Bearer ${currentToken}`,
      };
    }
    return config;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

export default axiosApiInstance;
