import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import {
  getAlertsByItemIdAPI,
  getUnifiedAlertsFilteredAPI,
  UnifiedAlertsFiltered,
  UnifiedAlertsFilteredRequest,
} from "../alerts/alertsAPI";
import { AlertSeverity } from "../alerts/alertsSlice";

import {
  AttatchmentData,
  getAttatchmentAPI,
  getAttatchmentZipAPI,
  getUserAttatchmentsAPI,
} from "../magicforms/magicFormsAPI";
import {
  changeStatusCoreIdAPI,
  CoreIdFilterOptions,
  DataManualInfo,
  downloadCoreIdImagesAPI,
  ExternalQueryResult,
  FilteredBlackListResponse,
  FilteredLeadsRequest,
  FilteredRegistrationRequest,
  generateListsReportAPI,
  getFilteredLeadsAPI,
  getFilteredListsAPI,
  GetFilteredListsRequest,
  getFilteredRegistrationsAPI,
  getFilterOptionsAPI,
  getImagesRegistrationAPI,
  getManualInfoAPI,
  getMiniDashboardDataAPI,
  getProjectsAPI,
  getRegistrationByIdAPI,
  MiniDashboardData,
  Project,
  RegistrationDetail,
  RegistrationsFiltered,
  resetTriesAPI,
  toogleCheckLeadAPI,
  updateProjectAPI,
} from "./coreIdAPI";
import {
  base64PDFToBlobUrl,
  getMimeTypeBasedOnExtension,
} from "../../../helpers/utils";

export type CoreIdActiveComponent =
  | "Registration"
  | "GraphicMonitoring"
  | "Flow"
  | "Alerts"
  | "Manual"
  | "RegistrationDetail"
  | "Analytics"
  | "none";

export interface AttatchmentDataRequest {
  filledFormId: string;
  stitchTypeId: string;
  visualized?: boolean;
}

export interface CoreIdState {
  loadingStatus: LoadingStatus;
  loadingFilterOptions: LoadingStatus;
  loadingDashboardItems: LoadingStatus;
  loadingImagesStatus: LoadingStatus;
  loadingAttatchmentsStatus: LoadingStatus;
  downloadingAttatchmentStatus: LoadingStatus;
  downloadingAttachmentZipStatus: LoadingStatus;
  loadingProjects: LoadingStatus;
  loadingAlertsStatus: LoadingStatus;
  loadingRegistrationAlerts: LoadingStatus;
  manualConsultLoading: LoadingStatus;
  downloadingImagesStatus: LoadingStatus;
  loadingLeads: LoadingStatus;
  miniDashboarData: MiniDashboardData | null;
  registrationsFiltered: RegistrationsFiltered | null;
  currentFilteredRequest: FilteredRegistrationRequest;
  currentRegistration: RegistrationDetail | null;
  currentPage: number;
  numPages: number;
  activeComponent: CoreIdActiveComponent;
  projects: Array<Project>;
  currentProject: Project | null;
  filterOptions: CoreIdFilterOptions | null;
  currentAttatchment: AttatchmentData;
  alerts: UnifiedAlertsFiltered | null;
  registrationAlerts: UnifiedAlertsFiltered | null;
  currentAlertsRequest: UnifiedAlertsFilteredRequest;
  categoryAlerts: {
    compliance: AlertSeverity;
    dates: AlertSeverity;
    incongruences: AlertSeverity;
    attachments: AlertSeverity;
    authenticity: AlertSeverity;
  };
  manualResult: ExternalQueryResult[] | null;
  manualResultId: string | undefined;
  imagesRequestId: string | undefined;
  currentListsFilters: GetFilteredListsRequest;
  filteredLists: FilteredBlackListResponse | null;
  loadingFilteredLists: LoadingStatus;
  currentListsPage: number;
  numListPages: number;
  downloadingReport: LoadingStatus;
  attachmentToVisualize: {
    url: string;
    fileName: string;
    mimeType: string;
  };
  currentLeads: RegistrationsFiltered | null;
}

const initialState: CoreIdState = {
  loadingStatus: "idle",
  loadingImagesStatus: "idle",
  downloadingAttatchmentStatus: "idle",
  loadingDashboardItems: "idle",
  loadingAttatchmentsStatus: "idle",
  loadingAlertsStatus: "idle",
  loadingProjects: "idle",
  loadingFilterOptions: "idle",
  loadingRegistrationAlerts: "idle",
  manualConsultLoading: "idle",
  downloadingAttachmentZipStatus: "idle",
  downloadingImagesStatus: "idle",
  loadingLeads: "idle",
  activeComponent: "none",
  currentPage: 0,
  numPages: 0,
  miniDashboarData: {
    fraudRate: 0,
    newRegs: 0,
    regsFailed: 0,
    regsSuccess: 0,
    totalRegs: 0,
  },

  registrationsFiltered: null,
  projects: [],
  currentProject: null,
  currentFilteredRequest: {
    endDate: null,
    page: 0,
    projectId: null,
    searchQuery: null,
    startDate: null,
    result: null,
    tagId: null,
  },
  filterOptions: null,
  currentRegistration: null,
  currentAttatchment: { fileBase64: "", fileName: "" },
  alerts: null,
  currentAlertsRequest: {
    page: 0,
    service: "COREID",
    category: null,
    startDate: null,
    endDate: null,
    criticality: null,
    status: null,
  },
  categoryAlerts: {
    attachments: "NONE",
    authenticity: "NONE",
    compliance: "NONE",
    dates: "NONE",
    incongruences: "NONE",
  },
  registrationAlerts: null,
  manualResult: null,
  imagesRequestId: undefined,
  manualResultId: undefined,
  currentListsFilters: {
    documentQuery: null,
    finishedDate: null,
    matched: null,
    nameQuery: null,
    page: 0,
    startedDate: null,
    userId: null,
  },
  filteredLists: null,
  loadingFilteredLists: "idle",
  currentListsPage: 0,
  numListPages: 0,
  downloadingReport: "idle",
  attachmentToVisualize: {
    url: "",
    fileName: "",
    mimeType: "",
  },
  currentLeads: null,
};

export const getFilteredRegistrations = createAsyncThunk(
  "coreId/getFilteredRegistrations",
  async (params: FilteredRegistrationRequest, { rejectWithValue }) => {
    try {
      const filteredRegistrations = await getFilteredRegistrationsAPI(params);
      if (filteredRegistrations.registrations) {
        for (const registration of filteredRegistrations.registrations
          .registrations) {
          let alertsRegistration = await getAlertsByItemIdAPI(
            registration.id,
            "COREID"
          );
          if (alertsRegistration.alerts) {
            registration.alerts = alertsRegistration.alerts.alerts.length;
          } else {
            registration.alerts = 0;
          }
        }
        return filteredRegistrations;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFilteredLeads = createAsyncThunk(
  "coreId/getFilteredLeads",
  async (params: FilteredLeadsRequest, { rejectWithValue }) => {
    try {
      const filteredLeads = await getFilteredLeadsAPI(params);
      if (filteredLeads.registrations) {
        return filteredLeads;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const toggleCheckedLead = createAsyncThunk(
  "coreId/toggleCheckedLead",
  async (params: { userIdentification: string }) => {
    return await toogleCheckLeadAPI(params.userIdentification);
  }
);

export const getAttatchmentData = createAsyncThunk(
  "coreId/getAttatchmentData",
  async (
    AttatchmentDataParams: AttatchmentDataRequest,
    { rejectWithValue }
  ) => {
    try {
      const Attatchment = await getAttatchmentAPI(
        AttatchmentDataParams.filledFormId,
        AttatchmentDataParams.stitchTypeId
      );
      if (Attatchment) {
        return Attatchment;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAttachmentsZip = createAsyncThunk(
  "coreId/getAttachmentsZip",
  async (
    { filledFormIds, userId }: { filledFormIds: string[]; userId: string },
    { rejectWithValue }
  ) => {
    const attatchments = await getAttatchmentZipAPI(filledFormIds, userId);
    if (attatchments) {
      return attatchments;
    } else {
      return rejectWithValue(false);
    }
  }
);

export const getFilterOptions = createAsyncThunk(
  "coreId/getFilterOptions",
  async (_, { rejectWithValue }) => {
    try {
      const filteredOptions = await getFilterOptionsAPI();
      if (filteredOptions.filterOptions) {
        return filteredOptions;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDashboardItems = createAsyncThunk(
  "coreId/getDashboardItems",
  async (_, { rejectWithValue }) => {
    try {
      const dashboardItems = await getMiniDashboardDataAPI();
      if (dashboardItems.miniDasboardData) {
        return dashboardItems.miniDasboardData;
      } else {
        rejectWithValue(dashboardItems.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getRegistrationById = createAsyncThunk(
  "coreId/getRegistrationById",
  async (id: string, { rejectWithValue }) => {
    try {
      const registration = await getRegistrationByIdAPI(id);
      return registration.registration;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getImagesRegistration = createAsyncThunk(
  "coreId/getImages/RegistraionById",
  async (id: string, { rejectWithValue }) => {
    try {
      const images = await getImagesRegistrationAPI(id);

      return images.pictures;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAttatchments = createAsyncThunk(
  "coreId/getAttatchments",
  async (id: string, { rejectWithValue }) => {
    try {
      const attatchments = await getUserAttatchmentsAPI(id);

      return attatchments;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFlowItems = createAsyncThunk(
  "coreId/getFlowItems",
  async (_, { rejectWithValue }) => {
    try {
      const projects = await getProjectsAPI();
      if (projects.projects) {
        return projects;
      } else {
        rejectWithValue(projects.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getCoreIdAlerts = createAsyncThunk(
  "coreId/getCoreIdAlerts",
  async (params: UnifiedAlertsFilteredRequest, { rejectWithValue }) => {
    try {
      const alerts = await getUnifiedAlertsFilteredAPI(params);
      if (alerts.alerts) {
        return alerts;
      } else {
        rejectWithValue(alerts.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateProject = createAsyncThunk(
  "coreId/updateProject",
  async (project: Project, { rejectWithValue }) => {
    try {
      const projectUpdated = await updateProjectAPI(project);
      if (projectUpdated.project) {
        return projectUpdated;
      } else {
        rejectWithValue(projectUpdated.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAlertsByRegistrationId = createAsyncThunk(
  "coreId/getAlertsByRegistrationId",
  async (regId: string, { rejectWithValue }) => {
    try {
      const alerts = await getAlertsByItemIdAPI(regId, "COREID");
      if (alerts.alerts) {
        return alerts.alerts;
      } else {
        rejectWithValue(alerts.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getManualInfo = createAsyncThunk(
  "coreId/getManualInfo",
  async (info: DataManualInfo, { rejectWithValue }) => {
    try {
      let listResults = await getManualInfoAPI(info);
      if (listResults) {
        return listResults;
      } else {
        return rejectWithValue("Error");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeStatus = createAsyncThunk(
  "coreId/changeStatus",
  async (
    params: { registrationId: string; newStatus: string },
    { rejectWithValue }
  ) => {
    try {
      let changed = await changeStatusCoreIdAPI(
        params.registrationId,
        params.newStatus
      );
      return changed;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadImagesZip = createAsyncThunk(
  "coreId/downloadImagesZip",
  async (regId: string, { rejectWithValue }) => {
    try {
      await downloadCoreIdImagesAPI(regId);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFilteredLists = createAsyncThunk(
  "coreId/getFilteredLists",
  async (params: GetFilteredListsRequest, { rejectWithValue }) => {
    try {
      const filteredLists = await getFilteredListsAPI(params);
      if (filteredLists?.reports) {
        return filteredLists;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const generateListsReport = createAsyncThunk(
  "coreId/generateListsReport",
  async (id: string, { rejectWithValue }) => {
    try {
      return await generateListsReportAPI(id);
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const resetTries = createAsyncThunk(
  "coreId/resetTries",
  async (id: string, { rejectWithValue }) => {
    try {
      let res = await resetTriesAPI(id);
      if (res.error) {
        return rejectWithValue(res.error);
      } else return;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const coreIdSlice = createSlice({
  name: "coreIdSlice",
  initialState,
  reducers: {
    resetRegistration: (state) => {
      state.currentRegistration = null;
      state.loadingAttatchmentsStatus = "idle";
      state.loadingImagesStatus = "idle";
      state.currentAttatchment = { fileBase64: "", fileName: "" };
      state.loadingAlertsStatus = "idle";
      state.registrationAlerts = null;
      state.attachmentToVisualize = {
        url: "",
        fileName: "",
        mimeType: "",
      };
      state.downloadingAttachmentZipStatus = "idle";
      state.currentLeads = null;
      state.loadingLeads = "idle";
    },
    setAttachmentToVisualize: (
      state,
      action: PayloadAction<{ url: string; fileName: string; mimeType: string }>
    ) => {
      state.attachmentToVisualize = action.payload;
    },
    setLeads: (state, action: PayloadAction<RegistrationsFiltered | null>) => {
      state.currentLeads = action.payload;
      state.loadingLeads = "idle";
    },
    changeActiveComponent: (
      state,
      action: PayloadAction<CoreIdActiveComponent>
    ) => {
      state.activeComponent = action.payload;
      state.currentPage = 0;
      state.numPages = 0;
    },
    changeCurrentProject: (state, action: PayloadAction<string>) => {
      let projects = state.projects;
      for (const project of projects) {
        if (project.id === action.payload) {
          state.currentProject = project;
        }
      }
    },
    changeCurrentFilters: (
      state,
      action: PayloadAction<FilteredRegistrationRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    changeCurrentAlertFilters: (
      state,
      action: PayloadAction<UnifiedAlertsFilteredRequest>
    ) => {
      state.currentAlertsRequest = {
        ...action.payload,
        page: 0,
        service: "COREID",
      };
    },
    changeCurrentListsFilters: (
      state,
      action: PayloadAction<GetFilteredListsRequest>
    ) => {
      state.currentListsFilters = {
        ...action.payload,
        page: 0,
      };
    },

    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, pageSize: action.payload };
    },
    changeAlertPageState: (state, action: PayloadAction<number>) => {
      let current = state.currentAlertsRequest;
      state.currentPage = action.payload;
      state.currentAlertsRequest = { ...current, page: action.payload };
    },
    changeCurrentListsPageState: (state, action: PayloadAction<number>) => {
      let current = state.currentListsFilters;
      state.currentListsFilters = { ...current, page: action.payload };
    },
    emptyCurrentAttatchment: (state) => {
      state.currentAttatchment = { fileBase64: "", fileName: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredRegistrations.fulfilled, (state, action) => {
        let registrations = action.payload;

        if (registrations.registrations) {
          state.registrationsFiltered = registrations.registrations;

          state.currentPage = registrations.registrations.currentPage;
          state.numPages = registrations.registrations.numPages;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilteredRegistrations.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getFilteredRegistrations.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getDashboardItems.fulfilled, (state, action) => {
        let dashboardItems = action.payload;
        if (dashboardItems) {
          state.miniDashboarData = dashboardItems;
          state.loadingDashboardItems = "resolved";
        } else {
          state.loadingDashboardItems = "rejected";
        }
      })
      .addCase(getDashboardItems.rejected, (state) => {
        state.loadingDashboardItems = "rejected";
      })
      .addCase(getDashboardItems.pending, (state) => {
        state.loadingDashboardItems = "pending";
      })
      .addCase(getFlowItems.fulfilled, (state, action) => {
        let projects = action.payload;
        if (projects && projects.projects) {
          state.projects = projects.projects;
          state.loadingProjects = "resolved";
        } else {
          state.loadingProjects = "rejected";
        }
      })
      .addCase(getFlowItems.rejected, (state) => {
        state.loadingProjects = "rejected";
      })
      .addCase(getFlowItems.pending, (state) => {
        state.loadingProjects = "pending";
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions) {
          state.filterOptions = filterOptions.filterOptions;
        } else {
          state.loadingFilterOptions = "rejected";
        }
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingFilterOptions = "rejected";
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingFilterOptions = "pending";
      })
      .addCase(getRegistrationById.fulfilled, (state, action) => {
        let registration = action.payload;
        if (registration) {
          state.currentRegistration = registration;
          state.loadingStatus = "resolved";
          state.loadingImagesStatus = "idle";
          state.loadingAttatchmentsStatus = "idle";
          state.downloadingAttatchmentStatus = "idle";
        } else {
          state.loadingStatus = "rejected";
          state.loadingImagesStatus = "rejected";
        }
      })
      .addCase(changeStatus.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(changeStatus.fulfilled, (state) => {
        state.currentRegistration = null;
      })
      .addCase(changeStatus.rejected, (state) => {
        state.currentRegistration = null;
      })
      .addCase(getRegistrationById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.loadingImagesStatus = "rejected";
        state.loadingAttatchmentsStatus = "rejected";
        state.downloadingAttatchmentStatus = "rejected";
      })
      .addCase(getRegistrationById.pending, (state) => {
        state.loadingImagesStatus = "pending";
        state.loadingStatus = "pending";
        state.loadingAttatchmentsStatus = "pending";
        state.downloadingAttatchmentStatus = "pending";
      })
      .addCase(getImagesRegistration.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loadingImagesStatus === "pending" &&
          state.imagesRequestId === requestId
        ) {
          let images = action.payload;
          let registration = state.currentRegistration;
          if (images && registration) {
            registration.pictures = images;
            state.currentRegistration = registration;
            state.loadingImagesStatus = "resolved";
          } else {
            state.loadingImagesStatus = "rejected";
          }
        }
      })
      .addCase(getImagesRegistration.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loadingImagesStatus === "pending" &&
          state.imagesRequestId === requestId
        ) {
          state.loadingImagesStatus = "rejected";
        }
      })
      .addCase(getImagesRegistration.pending, (state, action) => {
        if (state.loadingImagesStatus === "idle") {
          state.loadingImagesStatus = "pending";
          state.imagesRequestId = action.meta.requestId;
        }
      })
      .addCase(getAttatchments.fulfilled, (state, action) => {
        let attatchments = action.payload;
        let registration = state.currentRegistration;
        if (attatchments && registration) {
          registration.attatchments = attatchments;
          state.currentRegistration = registration;
          state.loadingAttatchmentsStatus = "resolved";
        } else {
          state.loadingAttatchmentsStatus = "rejected";
        }
      })
      .addCase(getAttatchments.rejected, (state) => {
        state.loadingAttatchmentsStatus = "rejected";
      })
      .addCase(getAttatchments.pending, (state) => {
        state.loadingAttatchmentsStatus = "pending";
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        let updatedProject = action.payload;
        if (updatedProject && updatedProject.project) {
          state.currentProject = updatedProject.project;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(updateProject.rejected, (state) => {
        state.loadingStatus = "rejected";
      })
      .addCase(updateProject.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getAttatchmentData.fulfilled, (state, action) => {
        let attatchment = action.payload;
        const { visualized } = action.meta.arg;
        if (attatchment) {
          if (visualized) {
            const url = base64PDFToBlobUrl(attatchment.fileBase64);
            state.attachmentToVisualize = {
              fileName: attatchment.fileName,
              mimeType: getMimeTypeBasedOnExtension(
                attatchment.fileName.split(".").pop() || ""
              ),
              url,
            };
          } else {
            state.currentAttatchment = attatchment;
          }
          state.downloadingAttatchmentStatus = "resolved";
        } else {
          state.downloadingAttatchmentStatus = "rejected";
        }
      })
      .addCase(getAttatchmentData.rejected, (state) => {
        state.downloadingAttatchmentStatus = "rejected";
      })
      .addCase(getAttatchmentData.pending, (state) => {
        state.downloadingAttatchmentStatus = "pending";
      })
      .addCase(getCoreIdAlerts.fulfilled, (state, action) => {
        let alerts = action.payload;
        if (alerts && alerts.alerts) {
          state.alerts = alerts.alerts;
          let categoryAlertsTemp: {
            compliance: AlertSeverity;
            dates: AlertSeverity;
            incongruences: AlertSeverity;
            attachments: AlertSeverity;
            authenticity: AlertSeverity;
          } = {
            attachments: "NONE",
            authenticity: "NONE",
            compliance: "NONE",
            dates: "NONE",
            incongruences: "NONE",
          };

          state.categoryAlerts = categoryAlertsTemp;
          state.numPages = alerts.alerts.numPages;

          state.loadingAlertsStatus = "resolved";
        } else {
          state.loadingAlertsStatus = "rejected";
        }
      })
      .addCase(getCoreIdAlerts.rejected, (state) => {
        state.loadingAlertsStatus = "rejected";
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getCoreIdAlerts.pending, (state) => {
        state.loadingAlertsStatus = "pending";
      })
      .addCase(getAlertsByRegistrationId.fulfilled, (state, action) => {
        let alerts = action.payload;
        if (alerts) {
          state.registrationAlerts = alerts;
          state.loadingRegistrationAlerts = "resolved";
        } else {
          state.loadingRegistrationAlerts = "rejected";
        }
      })
      .addCase(getAlertsByRegistrationId.rejected, (state) => {
        state.loadingRegistrationAlerts = "rejected";
      })
      .addCase(getAlertsByRegistrationId.pending, (state) => {
        state.loadingRegistrationAlerts = "pending";
      })
      .addCase(getManualInfo.fulfilled, (state, action) => {
        let manual: ExternalQueryResult[] = new Array(action.payload.lists);
        state.manualResult = manual;
        state.manualResultId = action.payload.id;
        state.manualConsultLoading = "resolved";
      })
      .addCase(getManualInfo.pending, (state) => {
        state.manualConsultLoading = "pending";
      })
      .addCase(getManualInfo.rejected, (state) => {
        state.manualResult = null;
        state.manualConsultLoading = "rejected";
      });

    builder
      .addCase(downloadImagesZip.fulfilled, (state) => {
        state.downloadingImagesStatus = "resolved";
      })
      .addCase(downloadImagesZip.rejected, (state) => {
        state.downloadingImagesStatus = "rejected";
      })
      .addCase(downloadImagesZip.pending, (state) => {
        state.downloadingImagesStatus = "pending";
      });

    // Builder for getFilteredLists
    builder
      .addCase(getFilteredLists.fulfilled, (state, action) => {
        let filteredLists = action.payload;
        if (filteredLists?.reports) {
          state.filteredLists = filteredLists;
          state.loadingFilteredLists = "resolved";
          state.numListPages = filteredLists.numPages;
        } else {
          state.loadingFilteredLists = "rejected";
        }
      })
      .addCase(getFilteredLists.rejected, (state) => {
        state.loadingFilteredLists = "rejected";
      })
      .addCase(getFilteredLists.pending, (state) => {
        state.loadingFilteredLists = "pending";
      });

    // Builder for generateListsReport
    builder
      .addCase(generateListsReport.fulfilled, (state) => {
        state.downloadingReport = "resolved";
      })
      .addCase(generateListsReport.rejected, (state) => {
        state.downloadingReport = "rejected";
      })
      .addCase(generateListsReport.pending, (state) => {
        state.downloadingReport = "pending";
      });
    // get Zipped attatchments
    builder
      .addCase(getAttachmentsZip.fulfilled, (state) => {
        state.downloadingAttachmentZipStatus = "resolved";
      })
      .addCase(getAttachmentsZip.rejected, (state) => {
        state.downloadingAttachmentZipStatus = "rejected";
      })
      .addCase(getAttachmentsZip.pending, (state) => {
        state.downloadingAttachmentZipStatus = "pending";
      });
    // Leads

    builder
      .addCase(getFilteredLeads.fulfilled, (state, action) => {
        const leads = action.payload;

        if (leads.registrations) {
          state.currentLeads = leads.registrations;
          state.loadingLeads = "resolved";
        } else {
          state.loadingLeads = "rejected";
          state.currentLeads = null;
        }
      })
      .addCase(getFilteredLeads.rejected, (state) => {
        state.loadingLeads = "rejected";
        state.currentLeads = null;
      })
      .addCase(getFilteredLeads.pending, (state) => {
        state.loadingLeads = "pending";
      });
  },
});

export const {
  changeActiveComponent,
  changeCurrentProject,
  changeCurrentFilters,
  changePageState,
  changePageSize,
  emptyCurrentAttatchment,
  changeAlertPageState,
  changeCurrentAlertFilters,
  resetRegistration,
  changeCurrentListsFilters,
  changeCurrentListsPageState,
  setAttachmentToVisualize,
  setLeads,
} = coreIdSlice.actions;

export const selectCurrentPageCoreId = (state: RootState) =>
  state.coreId.currentPage;
export const selectNumPagesCoreId = (state: RootState) => state.coreId.numPages;
export const selectPageSizeCoreId = (state: RootState) =>
  state.coreId.currentFilteredRequest.pageSize;
export const selectActiveCoreIdComponent = (state: RootState) =>
  state.coreId.activeComponent;
export const selectCoreIdLoading = (state: RootState) =>
  state.coreId.loadingStatus;
export const selectRegistrationImagesLoading = (state: RootState) =>
  state.coreId.loadingImagesStatus;
export const selectMiniDashboardData = (state: RootState) =>
  state.coreId.miniDashboarData;
export const selectFilteredRegistrations = (state: RootState) =>
  state.coreId.registrationsFiltered;
export const selectCoreIdProjects = (state: RootState) => state.coreId.projects;
export const selectCoreIdCurrentProject = (state: RootState) =>
  state.coreId.currentProject;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.coreId.currentFilteredRequest;
export const selectFilterOptions = (state: RootState) =>
  state.coreId.filterOptions;
export const selectCurrentRegistration = (state: RootState) =>
  state.coreId.currentRegistration;
export const selectCurrentAttatchment = (state: RootState) =>
  state.coreId.currentAttatchment;
export const selectAttatchmentsLoadingStatus = (state: RootState) =>
  state.coreId.loadingAttatchmentsStatus;
export const selectAttatchmentDownloadingStatus = (state: RootState) =>
  state.coreId.downloadingAttatchmentStatus;
export const selectAlertsLoadingStatus = (state: RootState) =>
  state.coreId.loadingAlertsStatus;
export const selectFilteredCoreIdAlerts = (state: RootState) =>
  state.coreId.alerts;
export const selectCurrentCoreIdAlertsRequest = (state: RootState) =>
  state.coreId.currentAlertsRequest;
export const selectCoreIdAlertsSeverity = (state: RootState) =>
  state.coreId.categoryAlerts;
export const selectProjectsLoading = (state: RootState) =>
  state.coreId.loadingProjects;
export const selectRegistrationAlerts = (state: RootState) =>
  state.coreId.registrationAlerts;
export const selectRegistrationAlertsLoading = (state: RootState) =>
  state.coreId.loadingRegistrationAlerts;
export const selectCurrentManual = (state: RootState) =>
  state.coreId.manualResult;
export const selectManualLoading = (state: RootState) =>
  state.coreId.manualConsultLoading;
export const selectDownloadZipLoading = (state: RootState) =>
  state.coreId.downloadingImagesStatus;
export const selectCurrentFilterListsRequest = (state: RootState) =>
  state.coreId.currentListsFilters;
export const selectFilteredLists = (state: RootState) =>
  state.coreId.filteredLists;
export const selectLoadingFilteredLists = (state: RootState) =>
  state.coreId.loadingFilteredLists;
export const selectListsCurrentPage = (state: RootState) =>
  state.coreId.currentListsPage;
export const selectListsNumPages = (state: RootState) =>
  state.coreId.numListPages;
export const selectDownloadingReport = (state: RootState) =>
  state.coreId.downloadingReport;
export const selectManualConsultId = (state: RootState) =>
  state.coreId.manualResultId;
export const selectLoadingAttachmentZip = (state: RootState) =>
  state.coreId.downloadingAttachmentZipStatus;
export const selectAttachmentToVisualize = (state: RootState) =>
  state.coreId.attachmentToVisualize;
export const selectCurrentLeads = (state: RootState) =>
  state.coreId.currentLeads;
export const selectLeadsLoading = (state: RootState) =>
  state.coreId.loadingLeads;

export default coreIdSlice.reducer;
