import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import { File, GeneratedDocuments } from "../firma/firmaAPI";
import {
  BackOfficeSigner,
  getAuthImageAPI,
  GetFilteredSignatureRequest,
  GetFilteredSignatureResponse,
  getFilteredSignaturesAPI,
  getFilterOptionsAPI,
  GetFilterOptionsResponse,
  getGeneratedDocumentsNewAPI,
  getSignatureByIdAPI,
  GetSignatureResponse,
} from "./firmaNewAPI";

export type FirmaActiveComponent = "FirmasNew" | "Analytics" | "none";

export interface FirmaNewState {
  loadingStatus: LoadingStatus;
  loadingDocumentStatus: LoadingStatus;
  loadingImages: LoadingStatus;
  loadingFilterOptions: LoadingStatus;
  firmas: GetFilteredSignatureResponse | null;
  notFoundMessage: string;
  currentPage: number;
  currentNumPages: number;
  activeComponent: FirmaActiveComponent;
  currentFilteredRequest: GetFilteredSignatureRequest;
  currentFirma: GetSignatureResponse | null;
  currentDocuments: GeneratedDocuments | null;
  filterOptions: GetFilterOptionsResponse;
  currentSigner: BackOfficeSigner;
  currentAuthImages: File[];
}

const initialState: FirmaNewState = {
  activeComponent: "none",
  currentFirma: null,
  currentNumPages: 0,
  currentPage: 1,
  firmas: null,
  loadingStatus: "idle",
  loadingDocumentStatus: "idle",
  loadingImages: "idle",
  loadingFilterOptions: "idle",
  notFoundMessage: "",
  currentFilteredRequest: {
    endDate: null,
    page: 0,
    startDate: null,
    status: null,
    documentType: null,
    project: null,
    tag: null,
    searchQuery: null,
  },
  currentDocuments: null,
  filterOptions: {
    projectOptions: [],
  },
  currentSigner: {
    status: "",
    startedDate: "",
    numAlerts: 0,
    email: "",
    phoneNumber: "",
    identification: "",
    identificationType: "",
    lastName: "",
    name: "",
    userId: "",
    validationMethod: "",
    signerId: "",
  },
  currentAuthImages: [],
};

export const getFilteredSignatures = createAsyncThunk(
  "firmaNew/getFilteredSignatures",
  async (params: GetFilteredSignatureRequest, { rejectWithValue }) => {
    try {
      const filteredSignatures = await getFilteredSignaturesAPI(params);
      if (filteredSignatures.filteredSignatures) {
        return filteredSignatures;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getSignatureById = createAsyncThunk(
  "firmaNew/getSignatureById",
  async (id: string, { rejectWithValue }) => {
    try {
      const firma = await getSignatureByIdAPI(id);
      return firma.signature;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGeneratedDocumentsNew = createAsyncThunk(
  "firmaNew/getGenerateDocuments",
  async (id: string, { rejectWithValue }) => {
    try {
      const documents = await getGeneratedDocumentsNewAPI(id);
      return documents.documents;
    } catch (err: any) {
      return rejectWithValue(err.messag);
    }
  }
);

export const getFilterOptions = createAsyncThunk(
  "firmaNew/getFilterOptions",
  async (_, { rejectWithValue }) => {
    try {
      const filterOptions = await getFilterOptionsAPI();
      return filterOptions;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAuthImages = createAsyncThunk(
  "firmaNew/getAuthImages",
  async (_, { rejectWithValue, getState }) => {
    try {
      const currentState = getState() as RootState;
      const currentSignature = currentState.firmaNew.currentFirma;
      const currentSigner = currentState.firmaNew.currentSigner;
      if (currentSignature && currentSigner) {
        const authImages = await getAuthImageAPI(
          currentSignature.documentId,
          currentSigner.signerId
        );
        return authImages;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const firmaNewSlice = createSlice({
  name: "firmaSlice",
  initialState,
  reducers: {
    changeActiveComponent: (
      state,
      action: PayloadAction<FirmaActiveComponent>
    ) => {
      state.activeComponent = action.payload;
    },
    changeCurrentFilters: (
      state,
      action: PayloadAction<GetFilteredSignatureRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, pageSize: action.payload };
    },
    changeCurrentSigner: (state, action: PayloadAction<BackOfficeSigner>) => {
      state.currentSigner = action.payload;
    },
    resetImageLoading: (state) => {
      state.loadingImages = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredSignatures.fulfilled, (state, action) => {
        let signatures = action.payload;

        if (signatures.filteredSignatures) {
          state.firmas = signatures.filteredSignatures;
          state.currentPage = signatures.filteredSignatures.currentPage;
          state.currentNumPages = signatures.filteredSignatures.numPages;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilteredSignatures.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentPage = 0;
        state.currentNumPages = 0;
      })
      .addCase(getFilteredSignatures.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getSignatureById.fulfilled, (state, action) => {
        let signature = action.payload;
        if (signature) {
          state.currentFirma = signature;
          state.loadingStatus = "resolved";
          state.loadingDocumentStatus = "idle";
          state.loadingImages = "idle";
        } else {
          state.loadingStatus = "rejected";
          state.loadingDocumentStatus = "rejected";
          state.loadingImages = "rejected";
        }
      })
      .addCase(getSignatureById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.loadingDocumentStatus = "rejected";
        state.loadingImages = "rejected";
      })
      .addCase(getSignatureById.pending, (state) => {
        state.loadingStatus = "pending";
        state.loadingDocumentStatus = "pending";
        state.loadingImages = "pending";
      })
      .addCase(getGeneratedDocumentsNew.fulfilled, (state, action) => {
        let documents = action.payload;
        if (documents) {
          state.currentDocuments = documents;
          state.loadingDocumentStatus = "resolved";
        } else {
          state.loadingDocumentStatus = "rejected";
        }
      })
      .addCase(getGeneratedDocumentsNew.rejected, (state) => {
        state.loadingDocumentStatus = "rejected";
      })
      .addCase(getGeneratedDocumentsNew.pending, (state) => {
        state.loadingDocumentStatus = "pending";
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions.filterOptions) {
          state.filterOptions = filterOptions.filterOptions;
          state.loadingFilterOptions = "resolved";
        } else {
          state.loadingFilterOptions = "rejected";
        }
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingFilterOptions = "rejected";
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingFilterOptions = "pending";
      })
      .addCase(getAuthImages.fulfilled, (state, action) => {
        let authImages = action.payload;
        if (authImages.authImages) {
          state.currentAuthImages = authImages.authImages;
          state.loadingImages = "resolved";
        } else {
          state.loadingImages = "rejected";
        }
      })
      .addCase(getAuthImages.rejected, (state) => {
        state.loadingImages = "rejected";
      })
      .addCase(getAuthImages.pending, (state) => {
        state.loadingImages = "pending";
      });
  },
});

export const {
  changeActiveComponent,
  changeCurrentFilters,
  changePageState,
  changeCurrentSigner,
  resetImageLoading,
  changePageSize,
} = firmaNewSlice.actions;

export const selectNotFoundMessageFirma = (state: RootState) =>
  state.firmaNew.notFoundMessage;
export const selectActiveFirmaComponent = (state: RootState) =>
  state.firmaNew.activeComponent;
export const selectCurrentPageFirma = (state: RootState) =>
  state.firmaNew.currentPage;
export const selectCurrentNumPagesFirma = (state: RootState) =>
  state.firmaNew.currentNumPages;
export const selectCurrentFirma = (state: RootState) =>
  state.firmaNew.currentFirma;
export const selectPageSizeFirma = (state: RootState) =>
  state.firmaNew.currentFilteredRequest.pageSize;
export const selectFirmas = (state: RootState) => state.firmaNew.firmas;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.firmaNew.currentFilteredRequest;
export const selectCurrentDocuments = (state: RootState) =>
  state.firmaNew.currentDocuments;
export const selectLoading = (state: RootState) => state.firmaNew.loadingStatus;
export const selectImageLoading = (state: RootState) =>
  state.firmaNew.loadingImages;
export const selectDocumentsLoading = (state: RootState) =>
  state.firmaNew.loadingDocumentStatus;
export const selectCurrentSigner = (state: RootState) =>
  state.firmaNew.currentSigner;
export const selectAuthImages = (state: RootState) =>
  state.firmaNew.currentAuthImages;

export default firmaNewSlice.reducer;
