import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeActiveComponent,
  selectActiveConfigurationComponent,
} from "../configurationSlice";
import {
  changeCreateClientRequest,
  createClient,
  getPossibleProjects,
  resetCreateClient,
  selectClientCreateError,
  selectClientCreateRequest,
  selectClientCreateResponse,
  selectLoadingCreateClient,
  selectLoadingPossibleProjects,
  selectPossibleProjects,
} from "./clientsSlice";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import { DropDown } from "../../common/components/Btns/DropDown";
import { ChromePicker } from "react-color";
import { arrayBufferToBase64 } from "../../services/pagares/utils";
import { ReactComponent as Delete } from "../../../Assets/common/images/delete.svg";
import { ReactComponent as Change } from "../../../Assets/common/images/change.svg";
import { ReactComponent as Upload } from "../../../Assets/common/images/upload.svg";
import { ReactComponent as Copy } from "../../../Assets/common/images/copy.svg";
import icono1 from "../../../Assets/common/images/ICO USER.png";
import icono3 from "../../../Assets/common/images/video.png";
import icono5 from "../../../Assets/common/images/microphone.png";
import icono6 from "../../../Assets/common/images/ICO SMS.png";
import logoanteia from "../../../Assets/common/images/logo_anteia.png";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "35%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const CreateSubClient = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let loadingCreateClient = useAppSelector(selectLoadingCreateClient);
  let createClientRequest = useAppSelector(selectClientCreateRequest);
  let createClientResponse = useAppSelector(selectClientCreateResponse);
  let createClientError = useAppSelector(selectClientCreateError);
  let possibleProjects = useAppSelector(selectPossibleProjects);
  let loadingPossibleProjects = useAppSelector(selectLoadingPossibleProjects);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);

  const [modalCreateSuccess, setModalCreateSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [step, setStep] = useState(1);
  const [colorPicker1, setColorPicker1] = useState(false);
  const [colorPicker2, setColorPicker2] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [copyUsername, setCopyUsername] = useState(false);
  const [copyPassword, setCopyPassword] = useState(false);
  const [projectOptions, setProjectOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [nameError, setNameError] = useState("");
  const [projectEror, setProjectError] = useState("");
  const [logoError, setLogoError] = useState("");

  const closeModalCreateSuccess = () => {
    setModalCreateSuccess(false);
    dispatch(resetCreateClient());
    navigate("/configuration/subclients");
  };

  const closeModalError = () => {
    setModalError(false);
  };

  const previsualization = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "10px",
              color: "#fff",
              margin: "0",
            }}
          >
            Antes de comenzar
          </p>

          <>
            {createClientRequest.brandConfig.logoBase64 ? (
              <img
                src={
                  "data:image/png;base64," +
                  createClientRequest.brandConfig.logoBase64
                }
                loading="eager"
                alt="logo empresa"
                style={{
                  maxWidth: "200px",
                  maxHeight: "120px",
                  margin: "10px 0 20px 0",
                  width: "auto",
                  height: "auto",
                }}
              />
            ) : (
              <img
                src={logoanteia}
                loading="eager"
                alt="logo anteia"
                style={{
                  maxWidth: "200px",
                  margin: "10px 0 20px 0",
                  maxHeight: "60px",
                  width: "auto",
                  height: "auto",
                }}
              />
            )}
          </>

          <p
            style={{
              width: "168px",
              textAlign: "center",
              fontWeight: "700",
              fontSize: "10px",
              color: "#fff",
            }}
          >
            necesita acceder, recolectar y almacenar datos de :
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono1}
                alt="Icono"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 0px 0",
                  fontFamily: "Open Sans",
                }}
              >
                Identidad
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "09px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                Consultar la identidad y estado del dispositivo
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono3}
                alt="Icono"
                style={{ width: "20.5px", height: "20.5px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 0px 0",
                  fontFamily: "Open Sans",
                }}
              >
                Cámara de vídeo
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "09px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                {" "}
                Realizar fotografías y videos
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono5}
                alt="Icono"
                style={{ width: "23px", height: "23px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 2px 0",
                  fontFamily: "Open Sans",
                }}
              >
                Micrófono
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "9px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                Grabar sonido
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono6}
                alt="Icono"
                style={{ width: "23.33px", height: "20.97px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 2px 0",
                  fontFamily: "Open Sans",
                }}
              >
                SMS
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "9px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                · Recibir mensajes de texto (sms)<br></br>· Enviar mensajes
                (sms) <br></br>· Leer mensajes (sms)
              </p>
            </div>
          </div>
        </div>

        <button
          style={{
            width: "140px",
            height: "30px",
            margin: "15px 0 0 20%",
            border: 0,
            borderRadius: "8px",
            color: "#fff",
            fontSize: "12px",
            textAlign: "center",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
          }}
        >
          SIGUIENTE
        </button>
      </div>
    );
  };

  const step1 = () => {
    return (
      <>
        <div
          style={{
            background: "rgba(0,0,0,0.2)",
            width: "80%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px 8px 0 0px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px 8px 0 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "35px",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px 8px 0 0px",
                color: "rgba(255,255,255,1)",
                textAlign: "center",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 600,
                padding: "0 10px",
              }}
            >
              Información básica del cliente y flujo
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  padding: "6% 0 0 6%",
                }}
              >
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                  }}
                >
                  Nombre
                </h6>
                <input
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "290px",
                    padding: "6px 10px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                  value={createClientRequest?.name}
                  onChange={(e) => {
                    dispatch(
                      changeCreateClientRequest({
                        ...createClientRequest,
                        name: e.target.value,
                      })
                    );
                    setNameError("");
                  }}
                />
                {nameError && (
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "2px 2px 0 0",
                      maxWidth: "290px",
                    }}
                  >
                    {nameError}
                  </p>
                )}
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                    marginTop: "20px",
                  }}
                >
                  NIT
                </h6>
                <input
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "290px",
                    padding: "6px 10px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                  value={createClientRequest?.nit}
                  onChange={(e) => {
                    let onlyDigitRegex = /^[0-9]+$/;
                    if (
                      onlyDigitRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      dispatch(
                        changeCreateClientRequest({
                          ...createClientRequest,
                          nit: e.target.value,
                        })
                      );
                    }
                  }}
                />
                <FormControlLabel
                  style={{
                    color: "#FFF",
                    alignSelf: "start",
                    width: "50%",
                    margin: "20px 0 20px -10px ",
                    fontFamily: "Open Sans",
                  }}
                  control={
                    <Checkbox
                      style={{ color: "#FFF", alignSelf: "start" }}
                      checked={createClientRequest?.canSeeData}
                      onChange={() => {
                        dispatch(
                          changeCreateClientRequest({
                            ...createClientRequest,
                            canSeeData: !createClientRequest?.canSeeData,
                          })
                        );
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      ¿Se puede visualizar la información de este cliente?
                    </span>
                  }
                />
                {projectOptions.length > 0 && (
                  <DropDown
                    key={"Projects"}
                    title="Proyectos"
                    options={projectOptions}
                    defaultValue={projectOptions[0]}
                    isClearable={false}
                    onChange={(e) => {
                      dispatch(
                        changeCreateClientRequest({
                          ...createClientRequest,
                          projectId: e?.value!,
                        })
                      );
                      setProjectError("");
                    }}
                  />
                )}
                {projectEror && (
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "2px 2px 0 0",
                    }}
                  >
                    {projectEror}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20%",
          }}
        >
          <div
            style={{
              padding: "14px",
              background: "rgba(0,0,0,0.2)",
              color: "rgba(255,255,255,0.5)",
              borderRadius: "8px",
              fontSize: "12px",
              marginLeft: "10px",
              fontWeight: 600,
            }}
          >
            Durante este paso, podrás seleccionar la información básica del
            cliente. Además, tendrás la opción de elegir si el metacliente podrá
            ver la información del subcliente que estás creando. Por último,
            deberás asignar un proyecto al cliente, lo cual determinará el flujo
            que utilizará el subcliente.
          </div>
        </div>
      </>
    );
  };

  const step2 = () => {
    return (
      <>
        <div
          style={{
            background: "rgba(0,0,0,0.2)",
            width: "80%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px 8px 0 0px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px 8px 0 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "35px",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px 8px 0 0px",
                color: "rgba(255,255,255,1)",
                textAlign: "center",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 600,
                padding: "0 10px",
              }}
            >
              Interfaz del flujo
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                  height: "100%",
                  padding: "0 0 0 6%",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "5px 0 20% 5px" }}>
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      borderRadius: "8px",
                      width: "150px",
                      background: "rgba(0,0,0,0.2)",
                      padding: "8px",
                    }}
                    htmlFor="fileInput"
                  >
                    <input
                      style={{
                        display: "none",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onChange={async (e) => {
                        let file = e.target.files ? e.target.files[0] : null;
                        let type = "";
                        let fileBase64 = "";
                        if (file) {
                          type = file.type;
                          let temp = await file.arrayBuffer();
                          fileBase64 = arrayBufferToBase64(temp);
                        }
                        if (
                          type === "image/png" ||
                          type === "image/jpeg" ||
                          type === "image/jpg"
                        ) {
                          dispatch(
                            changeCreateClientRequest({
                              ...createClientRequest,
                              brandConfig: {
                                ...createClientRequest.brandConfig,
                                logoBase64: fileBase64,
                              },
                            })
                          );
                          setFileErrorMessage("");
                          setLogoError("");
                        } else {
                          setFileErrorMessage("Formato no soportado");
                        }
                      }}
                      type="file"
                      id="fileInput"
                    />

                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        color: "white",
                        margin: "0 0 0 14px",
                      }}
                    >
                      {createClientRequest.brandConfig.logoBase64
                        ? "Cambiar Logo"
                        : "Agregar Logo"}
                    </div>
                    {createClientRequest.brandConfig.logoBase64 ? (
                      <Change />
                    ) : (
                      <Upload />
                    )}
                  </label>
                  {fileErrorMessage && (
                    <p
                      style={{
                        color: "#FF9494",

                        fontSize: "10px",
                      }}
                    >
                      {fileErrorMessage}{" "}
                    </p>
                  )}
                  {logoError && (
                    <p
                      style={{
                        fontSize: "10px",
                        color: "rgba(255, 105, 105, 0.7)",
                        fontWeight: "400",
                        textAlign: "start",
                        margin: "2px 2px 0 0",
                      }}
                    >
                      {logoError}
                    </p>
                  )}
                  {createClientRequest.brandConfig.logoBase64 && (
                    <button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        borderRadius: "8px",
                        width: "150px",
                        background: "rgba(0,0,0,0.2)",
                        margin: "10px 0 0 0",
                        padding: "8px",
                        border: "none",
                      }}
                      onClick={() => {
                        dispatch(
                          changeCreateClientRequest({
                            ...createClientRequest,
                            brandConfig: {
                              ...createClientRequest.brandConfig,
                              logoBase64: "",
                            },
                          })
                        );
                        setLogoError("Debe subir un logo");
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 700,
                          color: "white",
                          margin: "0 0 0 14px",
                        }}
                      >
                        Eliminar Logo
                      </div>
                      <Delete />
                    </button>
                  )}
                </div>
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                  }}
                >
                  Color 1
                </h6>
                <button
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "152px",
                    padding: "3px 4px",
                  }}
                  onClick={() => {
                    setColorPicker1(!colorPicker1);
                  }}
                >
                  <h5
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                      margin: "3px 6px 3px 20px",
                      textTransform: "uppercase",
                    }}
                  >
                    {createClientRequest.brandConfig.color1}
                  </h5>
                  <div
                    style={{
                      border: "none",
                      background: createClientRequest.brandConfig.color1,
                      borderRadius: "6px",
                      height: "100%",
                      width: "30px",
                    }}
                  ></div>
                </button>
                <div>
                  {colorPicker1 && (
                    <div style={{ position: "absolute", zIndex: 2 }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() => setColorPicker1(false)}
                      />
                      <ChromePicker
                        color={createClientRequest?.brandConfig?.color1}
                        styles={{
                          default: {
                            body: { fontFamily: "Open Sans" },
                          },
                        }}
                        disableAlpha
                        onChange={(e) => {
                          dispatch(
                            changeCreateClientRequest({
                              ...createClientRequest,
                              brandConfig: {
                                ...createClientRequest.brandConfig,
                                color1: e.hex,
                              },
                            })
                          );
                        }}
                        onChangeComplete={(e) => {
                          dispatch(
                            changeCreateClientRequest({
                              ...createClientRequest,
                              brandConfig: {
                                ...createClientRequest.brandConfig,
                                color1: e.hex,
                              },
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                    marginTop: "20px",
                  }}
                >
                  Color 2
                </h6>
                <button
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "152px",
                    padding: "3px 4px",
                  }}
                  onClick={() => {
                    setColorPicker2(!colorPicker2);
                  }}
                >
                  <h5
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                      margin: "3px 6px 3px 20px",
                      textTransform: "uppercase",
                    }}
                  >
                    {createClientRequest.brandConfig.color2}
                  </h5>
                  <div
                    style={{
                      border: "none",
                      background: createClientRequest.brandConfig.color2,
                      borderRadius: "6px",
                      height: "100%",
                      width: "30px",
                    }}
                  ></div>
                </button>
                <div>
                  {colorPicker2 && (
                    <div style={{ position: "absolute", zIndex: 2 }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() => setColorPicker2(false)}
                      />
                      <ChromePicker
                        color={createClientRequest?.brandConfig?.color2}
                        styles={{
                          default: {
                            body: { fontFamily: "Open Sans" },
                          },
                        }}
                        disableAlpha
                        onChange={(e) => {
                          dispatch(
                            changeCreateClientRequest({
                              ...createClientRequest,
                              brandConfig: {
                                ...createClientRequest.brandConfig,
                                color2: e.hex,
                              },
                            })
                          );
                        }}
                        onChangeComplete={(e) => {
                          dispatch(
                            changeCreateClientRequest({
                              ...createClientRequest,
                              brandConfig: {
                                ...createClientRequest.brandConfig,
                                color2: e.hex,
                              },
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "60%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: 700,
                    textAlign: "center",
                    margin: "0 0 15px 0",
                  }}
                >
                  Previsualización
                </h5>
                <div
                  style={{
                    borderRadius: "8px",
                    height: "80%",
                    width: "250px",
                    background: `radial-gradient(${createClientRequest.brandConfig.color1}, ${createClientRequest.brandConfig.color2})`,
                    overflow: "auto",
                    padding: "20px 20px",
                  }}
                >
                  {previsualization()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20%",
          }}
        >
          <div
            style={{
              padding: "14px",
              background: "rgba(0,0,0,0.2)",
              color: "rgba(255,255,255,0.5)",
              borderRadius: "8px",
              fontSize: "12px",
              marginLeft: "10px",
              fontWeight: 600,
            }}
          >
            En este paso podrá configurar la interfaz del flujo que se va a
            asignar a su subcliente. A la derecha aparece una previsualización
            de ejemplo.
          </div>
        </div>
      </>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return step1();
      case 2:
        return step2();
      default:
        return step1();
    }
  };

  useEffect(() => {
    if (activeComponent !== "Client") {
      dispatch(changeActiveComponent("Client"));
    }
    if (possibleProjects.length === 0 || loadingPossibleProjects === "idle") {
      dispatch(getPossibleProjects());
    }
    if (createClientResponse?.password) {
      setModalCreateSuccess(true);
    }
    if (
      possibleProjects.length > 0 &&
      loadingPossibleProjects === "resolved" &&
      projectOptions.length === 0
    ) {
      let possibleProjectOptions = possibleProjects.map((project) => {
        return { value: project.projectId, label: project.projectName };
      });

      setProjectOptions(possibleProjectOptions);
      dispatch(
        changeCreateClientRequest({
          ...createClientRequest,
          projectId: possibleProjects[0].projectId,
        })
      );
    }
    if (createClientError !== "") {
      setModalError(true);
    }
    if (loadingCreateClient === "resolved") {
      setModalCreateSuccess(true);
    }
  }, [
    possibleProjects,
    createClientError,
    loadingCreateClient,
    loadingPossibleProjects,
    projectOptions,
  ]);
  if (loadingCreateClient === "pending") {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1%",
        }}
      >
        <ClipLoader color="#FFF" size={"200px"} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "1%",
        }}
      >
        <h4
          style={{
            fontSize: "14px",
            fontWeight: 600,
            color: "rgba(255,255,255,0.5)",
            marginLeft: "10px",
          }}
        >
          Configuración del cliente
        </h4>
        <div
          style={{
            width: "100%",
            height: "80%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {renderStep()}
        </div>
        <div
          style={{
            width: "80%",
            height: "10%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "130px",
              height: "33px",
              borderRadius: "30px",
              background: "rgba(0,0,0,0.2)",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "15px",
              fontWeight: 700,
            }}
          >
            Paso {step} - 2
          </div>
          {step === 2 && (
            <button
              style={{
                width: "140px",
                height: "40px",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px",
                border: "none",
                fontSize: "14px",
                fontWeight: 700,
                color: "#fff",
              }}
              onClick={() => {
                if (step === 2) {
                  setStep(step - 1);
                }
              }}
            >
              Paso anterior
            </button>
          )}
          <button
            style={{
              width: "140px",
              height: "40px",
              background: "rgba(0,0,0,0.2)",
              borderRadius: "8px",
              border: "none",
              fontSize: "14px",
              fontWeight: 700,
              color: "#fff",
            }}
            onClick={() => {
              if (step === 2) {
                let shouldCreate = true;
                if (createClientRequest.brandConfig.logoBase64 === "") {
                  shouldCreate = false;
                  setLogoError("Debe subir un logo");
                }
                if (shouldCreate) {
                  dispatch(createClient());
                }
              } else {
                let nextStep = step + 1;
                if (step === 1) {
                  if (createClientRequest.projectId === "") {
                    setProjectError("Debe seleccionar un proyecto");
                    nextStep = step;
                  }
                  if (createClientRequest.name === "") {
                    setNameError("Debe ingresar un nombre de cliente");
                    nextStep = step;
                  }
                }
                setStep(nextStep);
              }
            }}
          >
            {step === 2 ? "Finalizar" : "Siguiente paso"}
          </button>
        </div>
        <Modal
          isOpen={modalCreateSuccess}
          onRequestClose={closeModalCreateSuccess}
          style={customStylesModal}
          contentLabel="Cliente creado"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
                Se ha creado el cliente de manera exitosa
              </h5>
              <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
                A continuación se encuentran las credenciales de acceso al
                backoffice que debes entregar al cliente.
              </h5>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "20px 0 0 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(0, 0, 0, 0.05)",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                    borderRadius: "6px",
                    width: "220px",
                    height: "30px",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#000",
                    margin: "0 5px 0 0",
                  }}
                >
                  {createClientResponse?.username}
                </div>
                <button
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                    background: "rgba(0, 0, 0, 0.05)",
                    border: "0.5px solid #000",
                    borderRadius: "6px",
                    width: "80px",
                    height: "30px",
                    padding: "0 1px 0 1px ",
                    margin: "0 5px 0 0",
                  }}
                  onClick={() => {
                    setCopyPassword(false);
                    setCopyUsername(true);
                    navigator.clipboard.writeText(
                      createClientResponse?.username!
                    );
                  }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: 600,
                      color: "#000",
                      margin: "0",
                    }}
                  >
                    {copyUsername ? "Copiado" : "Copiar"}
                  </p>{" "}
                  <Copy />
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "20px 0 0 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(0, 0, 0, 0.05)",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                    borderRadius: "6px",
                    width: "220px",
                    height: "30px",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#000",
                    margin: "0 5px 0 0",
                  }}
                >
                  {createClientResponse?.password}
                </div>
                <button
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                    background: "rgba(0, 0, 0, 0.05)",
                    border: "0.5px solid #000",
                    borderRadius: "6px",
                    width: "80px",
                    height: "30px",
                    padding: "0 1px 0 1px ",
                    margin: "0 5px 0 0",
                  }}
                  onClick={() => {
                    setCopyPassword(true);
                    setCopyUsername(false);
                    navigator.clipboard.writeText(
                      createClientResponse?.password!
                    );
                  }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: 600,
                      color: "#000",
                      margin: "0",
                    }}
                  >
                    {copyPassword ? "Copiado" : "Copiar"}
                  </p>{" "}
                  <Copy />
                </button>
              </div>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();

                closeModalCreateSuccess();
              }}
            >
              De acuerdo
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalError}
          onRequestClose={closeModalError}
          style={customStylesModal}
          contentLabel="Error creación de cliente"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
                Ha habido un error al crear el cliente. Por favor, inténtalo de
                nuevo.
              </h5>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();

                closeModalError();
              }}
            >
              Intentar de nuevo
            </button>
          </div>
        </Modal>
      </div>
    );
  }
};
