let getStatusName = (status: string) => {
  switch (status) {
    case "Created":
      return "NO Firmado";
    case "Validated":
      return "Validado";
    case "Invalid":
      return "Invalido";
    case "Failed":
      return "Fallido";
    case "Signing":
      return "Firmando";
    case "Signed":
      return "Firmado";
    case "Confirmed":
      return "Empezado";
    case "Started":
      return "Empezado";
    default:
      return "";
  }
};

let getStatusPagareName = (status: string) => {
  switch (status) {
    case "Created":
      return "NO Firmado";
    case "Signed":
      return "Firmado";
    case "Filled":
      return "Diligenciado";
    case "Endosado":
      return "Endosado";
      case "Nullified":
        return "Anulado";
    case "BlockedByEndosoValidation":
      return "Bloqueado por verificación de endoso";
    default:
      return status;
  }
};

let getTipoPagareName = (type: string) => {
  switch (type) {
    case "Blank":
      return "En blanco con carta de instrucciones";
    default:
      return "Diligenciado";
  }
};

export { getStatusName, getStatusPagareName, getTipoPagareName };
