import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import { NumAlerts, getNumberAlerts } from "../alerts/alertsAPI";


export interface GeneralState {
  loadingStatus: LoadingStatus;
  alerts: NumAlerts ;
  
}

const initialState: GeneralState = {
  loadingStatus: "idle",
 
  alerts:{
    numAlerts:null,
  }
};



export const getNumAlerts = createAsyncThunk(
  "general/numAlerts",
  async (_, { rejectWithValue }) => {
    try {
      const search = await getNumberAlerts();
      if (search.alerts) {
        return search;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const GeneralSlice = createSlice({
  name: "generalSlice",
  initialState,
  reducers: {
    resetRegistration: (state) => {
      state.loadingStatus = "idle";
      state.alerts ={
        numAlerts: null,
      };
    }
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNumAlerts.fulfilled, (state, action) => {
        let resquest = action.payload;

        if (resquest.alerts) {
          state.alerts = resquest.alerts;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getNumAlerts.rejected, (state) => {
        state.loadingStatus = "rejected";
      })
      .addCase(getNumAlerts.pending, (state) => {
        state.loadingStatus = "pending";
      });
  },
});

export const {
  resetRegistration,
} = GeneralSlice.actions;


export const selectGeneralLoading = (state: RootState) =>
  state.general.loadingStatus;
export const selectAlerts = (state: RootState) =>
  state.general.alerts;


export default GeneralSlice.reducer;
