export const HyperflowHelp = () => {
  return (
    <div
      style={{
        overflow: "auto",
        color: "white",
        padding: "2% 2%",
        width: "100%",
        height: "100%",
      }}
    >
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "52px",
        }}
      >
        Introducción
      </h2>
      <p>
        El servicio Hyperflow de anteia es una herramienta de onboarding que
        automatiza procesos.
      </p>
      <p>
        Esta herramienta se divide en varias secciones dependiendo de sus
        necesidades, para entender cada una de las funcionalidades, conceptos y
        terminología utilizada en el backoffice, puede consultar en el siguiente
        PDF.
      </p>
      <p>Algunos de los productos sobre los cuales podrá consultar son:</p>
      <ul>
        <li>CoreId</li>
        <li>Magic Forms</li>
        <li>Firma Electrónica</li>
        <li>Pagarés</li>
        <li>Alertas</li>
        <li>Adjuntos</li>
      </ul>
      <object
        data="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20HYPERFLOW.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Descarga alternativa:{" "}
          <a href="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20HYPERFLOW.pdf">
            Si no se ve puede dar click aquí para descargar el PDF
          </a>
        </p>
      </object>
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "36px",
        }}
      >
        Documentación de API
      </h2>
      <p>
        Además de manejo de la plataforma también ofrecemos documentación del
        API de Hyperflow, la cual puede consultar en el siguiente enlace:
      </p>
      <a
        href="https://anteia.stoplight.io/docs/clients-api/13gstoykkdfrn-hyperflow-api"
        target="blank
      "
      >
        Documentación API
      </a>
    </div>
  );
};
