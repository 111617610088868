import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeActiveComponent,
  changechangeRolesLoading,
  changeLoadingEditUserInfo,
  changeOtherUserdata,
  changeOtheruserDataError,
  changeOtherUserLoading,
  changeUserInfo,
  changeUserRoles,
  deleteUser,
  emptyGeneratedPassword,
  getOtherUser,
  getRoleOptions,
  resetOtherUserData,
  restorePassword,
  selectActiveConfigurationComponent,
  selectChangeRolesLoading,
  selectChangeUserInfoLoading,
  selectCurrentRoles,
  selectDeleteLoading,
  selectGeneratedPassword,
  selectLoadingOtherUser,
  selectOtherUser,
  selectOtherUserDataError,
  selectRestorePasswordLoading,
  selectRoleOptions,
  selectRoleOptionsLoading,
  setCurrentRoles,
  toggleUserStatus,
} from "../configurationSlice";
import { AccountView } from "./components/AccountView";
import { RolesComponent } from "./components/RolesComponent";
import { selectRoles } from "../../common/commonSlice";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const UserDetail = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let loadingOtherUser = useAppSelector(selectLoadingOtherUser);
  let otherUser = useAppSelector(selectOtherUser);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let roleOptionsLoading = useAppSelector(selectRoleOptionsLoading);
  let roleOptions = useAppSelector(selectRoleOptions);
  let otherUserError = useAppSelector(selectOtherUserDataError);
  let currentRoles = useAppSelector(selectCurrentRoles);
  let generatedPassword = useAppSelector(selectGeneratedPassword);
  let restorePasswordLoading = useAppSelector(selectRestorePasswordLoading);
  let changeInfoLoading = useAppSelector(selectChangeUserInfoLoading);
  let deleteUserLoading = useAppSelector(selectDeleteLoading);
  let roles = useAppSelector(selectRoles);
  let { id } = useParams();

  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);
  const [modalChangeInfoIsOpen, setModalChangeInfoIsOpen] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [modalChangeRolesIsOpen, setModalChangeRolesIsOpen] = useState(false);
  let changeRolesLoading = useAppSelector(selectChangeRolesLoading);
  const [copied, setCopied] = useState(false);

  const closePasswordModal = () => {
    setModalPasswordIsOpen(false);
  };

  const closeModalChangeInfo = () => {
    setModalChangeInfoIsOpen(false);
  };

  const closeModalConfirmDelete = () => {
    setModalConfirmDelete(false);
  };

  const closeModalChangeRoles = () => {
    setModalChangeRolesIsOpen(false);
  };

  useEffect(() => {
    if (activeComponent !== "User") {
      dispatch(changeActiveComponent("User"));
    }

    if ((!otherUser && loadingOtherUser === "idle") || otherUser?.id !== id) {
      dispatch(getOtherUser(id || ""));
    }

    if (roleOptions.length === 0 && roleOptionsLoading === "idle") {
      dispatch(getRoleOptions());
    }

    if (
      restorePasswordLoading === "resolved" ||
      restorePasswordLoading === "rejected"
    ) {
      setModalPasswordIsOpen(true);
    }

    if (changeInfoLoading === "resolved") {
      setModalChangeInfoIsOpen(true);
    }

    if (deleteUserLoading === "resolved") {
      dispatch(resetOtherUserData());
      navigate("/configuration/users");
    }

    if (changeRolesLoading === "resolved") {
      setModalChangeRolesIsOpen(true);
    }
  }, [
    restorePasswordLoading,
    changeInfoLoading,
    deleteUserLoading,
    changeRolesLoading,
  ]);
  if (loadingOtherUser === "pending") {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1%",
        }}
      >
        <ClipLoader color="#FFF" size={"200px"} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          padding: "1%",
        }}
      >
        <Modal
          isOpen={modalPasswordIsOpen}
          onRequestClose={closePasswordModal}
          style={customStylesModal}
          contentLabel="Restablecimiento de contraseña"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="text-medium text-normal">
                Se ha restaurado la contraseña de manera correcta, a
                continuación se encuentra la contraseña generada.
              </h5>
              <div
                style={{
                  border: "0.5px solid  rgba(0,0,0)",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "5px 2px 5px 2px",
                  margin: "4px 2px 10px 2px",
                }}
              >
                <h6
                  style={{
                    margin: "0px 5px 0px 5px",
                    textAlign: "center",
                    fontSize: "22px",
                  }}
                >
                  {generatedPassword}
                </h6>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    try {
                      if (generatedPassword) {
                        navigator.clipboard.writeText(generatedPassword);
                        setCopied(true);
                      }
                    } catch {
                      setCopied(false);
                    }
                  }}
                  style={{
                    border: "none",
                    background: "transparent",
                    fontWeight: "700",
                  }}
                >
                  {copied ? "Copiado" : "Copiar"}
                </button>
              </div>
              <h5 className="text-medium text-normal">
                Se le pedirá al usuario que la restablesca en el primer inicio
                de sesión{" "}
              </h5>
            </div>
            <div></div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                setCopied(false);
                dispatch(emptyGeneratedPassword());
                setModalPasswordIsOpen(false);
              }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalChangeInfoIsOpen}
          onRequestClose={closeModalChangeInfo}
          style={customStylesModal}
          contentLabel="Cambió información"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="text-medium text-normal">
                Se ha cambiado la información del usuario de manera exitosa
              </h5>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(changeLoadingEditUserInfo("idle"));
                dispatch(
                  changeOtheruserDataError({
                    emailError: "",
                    identificationError: "",
                    nameError: "",
                    phoneNumberError: "",
                  })
                );
                dispatch(changeOtherUserLoading("idle"));
                dispatch(changeOtherUserdata(null));
                setModalChangeInfoIsOpen(false);
              }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalConfirmDelete}
          onRequestClose={closeModalConfirmDelete}
          style={customStylesModal}
          contentLabel="Eliminar usuario"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="text-medium text-normal">
                ¿Está seguro de eliminar este usuario
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <button
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(deleteUser(id!));
                  setModalChangeInfoIsOpen(false);
                }}
              >
                Sí
              </button>
              <button
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();

                  closeModalConfirmDelete();
                }}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalChangeRolesIsOpen}
          onRequestClose={closeModalChangeRoles}
          style={customStylesModal}
          contentLabel="Cambió roles"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="text-medium text-normal">
                Se han cambiado los roles de manera exitosa
              </h5>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(changechangeRolesLoading("idle"));
                dispatch(changeOtherUserLoading("idle"));
                dispatch(changeOtherUserdata(null));
                closeModalChangeRoles();
              }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
        {otherUser ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                }}
              >
                {roles.includes("users.delete") && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setModalConfirmDelete(true);
                    }}
                    style={{
                      border: "none",
                      background: "rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      padding: "12px 8px 12px 8px",
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#FFF",
                      marginRight: "2%",
                    }}
                  >
                    Eliminar usuario
                  </button>
                )}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(toggleUserStatus(id!));
                  }}
                  style={{
                    border: "none",
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                    padding: "12px 8px 12px 8px",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#FFF",
                  }}
                >
                  {otherUser.active ? "Desactivar usuario" : "Activar usuario"}
                </button>
              </div>
              {/* <button
                style={{
                  border: "none",
                  background: "rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  padding: "12px 8px 12px 8px",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#FFF",
                  width: "250px",
                }}
              >
                Ver historial de usuario
              </button> */}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "1%",
                marginTop: "1%",
              }}
            >
              <AccountView
                changePassword={() => {}}
                confirmChanges={() => {
                  dispatch(changeUserInfo(id!));
                }}
                errorAccountData={otherUserError}
                accountData={otherUser}
                width="48%"
                resetPasswordFunction={() => {
                  dispatch(restorePassword(id!));
                }}
                resetsPassword={true}
                loading={loadingOtherUser}
                resetPasswordLoading={restorePasswordLoading}
                changeUserData={changeOtherUserdata}
                changeErrorData={changeOtheruserDataError}
              />
              <RolesComponent
                cancelChangesFunction={() => {
                  dispatch(setCurrentRoles(otherUser?.roles!));
                }}
                changeRolesFunction={() => {
                  dispatch(changeUserRoles(id!));
                }}
                width="48%"
                currentRoles={currentRoles}
                immutableRoles={otherUser.roles}
                predeterminedRoles={roleOptions}
                loading={roleOptionsLoading}
                visibility={"visible"}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <h4 style={{ color: "#FFF" }}>No existe el usuario</h4>
          </div>
        )}
      </div>
    );
  }
};
