import { createStyles, makeStyles } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  MonitoringReg,
  getCurrentIds,
  getNewIds,
  getPics,
  getReg,
  loadAllClientNames,
  loadAllCurrentIds,
  reloadNecesaryIds,
  selectMasterLoading,
  selectRegs,
} from "./liveMonitoringSlice";
import { MultiFactorMatrixData, RegistrationDetail } from "../coreid/coreIdAPI";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { formatDateStandar } from "../../../helpers/utils";
import { LoadingStatus } from "../../common/commonSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import { ClipLoader } from "react-spinners";
import ImageGallery from "react-image-gallery";
import { RegPicResponse } from "./liveMonitoringApi";
export const useStyles = makeStyles(() =>
  createStyles({
    customBadge: {
      backgroundColor: "transparent",
      color: "white",
      fontSize: "30px",
    },
  })
);

export const LiveMonitoringPage = () => {
  let masterLoading = useAppSelector(selectMasterLoading);
  let regs = useAppSelector(selectRegs);

  let dispatch = useAppDispatch();
  //Request Ids
  useEffect(() => {
    dispatch(getCurrentIds()).then((res) => { });
  }, []);

  //Load All ids
  useEffect(() => {
    if (masterLoading === false) {
      let regIds = [];
      for (let id in regs) {
        regIds.push(id);
      }
      dispatch(loadAllCurrentIds(regIds)).then((_) => {

        loopReload()
      });
    }
  }, [masterLoading]);

  async function loopReload() {
    while (true) {
      await new Promise(r => setTimeout(r, 10000));
      dispatch(getNewIds()).then((_) => {
        dispatch(reloadNecesaryIds())
        let regIds = [];
        for (let id in regs) {
          regIds.push(id);
        }
        dispatch(loadAllClientNames(regIds))
      });
    }
  }

  let drawableRegs: MonitoringReg[] = [];
  for (let id in regs) {
    drawableRegs.push(regs[id]);
  }

  drawableRegs.sort((a, b) => {
    if (a.reg === null || b.reg === null) return 0;
    if (a.reg.startDatetime > b.reg.startDatetime) return -1;
    if (a.reg.startDatetime < b.reg.startDatetime) return 1;
    return 0;
  })

  return (
    <div style={{ height: "100%" }}>
      {masterLoading ? (
        <div>Loading...</div>
      ) : (
        <div style={{ height: "100%", overflow: "scroll" }}>
          {drawableRegs.map((reg) => {
            return (
              <div key={reg.id} style={{ marginTop: "30px" }}>
                <MonitoringRegView reg={reg} onReload={(id: string) => {
                  dispatch(getReg(id));
                  dispatch(getPics(id))
                }} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

interface MonitoringRegViewProps {
  reg: MonitoringReg;
  onReload: (id: string) => void;
}

const MonitoringRegView = (input: MonitoringRegViewProps) => {
  let props = input.reg;
  switch (props.status) {
    case "idle":
      return <div>Idle...</div>;
    case "loading":
      return <div>Loading...</div>;
    case "error":
      return <div>Error</div>;
    case "loaded":
      if (props.reg !== null) {
        let reg = props.reg;
        return (
          <div className="flex-row">
            {/* Control */}
            <div
              className="flex-col"
              style={{
                background: "rgba(0,0,0,0.2)",
                borderRadius: "12px",
                padding: "10px",
                marginRight: "30px",
              }}
            >
              <div className="flex-row">
                <div
                  className={`text-normal text-small primary-color-50`}
                  onClick={() => {
                    input.onReload(props.id);
                  }}
                >
                  Recargar
                </div>
              </div>
            </div>
            {/* Images */}
            <div
              className="flex-col"
              style={{
                width: "30%",
                overflow: "auto",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "12px",
                padding: "10px",
                marginRight: "30px",
              }}
            >
              {Generalmages2({
                currentRegistration: reg,
                loadingImages: props.picsLoading ? "pending" : "resolved",
                pictures: props.pics,
              })}
            </div>
            {/* Main Info */}
            <div
              className="flex-col"
              style={{
                width: "30%",
                overflow: "auto",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "12px",
                padding: "10px",
              }}
            >
              <div className={`text-normal text-small primary-color-50`}>
                Id: {reg.id}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                First Name: {reg.name}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                Last Name: {reg.lastName}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                Document: {reg.userIdentification}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                ClientId: {reg.clientId}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                ClientName: {props.clientName}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                UnifiedChecks: {reg.unifiedChecksOk ? "true" : "false"}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                Done?: {reg.result ? "true" : "false"}
              </div>
              <div className={`text-normal text-small primary-color-50`}>
                Fecha y hora: {formatDateStandar(reg.startDatetime)}
              </div>
            </div>
            {/* Matrix Data */}
            <div
              className="flex-col"
              style={{ width: "35%", overflow: "auto" }}
            >
              {getMatrixDetailData(reg)}
            </div>
          </div>
        );
      } else return <div>Null</div>;
  }
};

interface ImagesProps2 {
  currentRegistration: RegistrationDetail;
  loadingImages: LoadingStatus;
  pictures: RegPicResponse | null;
}

let getImages = (images: Array<string>) => {
  let imageList: { original: string; originalHeight: number }[] = [];
  for (const image of images) {
    imageList.push({
      original: `data:image/png;base64,${image}`,
      originalHeight: 270,
    });
  }
  return imageList;
};

const Generalmages2 = (props: ImagesProps2) => {
  let getGeneralImages = (
    currentRegistration: RegistrationDetail,
    loadingImages: LoadingStatus,
    pictures: RegPicResponse | null
  ) => {
    switch (loadingImages) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingImages === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            No pudimos cargar las imagenes
          </div>
        );
      case "resolved":
        if (pictures === null) return <div>Nada</div>;
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "space-between",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div style={{ width: "50%" }}>
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
                infinite
                showThumbnails={false}
                showBullets
                items={getImages(pictures.docPics)}
              />
            </div>
            <div style={{ width: "40%" }}>
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
                infinite
                showThumbnails={false}
                showBullets
                items={getImages(pictures.facePics)}
              />
            </div>
          </div>
        );
      default:
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            Imagenes de prueba
          </div>
        );
    }
  };
  return (
    <>
      {getGeneralImages(
        props.currentRegistration,
        props.loadingImages,
        props.pictures
      )}
    </>
  );
};

const getMatrixDetailData = (currentRegistration: RegistrationDetail) => {
  return (
    <div className="flex-col" style={{ width: "100%", overflow: "auto" }}>
      <table
        style={{
          width: "90%",
          marginLeft: "5%",
          borderCollapse: "collapse",
          overflow: "hidden",
          borderRadius: "12px",
          padding: "10px",
        }}
      >
        <thead
          className="text-normal text-small primary-color"
          style={{
            background: "rgba(0,0,0,0.2)",
            height: "30px",
            textAlign: "center",
          }}
        >
          <tr style={{ height: "15px" }}>
            <th></th>
            <th>Factor de verificación</th>
            <th>Score (0-100)</th>
            <th>Umbral de Bloqueo</th>
            <th>Pasa Umbral</th>
            <th></th>
          </tr>
        </thead>
        <tbody
          className={`text-normal text-small primary-color-50`}
          style={{
            background: "rgba(0,0,0,0.2)",
            textAlign: "center",
            margin: "5px 0 5px 0",
          }}
        >
          <tr></tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Face match"}</td>
            <td>{currentRegistration.multiFactorMatrix?.faceMatch.score}</td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.faceMatch
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.faceMatch.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Detección de impresiones en documento"}</td>
            <td>{currentRegistration.multiFactorMatrix?.impresion.score}</td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.impresion
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.impresion.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Detección de pantalla para documento"}</td>
            <td>{currentRegistration.multiFactorMatrix?.screen.score}</td>
            <td>
              {currentRegistration.multiFactorMatrix?.screen.blockingThreshold}
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.screen.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Validez-Autenticidad"}</td>
            <td>{currentRegistration.multiFactorMatrix?.validity.score}</td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.validity
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.validity.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Veracidad documento"}</td>
            <td>{currentRegistration.multiFactorMatrix?.veracity.score}</td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.veracity
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.veracity.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Listas"}</td>
            <td>{currentRegistration.multiFactorMatrix?.lists.score}</td>
            <td>
              {currentRegistration.multiFactorMatrix?.lists.blockingThreshold}
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.lists.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          {/* <tr >
          <td>{"Liveness"}</td>
          <td>{ currentRegistration.multiFactorMatrix?.liveness.score}</td>
          <td>{ currentRegistration.multiFactorMatrix?.liveness.blockingThreshold}</td>
          <td>{ currentRegistration.multiFactorMatrix?.liveness.passes?"SI":"NO"}</td>

      </tr>*/}
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"GPS(Geofencin)"}</td>
            <td>{currentRegistration.multiFactorMatrix?.geolocation.score}</td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.geolocation
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.geolocation.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Device ID"}</td>
            <td>{currentRegistration.multiFactorMatrix?.deviceId.score}</td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.deviceId
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.deviceId.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Match datos iniciales"}</td>
            <td>
              {currentRegistration.multiFactorMatrix?.initialDataMatch.score}
            </td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.initialDataMatch
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.initialDataMatch.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr style={{ height: "25px" }}>
            <td></td>
            <td>{"Alertas entidades gubernamentales"}</td>
            <td>{currentRegistration.multiFactorMatrix?.govEntity.score}</td>
            <td>
              {
                currentRegistration.multiFactorMatrix?.govEntity
                  .blockingThreshold
              }
            </td>
            <td>
              {currentRegistration.multiFactorMatrix?.govEntity.passes
                ? "SI"
                : "NO"}
            </td>
            <td></td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
    </div>
  );
};
