import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface CustomizationState {
  backgroundImageUrl: BackgroundUrl;
}

type BackgroundUrl =
  | "https://cdn.anteia.co/back-office/dynamic-background/background1.png";

const initialState: CustomizationState = {
  backgroundImageUrl:
    "https://cdn.anteia.co/back-office/dynamic-background/background1.png",
};

export const customizationSlice = createSlice({
  name: "customization",
  initialState,
  reducers: {
    changeBackgroundImage: (state, action: PayloadAction<BackgroundUrl>) => {
      state.backgroundImageUrl = action.payload;
    },
  },
});

export const { changeBackgroundImage } = customizationSlice.actions;

export const selectBackgroundImageUrl = (state: RootState) =>
  state.customize.backgroundImageUrl;

export default customizationSlice.reducer;
