import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import {
  confirmOtpAPI,
  ConfirmOtpRequest,
  CreatePagareOptions,
  downloadFileAPI,
  endosarAPI,
  EndosarRequest,
  EndosoData,
  FieldBackoffice,
  filloutAPI,
  FillOutRequest,
  GeneratedDocumentsPagare,
  GeneratePagareRequest,
  getCreatePagareOptionsAPI,
  GetFilteredPagareRequest,
  GetFilteredPagareResponse,
  getFilteredPagaresAPI,
  getOwnerByPagare,
  getPagareByIdAPI,
  getPagareDocumentsAPI,
  getPagareFieldsAPI,
  GetPagareResponse,
  getUrlPagareAPI,
  PagareDownloadReq,
  PagareOwner,
  sendOtpConfirmAPI,
  SendPagareConfirmationOtp,
} from "./pagareAPI";

export type PagareActiveComponent = "Pagares" | "Analytics" | "none";

export type EndosoStep = "endosante" | "endosatario" | "confirm";
export interface PagareState {
  loadingPagareStatus: LoadingStatus;
  loadingDocumentStatus: LoadingStatus;
  loadingFieldsStatus: LoadingStatus;
  loadingCreatePagareOptions: LoadingStatus;
  loadingCreatePagare: LoadingStatus;
  loadingOwner: LoadingStatus;
  loadingOtp: LoadingStatus;
  downloadStatus: Array<LoadingStatus>;
  pagares: GetFilteredPagareResponse | null;
  currentFilteredRequest: GetFilteredPagareRequest;
  currentPage: number;
  currentNumPages: number;
  activeComponent: PagareActiveComponent;
  currentPagare: GetPagareResponse | null;
  currentDocuments: GeneratedDocumentsPagare | null;
  notFoundMessage: string;
  currentFields: FieldBackoffice[];
  sentOtp: boolean;
  sendAgain: boolean;
  idConfirmedPagare: string | null;
  filled: boolean;
  endosado: boolean;
  endosarRequest: EndosarRequest;
  endosoStep: EndosoStep;
  createPagareOptions: CreatePagareOptions | null;
  generatePagareRequest: GeneratePagareRequest;
  generatedUrl: string;
  currentOwner: PagareOwner | null;
}

const initialState: PagareState = {
  loadingPagareStatus: "idle",
  loadingDocumentStatus: "idle",
  loadingFieldsStatus: "idle",
  loadingOtp: "idle",
  loadingCreatePagareOptions: "idle",
  loadingOwner: "idle",
  downloadStatus: [],
  activeComponent: "none",
  pagares: null,
  currentPage: 1,
  currentNumPages: 0,
  currentDocuments: null,
  notFoundMessage: "",
  currentFilteredRequest: {
    endDate: null,
    page: 0,
    startDate: null,
    status: null,
    type: null,
  },
  currentPagare: null,
  currentFields: [],
  sentOtp: false,
  sendAgain: false,
  idConfirmedPagare: null,
  filled: false,
  endosado: false,
  endosarRequest: {
    endosante: {
      email: "",
      identification: "",
      identificationType: "",
      idLegalRepresentative: "",
      idTypeLegalRepresentative: "",
      naturalPerson: true,
    },
    endosatario: {
      email: "",
      identification: "",
      identificationType: "",
      idLegalRepresentative: "",
      idTypeLegalRepresentative: "",
      naturalPerson: true,
    },
    idConfirmation: "",
    sellValue: 0,
    withResponsability: false,
  },
  createPagareOptions: null,
  generatePagareRequest: {
    currency: "",
    documentBase64: "",
    documentName: "",
    pagareTemplateId: "",
    projectId: "",
    redirectionUrl: "",
    value: 0,
  },
  loadingCreatePagare: "idle",
  generatedUrl: "",
  currentOwner: null,
  endosoStep: "endosante",
};

export const getFilteredPagares = createAsyncThunk(
  "pagares/getFilteredPagares",
  async (params: GetFilteredPagareRequest, { rejectWithValue }) => {
    try {
      const filteredPagares = await getFilteredPagaresAPI(params);
      if (filteredPagares.filteredPagares) {
        return filteredPagares;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getPagareById = createAsyncThunk(
  "pagares/getSignatureById",
  async (id: string, { rejectWithValue }) => {
    try {
      const pagare = await getPagareByIdAPI(id);
      return pagare.pagare;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getPagareOwner = createAsyncThunk(
  "pagares/getPagareOwner",
  async (id: string, { rejectWithValue }) => {
    const ownerResult = await getOwnerByPagare(id);
    if (ownerResult.error) {
      rejectWithValue(ownerResult.error);
    } else {
      return ownerResult.owner;
    }
  }
);

export const getDocumentsPagare = createAsyncThunk(
  "pagares/getDocumentsPagare",
  async (id: string, { rejectWithValue }) => {
    try {
      const documents = await getPagareDocumentsAPI(id);
      return documents.documents;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFieldsPagare = createAsyncThunk(
  "pagares/getFieldsPagare",
  async (id: string, { rejectWithValue }) => {
    try {
      const fields = await getPagareFieldsAPI(id);
      return fields;
    } catch (err: any) {
      return rejectWithValue([]);
    }
  }
);

export const sendOtpConfirm = createAsyncThunk(
  "pagares/sendOtpConfirm",
  async (req: SendPagareConfirmationOtp) => {
    const result = await sendOtpConfirmAPI(req);
    if (result) {
      return result;
    } else {
      const resultAgain = await sendOtpConfirmAPI(req);
      return resultAgain;
    }
  }
);

export const confirmOtp = createAsyncThunk(
  "pagares/confirmOtp",
  async (req: ConfirmOtpRequest, { rejectWithValue }) => {
    const result = await confirmOtpAPI(req);
    if (result.idConfirmedPagare && result.idConfirmedPagare !== "") {
      return result.idConfirmedPagare;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const fillout = createAsyncThunk(
  "pagares/fillout",
  async (idConfirmation: string, { getState }) => {
    const state = getState() as RootState;
    let filloutReq: FillOutRequest = {
      fields: state.pagare.currentFields,
      idConfirmation,
    };
    let response = await filloutAPI(filloutReq);

    return response;
  }
);

export const endosar = createAsyncThunk(
  "pagares/endosar",
  async (idConfirmation: string, { getState }) => {
    const state = getState() as RootState;
    let endosarReq: EndosarRequest = {
      ...state.pagare.endosarRequest,
      idConfirmation,
    };
    let response = await endosarAPI(endosarReq);

    return response;
  }
);

export const downloadFile = createAsyncThunk(
  "pagares/download",
  async (data: PagareDownloadReq) => {
    try {
      await downloadFileAPI(data.fileName);
      return data.index;
    } catch {
      return data.index;
    }
  }
);

export const getPagareOptions = createAsyncThunk(
  "pagare/getOptions",
  async () => {
    let pagareOptions = await getCreatePagareOptionsAPI();

    return pagareOptions;
  }
);

export const generateUrl = createAsyncThunk(
  "pagare/generateUrl",
  async (_, { getState, rejectWithValue }) => {
    let state = getState() as RootState;
    let req = state.pagare.generatePagareRequest;
    try {
      let url = await getUrlPagareAPI(req);

      return url;
    } catch (err: any) {
      rejectWithValue(null);
    }
  }
);

export const pagareSlice = createSlice({
  name: "pagareSlice",
  initialState,
  reducers: {
    changeActiveComponent: (
      state,
      action: PayloadAction<PagareActiveComponent>
    ) => {
      state.activeComponent = action.payload;
    },
    changeCurrentFilters: (
      state,
      action: PayloadAction<GetFilteredPagareRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, pageSize: action.payload };
    },
    resetFields: (state) => {
      state.currentFields = [];
      state.filled = false;
      state.sentOtp = false;
      state.endosado = false;
      state.endosarRequest = {
        endosante: {
          email: "",
          identification: "",
          identificationType: "",
          idLegalRepresentative: "",
          idTypeLegalRepresentative: "",
          naturalPerson: true,
        },
        endosatario: {
          email: "",
          identification: "",
          identificationType: "",
          idLegalRepresentative: "",
          idTypeLegalRepresentative: "",
          naturalPerson: true,
        },
        idConfirmation: "",
        sellValue: 0,
        withResponsability: false,
      };
    },
    resetPagareDetail: (state) => {
      state.currentFields = [];
      state.filled = false;
      state.currentDocuments = null;
      state.currentPagare = null;
      state.sentOtp = false;
      state.endosado = false;
      state.loadingOwner = "idle";
      state.currentOwner = null;
      state.endosarRequest = {
        endosante: {
          email: "",
          identification: "",
          identificationType: "",
          idLegalRepresentative: "",
          idTypeLegalRepresentative: "",
          naturalPerson: true,
        },
        endosatario: {
          email: "",
          identification: "",
          identificationType: "",
          idLegalRepresentative: "",
          idTypeLegalRepresentative: "",
          naturalPerson: true,
        },
        idConfirmation: "",
        sellValue: 0,
        withResponsability: false,
      };
    },
    setEndoso: (state, action: PayloadAction<EndosarRequest>) => {
      state.endosarRequest = action.payload;
    },
    setEndosante: (state, action: PayloadAction<EndosoData>) => {
      state.endosarRequest.endosante = action.payload;
    },
    setEndosatario: (state, action: PayloadAction<EndosoData>) => {
      state.endosarRequest.endosatario = action.payload;
    },
    changeEndosoStep: (
      state,
      action: PayloadAction<{ endosoStep: EndosoStep }>
    ) => {
      state.endosoStep = action.payload.endosoStep;
    },
    setValuesFields: (
      state,
      action: PayloadAction<
        {
          id: string;
          value: string;
        }[]
      >
    ) => {
      let fields = state.currentFields;
      for (const field of fields) {
        let fieldValue = action.payload.find((x) => x.id == field.id);
        field.value = fieldValue?.value!;
      }
      state.currentFields = fields;
    },
    updatePagareCreate: (
      state,
      action: PayloadAction<GeneratePagareRequest>
    ) => {
      state.generatePagareRequest = action.payload;
    },
    resetCreatePagare: (state) => {
      state.generatePagareRequest = {
        currency: "",
        documentBase64: "",
        documentName: "",
        pagareTemplateId: "",
        projectId: "",
        redirectionUrl: "",
        value: 0,
      };
      state.generatedUrl = "";
      state.loadingCreatePagareOptions = "idle";
      (state.createPagareOptions = null), (state.loadingCreatePagare = "idle");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredPagares.fulfilled, (state, action) => {
        let pagares = action.payload;

        if (pagares.filteredPagares) {
          state.pagares = pagares.filteredPagares;
          state.currentPage = pagares.filteredPagares.currentPage;
          state.currentNumPages = pagares.filteredPagares.numPages;
          state.loadingPagareStatus = "resolved";
        } else {
          state.loadingPagareStatus = "rejected";
        }
      })
      .addCase(getFilteredPagares.rejected, (state) => {
        state.loadingPagareStatus = "rejected";
        state.currentPage = 0;
        state.currentNumPages = 0;
      })
      .addCase(getFilteredPagares.pending, (state) => {
        state.loadingPagareStatus = "pending";
      })
      .addCase(getPagareById.fulfilled, (state, action) => {
        let pagare = action.payload;
        if (pagare) {
          state.currentPagare = pagare;
          state.loadingPagareStatus = "resolved";
          state.loadingDocumentStatus = "idle";
        } else {
          state.loadingPagareStatus = "rejected";
          state.loadingDocumentStatus = "rejected";
        }
      })
      .addCase(getPagareById.rejected, (state) => {
        state.loadingPagareStatus = "rejected";
        state.loadingDocumentStatus = "rejected";
      })
      .addCase(getPagareById.pending, (state) => {
        state.loadingDocumentStatus = "pending";
        state.loadingPagareStatus = "pending";
      })
      .addCase(getDocumentsPagare.fulfilled, (state, action) => {
        let documents = action.payload;
        if (documents) {
          state.currentDocuments = documents;
          state.loadingDocumentStatus = "resolved";
          let downloadFileLoading: Array<LoadingStatus> = [];
          for (const extraDocument of documents.extraDocuments) {
            downloadFileLoading.push("idle");
          }
        } else {
          state.loadingDocumentStatus = "rejected";
        }
      })
      .addCase(getDocumentsPagare.rejected, (state) => {
        state.loadingDocumentStatus = "rejected";
      })
      .addCase(getDocumentsPagare.pending, (state) => {
        state.loadingDocumentStatus = "pending";
      })
      .addCase(getFieldsPagare.fulfilled, (state, action) => {
        state.currentFields = action.payload;
        state.loadingFieldsStatus = "resolved";
      })
      .addCase(getFieldsPagare.pending, (state) => {
        state.loadingFieldsStatus = "pending";
      })
      .addCase(getFieldsPagare.rejected, (state) => {
        state.loadingFieldsStatus = "rejected";
      })
      .addCase(sendOtpConfirm.pending, (state) => {
        state.loadingOtp = "pending";
      })
      .addCase(sendOtpConfirm.fulfilled, (state) => {
        let sendAgain = state.sendAgain;
        state.loadingOtp = "resolved";
        state.sendAgain = !sendAgain;
        state.sentOtp = true;
      })
      .addCase(confirmOtp.pending, (state) => {
        state.loadingOtp = "pending";
      })
      .addCase(confirmOtp.fulfilled, (state, action) => {
        state.idConfirmedPagare = action.payload;
        state.loadingOtp = "resolved";
      })
      .addCase(confirmOtp.rejected, (state) => {
        state.loadingOtp = "rejected";
        state.idConfirmedPagare = null;
      })
      .addCase(fillout.pending, (state) => {
        state.loadingOtp = "pending";
      })
      .addCase(fillout.fulfilled, (state, action) => {
        state.filled = action.payload;
        state.loadingOtp = "resolved";
        state.idConfirmedPagare = null;
      })
      .addCase(endosar.pending, (state) => {
        state.loadingOtp = "pending";
      })
      .addCase(endosar.fulfilled, (state) => {
        state.loadingOtp = "resolved";
        state.endosado = true;
        state.idConfirmedPagare = null;
      })
      .addCase(getPagareOptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.createPagareOptions = action.payload;
          if (
            action.payload.pagareTemplateOptions &&
            action.payload.pagareTemplateOptions.length === 1
          ) {
            state.generatePagareRequest.pagareTemplateId =
              action.payload.pagareTemplateOptions[0].id;
          }
          if (
            action.payload.projectOptions &&
            action.payload.projectOptions.length === 1
          ) {
            state.generatePagareRequest.projectId =
              action.payload.projectOptions[0].id;
          }
          state.loadingCreatePagareOptions = "resolved";
        } else {
          state.loadingCreatePagareOptions = "rejected";
        }
      })
      .addCase(getPagareOptions.pending, (state) => {
        state.loadingCreatePagareOptions = "pending";
      })
      .addCase(generateUrl.pending, (state) => {
        state.loadingCreatePagare = "pending";
      })
      .addCase(generateUrl.fulfilled, (state, action) => {
        if (action.payload && action.payload !== "") {
          state.generatedUrl = action.payload;
          state.loadingCreatePagare = "resolved";
        } else {
          state.loadingCreatePagare = "rejected";
        }
      })
      .addCase(generateUrl.rejected, (state) => {
        state.loadingCreatePagare = "rejected";
      })
      .addCase(downloadFile.pending, (state, action) => {
        state.downloadStatus[action.meta.arg.index] = "pending";
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.downloadStatus[action.meta.arg.index] = "resolved";
      });

    // Owner

    builder
      .addCase(getPagareOwner.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentOwner = action.payload;
          if (action.payload.confirmed) {
            state.endosoStep = "endosatario";
          }
          state.endosarRequest.endosante = {
            email: action.payload.email || "",
            identification: action.payload.identification || "",
            identificationType: action.payload.identificationType || "",
            naturalPerson: action.payload.naturalPerson,
            idLegalRepresentative:
              action.payload.idTypeLegalRepresentative || "",
            idTypeLegalRepresentative:
              action.payload.idTypeLegalRepresentative || "",
          };
          state.loadingOwner = "resolved";
        } else {
          state.loadingOwner = "rejected";
        }
      })
      .addCase(getPagareOwner.pending, (state) => {
        state.loadingOwner = "pending";
      })
      .addCase(getPagareOwner.rejected, (state) => {
        state.loadingOwner = "rejected";
      });
  },
});

export const {
  changeActiveComponent,
  changeCurrentFilters,
  changePageState,
  setValuesFields,
  resetFields,
  resetPagareDetail,
  setEndoso,
  updatePagareCreate,
  resetCreatePagare,
  setEndosante,
  setEndosatario,
  changeEndosoStep,
  changePageSize,
} = pagareSlice.actions;

export const selectNotFoundMessagePagare = (state: RootState) =>
  state.pagare.notFoundMessage;
export const selectActivePagareComponent = (state: RootState) =>
  state.pagare.activeComponent;
export const selectCurrentPagePagare = (state: RootState) =>
  state.pagare.currentPage;
export const selectCurrentNumPagesPagare = (state: RootState) =>
  state.pagare.currentNumPages;
export const selectPageSizePagare = (state: RootState) =>
  state.pagare.currentFilteredRequest.pageSize;
export const selectPagares = (state: RootState) => state.pagare.pagares;
export const selectCurrentFilterPagareRequest = (state: RootState) =>
  state.pagare.currentFilteredRequest;
export const selectLoadingPagares = (state: RootState) =>
  state.pagare.loadingPagareStatus;
export const selectLoadingDocuments = (state: RootState) =>
  state.pagare.loadingDocumentStatus;
export const selectCurrentPagare = (state: RootState) =>
  state.pagare.currentPagare;
export const selectCurrentDocumentsPagare = (state: RootState) =>
  state.pagare.currentDocuments;
export const selectLoadingFields = (state: RootState) =>
  state.pagare.loadingFieldsStatus;
export const selectCurrentFields = (state: RootState) =>
  state.pagare.currentFields;
export const selectSentOtp = (state: RootState) => state.pagare.sentOtp;
export const selectOtpLoading = (state: RootState) => state.pagare.loadingOtp;
export const selectSendAgain = (state: RootState) => state.pagare.sendAgain;
export const selectConfirmationId = (state: RootState) =>
  state.pagare.idConfirmedPagare;
export const selectFilledOut = (state: RootState) => state.pagare.filled;
export const selectEndosado = (state: RootState) => state.pagare.endosado;
export const selectEndoso = (state: RootState) => state.pagare.endosarRequest;
export const selectCreatePagareOptions = (state: RootState) =>
  state.pagare.createPagareOptions;
export const selectLoadingPagareOptions = (state: RootState) =>
  state.pagare.loadingCreatePagareOptions;
export const selectCurrentPagareCreate = (state: RootState) =>
  state.pagare.generatePagareRequest;
export const selectLoadingCreatePagare = (state: RootState) =>
  state.pagare.loadingCreatePagare;
export const selectGeneratedUrl = (state: RootState) =>
  state.pagare.generatedUrl;
export const selectDownloadingStatus = (state: RootState) =>
  state.pagare.downloadStatus;
export const selectLoadingPagareOwner = (state: RootState) =>
  state.pagare.loadingOwner;
export const selectCurrentPagareOwner = (state: RootState) =>
  state.pagare.currentOwner;
export const selectEndosoStep = (state: RootState) => state.pagare.endosoStep;

export default pagareSlice.reducer;
