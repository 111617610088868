export interface SidePanelProps {
  filter?: JSX.Element;
  categories?: JSX.Element;
}

export const SidePanel = (props: SidePanelProps) => {
  return (
    <div
      className="flex-col"
      style={{
        background: "rgba(0,0,0,0.1)",
        minWidth: "270px",
        width: "20%",
        height: "100%",
        justifyContent: props.filter ? "space-between" : "end",
        alignItems: "center",
        paddingTop: "15px",
        paddingBottom: "10px",
      }}
    >
      <div
        className="flex-col"
        style={{
          width: "100%",
          alignItems: "center",
          marginBottom: "2px",
        }}
      >
        {props.filter ? props.filter : ""}
      </div>
      {props.categories ? props.categories : ""}
    </div>
  );
};
