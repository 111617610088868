import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeActiveComponent,
  selectActiveConfigurationComponent,
} from "../configurationSlice";
import {
  changeEditClientRequest,
  editClient,
  getPossibleProjects,
  resetClient,
  selectEditClientRequest,
  selectLoadingEditClient,
  selectLoadingPossibleProjects,
  selectPossibleProjects,
  selectSubclientDetail,
} from "./clientsSlice";
import { DropDown } from "../../common/components/Btns/DropDown";
import { ChromePicker } from "react-color";
import { arrayBufferToBase64 } from "../../services/pagares/utils";
import { ReactComponent as Delete } from "../../../Assets/common/images/delete.svg";
import { ReactComponent as Change } from "../../../Assets/common/images/change.svg";
import { ReactComponent as Upload } from "../../../Assets/common/images/upload.svg";
import icono1 from "../../../Assets/common/images/ICO USER.png";
import icono3 from "../../../Assets/common/images/video.png";
import icono5 from "../../../Assets/common/images/microphone.png";
import icono6 from "../../../Assets/common/images/ICO SMS.png";
import logoanteia from "../../../Assets/common/images/logo_anteia.png";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "25%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const EditSubclient = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let loadingEditClient = useAppSelector(selectLoadingEditClient);
  let editClientRequest = useAppSelector(selectEditClientRequest);
  let possibleProjects = useAppSelector(selectPossibleProjects);
  let loadingPossibleProjects = useAppSelector(selectLoadingPossibleProjects);
  let currentClient = useAppSelector(selectSubclientDetail);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);

  const [modalEditSuccess, setModalEditSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const [colorPicker1, setColorPicker1] = useState(false);
  const [colorPicker2, setColorPicker2] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [projectOptions, setProjectOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [nameError, setNameError] = useState("");
  const [projectEror, setProjectError] = useState("");

  const closeModalEditSuccess = () => {
    setModalEditSuccess(false);
    dispatch(resetClient());
    navigate(`/configuration/subclients/${id}`);
  };

  const previsualization = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "10px",
              color: "#fff",
              margin: "0",
            }}
          >
            Antes de comenzar
          </p>

          <>
            {editClientRequest.brandConfig.logoBase64 ? (
              <img
                src={
                  "data:image/png;base64," +
                  editClientRequest.brandConfig.logoBase64
                }
                loading="eager"
                alt="logo empresa"
                style={{
                  maxWidth: "200px",
                  maxHeight: "120px",
                  margin: "10px 0 20px 0",
                  width: "auto",
                  height: "auto",
                }}
              />
            ) : (
              <img
                src={currentClient?.brandConfig.logoUrl}
                loading="eager"
                alt="logo"
                style={{
                  maxWidth: "200px",
                  margin: "10px 0 20px 0",
                  maxHeight: "60px",
                  width: "auto",
                  height: "auto",
                }}
              />
            )}
          </>

          <p
            style={{
              width: "168px",
              textAlign: "center",
              fontWeight: "700",
              fontSize: "10px",
              color: "#fff",
            }}
          >
            necesita acceder, recolectar y almacenar datos de :
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono1}
                alt="Icono"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 0px 0",
                  fontFamily: "Open Sans",
                }}
              >
                Identidad
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "09px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                Consultar la identidad y estado del dispositivo
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono3}
                alt="Icono"
                style={{ width: "20.5px", height: "20.5px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 0px 0",
                  fontFamily: "Open Sans",
                }}
              >
                Cámara de vídeo
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "09px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                {" "}
                Realizar fotografías y videos
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono5}
                alt="Icono"
                style={{ width: "23px", height: "23px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 2px 0",
                  fontFamily: "Open Sans",
                }}
              >
                Micrófono
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "9px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                Grabar sonido
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="instruction_icon" style={{ marginRight: "17px" }}>
              <img
                src={icono6}
                alt="Icono"
                style={{ width: "23.33px", height: "20.97px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "11px",
                  color: "#fff",
                  margin: "0 0 2px 0",
                  fontFamily: "Open Sans",
                }}
              >
                SMS
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "9px",
                  lineHeight: "11px",
                  color: "#fff",
                  width: "160px",
                  margin: "0",
                  textAlign: "start",
                  fontFamily: "Open Sans",
                }}
              >
                · Recibir mensajes de texto (sms)<br></br>· Enviar mensajes
                (sms) <br></br>· Leer mensajes (sms)
              </p>
            </div>
          </div>
        </div>

        <button
          style={{
            width: "140px",
            height: "30px",
            margin: "15px 0 0 20%",
            border: 0,
            borderRadius: "8px",
            color: "#fff",
            fontSize: "12px",
            textAlign: "center",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
          }}
        >
          SIGUIENTE
        </button>
      </div>
    );
  };

  const step1 = () => {
    return (
      <>
        <div
          style={{
            background: "rgba(0,0,0,0.2)",
            width: "80%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px 8px 0 0px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px 8px 0 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "35px",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px 8px 0 0px",
                color: "rgba(255,255,255,1)",
                textAlign: "center",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 600,
                padding: "0 10px",
              }}
            >
              Información básica del cliente y flujo
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  padding: "6% 0 0 6%",
                }}
              >
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                  }}
                >
                  Nombre
                </h6>
                <input
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "290px",
                    padding: "6px 10px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                  value={editClientRequest?.name}
                  onChange={(e) => {
                    dispatch(
                      changeEditClientRequest({
                        ...editClientRequest,
                        name: e.target.value,
                      })
                    );
                    setNameError("");
                  }}
                />
                {nameError && (
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "2px 2px 0 0",
                      maxWidth: "290px",
                    }}
                  >
                    {nameError}
                  </p>
                )}
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                    marginTop: "20px",
                  }}
                >
                  NIT
                </h6>
                <input
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "290px",
                    padding: "6px 10px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                  value={editClientRequest?.nit}
                  onChange={(e) => {
                    let onlyDigitRegex = /^[0-9]+$/;
                    if (
                      onlyDigitRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      dispatch(
                        changeEditClientRequest({
                          ...editClientRequest,
                          nit: e.target.value,
                        })
                      );
                    }
                  }}
                />
                {projectOptions.length > 0 && (
                  <DropDown
                    key={"Projects"}
                    title="Proyectos"
                    options={projectOptions}
                    defaultValue={projectOptions[0]}
                    isClearable={false}
                    onChange={(e) => {
                      dispatch(
                        changeEditClientRequest({
                          ...editClientRequest,
                          projectId: e?.value!,
                        })
                      );
                      setProjectError("");
                    }}
                  />
                )}{" "}
                {projectEror && (
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "2px 2px 0 0",
                    }}
                  >
                    {projectEror}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20%",
          }}
        >
          <div
            style={{
              padding: "14px",
              background: "rgba(0,0,0,0.2)",
              color: "rgba(255,255,255,0.5)",
              borderRadius: "8px",
              fontSize: "12px",
              marginLeft: "10px",
              fontWeight: 600,
            }}
          >
            Durante este paso, podrás seleccionar la información básica del
            cliente. Además, tendrás la opción de elegir si el metacliente podrá
            ver la información del subcliente que estás creando. Por último,
            deberás asignar un proyecto al cliente, lo cual determinará el flujo
            que utilizará el subcliente.
          </div>
        </div>
      </>
    );
  };

  const step2 = () => {
    return (
      <>
        <div
          style={{
            background: "rgba(0,0,0,0.2)",
            width: "80%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px 8px 0 0px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px 8px 0 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "35px",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px 8px 0 0px",
                color: "rgba(255,255,255,1)",
                textAlign: "center",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 600,
                padding: "0 10px",
              }}
            >
              Interfaz del flujo
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                  height: "100%",
                  padding: "0 0 0 6%",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "5px 0 20% 5px" }}>
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      borderRadius: "8px",
                      width: "150px",
                      background: "rgba(0,0,0,0.2)",
                      padding: "8px",
                    }}
                    htmlFor="fileInput"
                  >
                    <input
                      style={{
                        display: "none",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onChange={async (e) => {
                        let file = e.target.files ? e.target.files[0] : null;
                        let type = "";
                        let fileBase64 = "";
                        if (file) {
                          type = file.type;
                          let temp = await file.arrayBuffer();
                          fileBase64 = arrayBufferToBase64(temp);
                        }
                        if (
                          type === "image/png" ||
                          type === "image/jpeg" ||
                          type === "image/jpg"
                        ) {
                          dispatch(
                            changeEditClientRequest({
                              ...editClientRequest,
                              brandConfig: {
                                ...editClientRequest.brandConfig,
                                logoBase64: fileBase64,
                              },
                            })
                          );
                          setFileErrorMessage("");
                        } else {
                          setFileErrorMessage("Formato no soportado");
                        }
                      }}
                      type="file"
                      id="fileInput"
                    />

                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        color: "white",
                        margin: "0 0 0 14px",
                      }}
                    >
                      {editClientRequest.brandConfig.logoBase64
                        ? "Cambiar Logo"
                        : "Agregar Logo"}
                    </div>
                    {editClientRequest.brandConfig.logoBase64 ? (
                      <Change />
                    ) : (
                      <Upload />
                    )}
                  </label>
                  {fileErrorMessage && (
                    <p
                      style={{
                        color: "#FF9494",

                        fontSize: "10px",
                      }}
                    >
                      {fileErrorMessage}{" "}
                    </p>
                  )}

                  {editClientRequest.brandConfig.logoBase64 && (
                    <button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        borderRadius: "8px",
                        width: "150px",
                        background: "rgba(0,0,0,0.2)",
                        margin: "10px 0 0 0",
                        padding: "8px",
                        border: "none",
                      }}
                      onClick={() => {
                        dispatch(
                          changeEditClientRequest({
                            ...editClientRequest,
                            brandConfig: {
                              ...editClientRequest.brandConfig,
                              logoBase64: "",
                            },
                          })
                        );
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 700,
                          color: "white",
                          margin: "0 0 0 14px",
                        }}
                      >
                        Eliminar Logo
                      </div>
                      <Delete />
                    </button>
                  )}
                </div>
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                  }}
                >
                  Color 1
                </h6>
                <button
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "152px",
                    padding: "3px 4px",
                  }}
                  onClick={() => {
                    setColorPicker1(!colorPicker1);
                  }}
                >
                  <h5
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                      margin: "3px 6px 3px 20px",
                      textTransform: "uppercase",
                    }}
                  >
                    {editClientRequest.brandConfig.color1}
                  </h5>
                  <div
                    style={{
                      border: "none",
                      background: editClientRequest.brandConfig.color1,
                      borderRadius: "6px",
                      height: "100%",
                      width: "30px",
                    }}
                  ></div>
                </button>
                <div>
                  {colorPicker1 && (
                    <div style={{ position: "absolute", zIndex: 2 }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() => setColorPicker1(false)}
                      />
                      <ChromePicker
                        color={editClientRequest?.brandConfig?.color1}
                        styles={{
                          default: {
                            body: { fontFamily: "Open Sans" },
                          },
                        }}
                        disableAlpha
                        onChange={(e) => {
                          dispatch(
                            changeEditClientRequest({
                              ...editClientRequest,
                              brandConfig: {
                                ...editClientRequest.brandConfig,
                                color1: e.hex,
                              },
                            })
                          );
                        }}
                        onChangeComplete={(e) => {
                          dispatch(
                            changeEditClientRequest({
                              ...editClientRequest,
                              brandConfig: {
                                ...editClientRequest.brandConfig,
                                color1: e.hex,
                              },
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                    marginTop: "20px",
                  }}
                >
                  Color 2
                </h6>
                <button
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "152px",
                    padding: "3px 4px",
                  }}
                  onClick={() => {
                    setColorPicker2(!colorPicker2);
                  }}
                >
                  <h5
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                      margin: "3px 6px 3px 20px",
                      textTransform: "uppercase",
                    }}
                  >
                    {editClientRequest.brandConfig.color2}
                  </h5>
                  <div
                    style={{
                      border: "none",
                      background: editClientRequest.brandConfig.color2,
                      borderRadius: "6px",
                      height: "100%",
                      width: "30px",
                    }}
                  ></div>
                </button>
                <div>
                  {colorPicker2 && (
                    <div style={{ position: "absolute", zIndex: 2 }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() => setColorPicker2(false)}
                      />
                      <ChromePicker
                        color={editClientRequest?.brandConfig?.color2}
                        styles={{
                          default: {
                            body: { fontFamily: "Open Sans" },
                          },
                        }}
                        disableAlpha
                        onChange={(e) => {
                          dispatch(
                            changeEditClientRequest({
                              ...editClientRequest,
                              brandConfig: {
                                ...editClientRequest.brandConfig,
                                color2: e.hex,
                              },
                            })
                          );
                        }}
                        onChangeComplete={(e) => {
                          dispatch(
                            changeEditClientRequest({
                              ...editClientRequest,
                              brandConfig: {
                                ...editClientRequest.brandConfig,
                                color2: e.hex,
                              },
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "60%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: 700,
                    textAlign: "center",
                    margin: "0 0 15px 0",
                  }}
                >
                  Previsualización
                </h5>
                <div
                  style={{
                    borderRadius: "8px",
                    height: "80%",
                    width: "250px",
                    background: `radial-gradient(${editClientRequest.brandConfig.color1}, ${editClientRequest.brandConfig.color2})`,
                    overflow: "auto",
                    padding: "20px 20px",
                  }}
                >
                  {previsualization()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20%",
          }}
        >
          <div
            style={{
              padding: "14px",
              background: "rgba(0,0,0,0.2)",
              color: "rgba(255,255,255,0.5)",
              borderRadius: "8px",
              fontSize: "12px",
              marginLeft: "10px",
              fontWeight: 600,
            }}
          >
            En este paso podrá configurar la interfaz del flujo que se va a
            asignar a su subcliente. A la derecha aparece una previsualización
            de ejemplo.
          </div>
        </div>
      </>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return step1();
      case 2:
        return step2();
      default:
        return step1();
    }
  };

  useEffect(() => {
    if (activeComponent !== "Client") {
      dispatch(changeActiveComponent("Client"));
    }
    if (possibleProjects.length === 0 || loadingPossibleProjects === "idle") {
      dispatch(getPossibleProjects());
    }
    if (loadingEditClient === "resolved") {
      setModalEditSuccess(true);
    }
    if (
      possibleProjects.length > 0 &&
      loadingPossibleProjects === "resolved" &&
      projectOptions.length === 0
    ) {
      let possibleProjectOptions = possibleProjects.map((project) => {
        return { value: project.projectId, label: project.projectName };
      });

      setProjectOptions(possibleProjectOptions);
      dispatch(
        changeEditClientRequest({
          ...editClientRequest,
          projectId: possibleProjects[0].projectId,
        })
      );
    }
    if (!editClientRequest.name) {
      navigate(`/configuration/subclients/${id}`);
    }
  }, [
    possibleProjects,
    loadingEditClient,
    editClientRequest,
    loadingPossibleProjects,
    projectOptions,
  ]);
  if (loadingEditClient === "pending") {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1%",
        }}
      >
        <ClipLoader color="#FFF" size={"200px"} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "1%",
        }}
      >
        <h4
          style={{
            fontSize: "14px",
            fontWeight: 600,
            color: "rgba(255,255,255,0.5)",
            marginLeft: "10px",
          }}
        >
          Edición del cliente
        </h4>
        <div
          style={{
            width: "100%",
            height: "80%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {renderStep()}
        </div>
        <div
          style={{
            width: "80%",
            height: "10%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "130px",
              height: "33px",
              borderRadius: "30px",
              background: "rgba(0,0,0,0.2)",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "15px",
              fontWeight: 700,
            }}
          >
            Paso {step} - 2
          </div>
          <button
            style={{
              width: "140px",
              height: "40px",
              background: "rgba(0,0,0,0.2)",
              borderRadius: "8px",
              border: "none",
              fontSize: "14px",
              fontWeight: 700,
              color: "#fff",
            }}
            onClick={() => {
              if (step === 2) {
                dispatch(editClient());
              } else {
                setStep(step + 1);
              }
            }}
          >
            {step === 2 ? "Finalizar" : "Siguiente paso"}
          </button>
        </div>
        <Modal
          isOpen={modalEditSuccess}
          onRequestClose={closeModalEditSuccess}
          style={customStylesModal}
          contentLabel="Cliente creado"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
                Información actualizada correctamente
              </h5>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();

                closeModalEditSuccess();
              }}
            >
              De acuerdo
            </button>
          </div>
        </Modal>
      </div>
    );
  }
};
