import { ReactComponent as DownArrow } from "../../../../Assets/common/images/downArrow.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  changeCurrentSigner,
  getGeneratedDocumentsNew,
  getSignatureById,
  selectCurrentDocuments,
  selectCurrentFirma,
  selectDocumentsLoading,
  selectLoading,
} from "../firmaNewSlice";
import ClipLoader from "react-spinners/ClipLoader";
import {
  base64PDFToBlobUrl,
  formatDateStandar,
} from "../../../../helpers/utils";
import { LoadingStatus, selectRoles } from "../../../common/commonSlice";
import {
  MediumSquare,
  SmallSquare,
} from "../../firma/detail/components/Squares";
import { getStatusName } from "../../firma/helpers/utils";
import { ReactComponent as DeleteTag } from "../../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../../Assets/common/images/addTag.svg";
import { GeneratedDocuments } from "../../firma/firmaAPI";
import fileDownload from "js-file-download";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByEntity,
  selectAllTags,
  selectCurrentEntity,
  selectCurrentTagCreation,
  selectCurrentTagsByEntity,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByEntity,
  setTagCreation,
} from "../../tags/tagSlice";

export const DetailFirmaNew = () => {
  let loadingStatus = useAppSelector(selectLoading);
  let documentsLoading = useAppSelector(selectDocumentsLoading);
  let currentSignature = useAppSelector(selectCurrentFirma);
  let documents = useAppSelector(selectCurrentDocuments);

  // Tag stuff
  let currentEntity = useAppSelector(selectCurrentEntity);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let allTags = useAppSelector(selectAllTags);
  let currentEntityTags = useAppSelector(selectCurrentTagsByEntity);
  let loadingEntityTags = useAppSelector(selectLoadingCurrentTagsByEntity);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState(false);
  let roles = useAppSelector(selectRoles);

  const [documentToVisualize, setDocumentToVisualize] = useState<{
    documentBase64: string;
    documentName: string;
    url: string;
  }>({
    documentBase64: "",
    documentName: "",
    url: "",
  });

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  const formatDateStandarNew = (dateString: string, status: string) => {
    switch (status) {
      case "Created":
        return "NO Firmado";
      case "Validated":
        return "NO Firmado";
      case "Invalid":
        return "NO Firmado";
      case "Failed":
        return "NO Firmado";
      case "Confirmed":
        return "NO Firmado";
      case "Started":
        return "NO Firmado";
      case "Signing":
        return "Firmando";
      default:
        return formatDateStandar(dateString);
    }
  };

  let getDocument = (
    loadingDocuments: LoadingStatus,
    generatedDocuments: GeneratedDocuments,
    documentType: "Certificate" | "SignedDocument",
    status: string,
    setDocumentVisualizer: React.Dispatch<
      React.SetStateAction<{
        documentBase64: string;
        documentName: string;
        url: string;
      }>
    >
  ) => {
    switch (loadingDocuments) {
      case "pending":
        return (
          <LoadingOverlay
            active={documentsLoading === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "resolved":
        if (!generatedDocuments.finishedSignature) {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha terminado el proceso de firma
            </div>
          );
        } else {
          if (documentType === "Certificate") {
            return (
              <>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = base64PDFToBlobUrl(
                      generatedDocuments.certificate.documentBase64
                    );
                    setDocumentVisualizer({
                      documentBase64:
                        generatedDocuments.certificate.documentBase64,
                      documentName: generatedDocuments.certificate.documentName,
                      url,
                    });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.certificate.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(blob, "Certificado.pdf");
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    let url = base64PDFToBlobUrl(
                      generatedDocuments.document.documentBase64
                    );
                    setDocumentVisualizer({
                      documentBase64:
                        generatedDocuments.document.documentBase64,
                      documentName: generatedDocuments.document.documentName,
                      url,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.document.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          generatedDocuments.document.documentName
                        );
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          }
        }
      case "rejected":
        if (status !== "Signed") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha terminado el proceso de firma
            </div>
          );
        } else {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No pudimos cargar los documentos
            </div>
          );
        }
      default:
        return (
          <>
            <div
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginBottom: "2%",
              }}
            >
              <div style={{ marginRight: "5%" }}>Visualizar</div>
              <EyeIcon />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <div style={{ marginRight: "5%" }}>Descargar</div>
              <EyeIcon />
            </div>
          </>
        );
    }
  };

  const getTagsRender = () => {
    if (loadingEntityTags == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gridRowGap: "5px",
            margin: "5px 0px",
          }}
        >
          {currentEntityTags.map((tag) => {
            return (
              <div
                className="flex-row"
                style={{
                  backgroundColor: "rgba(0,0,0,0.25)",
                  borderRadius: "8px",
                  padding: "3px 8px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: 600,
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  {tag.tag}
                </div>
                {roles.includes("tags.edit") && (
                  <DeleteTag
                    onClick={() => {
                      dispatch(
                        disassociateTag({
                          tagId: tag.id,
                          entityId: id!,
                          service: "FIRMA",
                        })
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            );
          })}
          <div>
            <div
              className="flex-row"
              style={{
                backgroundColor: "rgba(0,0,0,0.25)",
                borderRadius: "8px",
                padding: "1px 8px",
                marginRight: "5px",
                marginLeft: "0px",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (roles.includes("tags.edit")) {
                  setAddTagWindow(!addTagWindow);
                }
              }}
            >
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: 600,
                  color: "#fff",
                  marginRight: "5px",
                }}
              >
                Etiquetas
              </div>

              {roles.includes("tags.edit") && (
                <AddTag
                  style={{ cursor: "pointer", margin: "5px 0 5px 5px" }}
                />
              )}
            </div>
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: id!,
                              service: "FIRMA",
                            })
                          );
                        }}
                      />
                      <button
                        style={{
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag && tagCreation.tag.length > 0
                              ? "visible"
                              : "hidden",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: id!,
                                service: "FIRMA",
                                tag: null,
                              })
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: id!,
                                  service: "FIRMA",
                                })
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  let { id } = useParams();

  useEffect(() => {
    if (
      (!currentSignature || currentSignature.documentId !== id) &&
      id !== ""
    ) {
      dispatch(getSignatureById(id || ""));
    }

    if (currentSignature && documentsLoading === "idle") {
      dispatch(getGeneratedDocumentsNew(currentSignature.documentId));
    }

    dispatch(changeActiveComponent("none"));

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentEntity !== id && id) {
      dispatch(getCurrentTagsByEntity({ entityId: id, service: "FIRMA" }));
    }

    return () => {};
  }, [id, currentSignature]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {currentSignature &&
      currentSignature.signers.length > 0 &&
      loadingStatus === "resolved" ? (
        <div className="flex-col" style={{ height: "90%", overflow: "auto" }}>
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "5%",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar a lista de verificaciones
              </div>
            </div>
          </div>
          <div
            className="flex-col"
            style={{
              height: "85%",
              width: "100%",
              justifyContent: "start",
              padding: "1%",
            }}
          >
            <div
              className="flex-row"
              style={{
                justifyContent: "start",
                height: "12%",
              }}
            >
              <SmallSquare
                parentWidth="23%"
                title="Estado de la firma"
                content={<div>{getStatusName(currentSignature.status)}</div>}
              />
              <SmallSquare
                parentWidth="23%"
                title="Fecha de la firma"
                content={
                  <div>
                    {formatDateStandarNew(
                      currentSignature.documentStartedDate,
                      currentSignature.status
                    )}
                  </div>
                }
              />
              <SmallSquare
                parentWidth="23%"
                title="Alertas"
                content={
                  <div>
                    {currentSignature.numAlerts === 0
                      ? "Ninguna"
                      : currentSignature.numAlerts}
                  </div>
                }
              />
              <SmallSquare
                parentWidth="23%"
                title="No. de firmantes"
                content={<div>{currentSignature.numSigners}</div>}
              />
            </div>
            <div
              className="flex-row"
              style={{
                height: "60%",
                justifyContent: "start",
              }}
            >
              <div
                className="flex-col"
                style={{ width: "95%", marginRight: "1%" }}
              >
                <div
                  className="flex-col text-medium text-normal primary-color-35"
                  style={{
                    marginTop: "3%",
                    background: "rgba(0,0,0,0.4)",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    height: "8%",
                  }}
                >
                  Datos de los firmantes
                </div>
                <div
                  className=" text-medium text-normal primary-color"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    height: "85%",
                    marginBottom: "3%",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",

                    padding: "3%",
                    overflow: "auto",
                  }}
                >
                  {currentSignature.signers.map((signer, index) => {
                    return (
                      <div
                        className="flex-col"
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(changeCurrentSigner(signer));
                          navigate(
                            `/services/electronicSignatureNew/signatures/${currentSignature?.documentId}/signer/${signer.signerId}`
                          );
                        }}
                        style={{
                          background: "rgba(0,0,0,0.2)",

                          borderRadius: "8px",
                          padding: "5%",
                          cursor: "pointer",
                          justifyContent: "space-evenly",

                          marginRight: "10px",
                        }}
                      >
                        <div style={{ fontSize: "14px", color: "white" }}>
                          Nombre : {signer.name}
                        </div>
                        <div style={{ fontSize: "14px", color: "white" }}>
                          Apellido : {signer.lastName}
                        </div>
                        <div style={{ fontSize: "14px", color: "white" }}>
                          User ID : {signer.userId}
                        </div>
                        <div style={{ fontSize: "14px", color: "white" }}>
                          Email : {signer.email}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{
                height: "20%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  width: "50%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <MediumSquare
                  parentWidth="46%"
                  title="Documento Firmado"
                  content={getDocument(
                    documentsLoading,
                    documents!,
                    "SignedDocument",
                    currentSignature.status,
                    setDocumentToVisualize
                  )}
                />
                <MediumSquare
                  parentWidth="46%"
                  title="Certificado de firma"
                  content={getDocument(
                    documentsLoading,
                    documents!,
                    "Certificate",
                    currentSignature.status,
                    setDocumentToVisualize
                  )}
                />
              </div>
              {roles.includes("tags.view") && getTagsRender()}
            </div>
          </div>
          {documentToVisualize.documentBase64 !== "" ? (
            <div
              className="flex-col"
              style={{
                position: "absolute",
                left: "1%",
                top: "10%",
                height: "100%",
                width: "100%",
              }}
            >
              <button
                onClick={() =>
                  setDocumentToVisualize({
                    documentBase64: "",
                    documentName: "",
                    url: "",
                  })
                }
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "25px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                  color: "white",
                }}
              >
                Cerrar
              </button>
              <object
                data={documentToVisualize.url}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p>
                  Tu navegador no permite visualizar PDFs puedes descargar el
                  documento aquí
                  <a
                    onClick={() => {
                      fetch(documentToVisualize.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                          fileDownload(
                            blob,
                            "Documento.pdf",
                            "application/pdf"
                          );
                        });
                    }}
                  >
                    Descargar
                  </a>
                </p>
              </object>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
