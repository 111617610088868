import { useEffect } from "react";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { RadioButton } from "../../common/components/Btns/RadioButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { Outlet, useNavigate } from "react-router-dom";
import {
  changeActiveComponent,
  changeCurrentFilters,
  selectActivePagareComponent,
  selectCurrentFilterPagareRequest,
} from "./pagareSlice";
import { Category } from "../components/sidePanel/categories/Category";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import {
  changePagareFilters,
  resetStateAnalytics,
  selectAnalyticsPagareRequest,
} from "../analytics/analyticsSlice";
export const Pagares = () => {
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterPagareRequest);
  let currentAnalyticsRequest = useAppSelector(selectAnalyticsPagareRequest);
  let roles = useAppSelector(selectRoles);

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Pagares") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                startDate: null,
                endDate: null,
              })
            );
          }}
          items={[
            <div
              key={1}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Tipo de Pagaré
            </div>,
            <div key={2} style={{ alignItems: "center" }}>
              <RadioButton
                label="Blanco con carta"
                checked={currentFilteredRequest.type === "Blank"}
                onClick={() => {
                  if (currentFilteredRequest.type === "Blank") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        type: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      type: "Blank",
                    })
                  );
                }}
              />
              <RadioButton
                label="Cerrado"
                checked={currentFilteredRequest.type === "Closed"}
                onClick={() => {
                  if (currentFilteredRequest.type === "Closed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        type: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      type: "Closed",
                    })
                  );
                }}
              />
            </div>,
            <div
              key={3}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Estado
            </div>,
            <div key={4} style={{ alignItems: "center" }}>
              <RadioButton
                label="Creado"
                checked={currentFilteredRequest.status === "Created"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Created") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Created",
                    })
                  );
                }}
              />
              <RadioButton
                label="Firmado"
                checked={currentFilteredRequest.status === "Signed"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Signed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Signed",
                    })
                  );
                }}
              />
              <RadioButton
                label="Diligenciado"
                checked={currentFilteredRequest.status === "Filled"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Filled") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Filled",
                    })
                  );
                }}
              />
              <RadioButton
                label="Endosado"
                checked={currentFilteredRequest.status === "Endosado"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Endosado") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Endosado",
                    })
                  );
                }}
              />
            </div>,
          ]}
        />
      );
    } else if (activeComponent === "Analytics") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          calendarType={"double"}
          endDate={
            currentAnalyticsRequest.endDate
              ? new Date(currentAnalyticsRequest.endDate)
              : null
          }
          startDate={
            currentAnalyticsRequest.startDate
              ? new Date(currentAnalyticsRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changePagareFilters({
                    ...currentAnalyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changePagareFilters({
                    ...currentAnalyticsRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          items={[
            <div
              key={1}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Tipo de Pagaré
            </div>,
            <div key={2} style={{ alignItems: "center" }}>
              <RadioButton
                label="Blanco con carta"
                checked={currentAnalyticsRequest.type === "Blank"}
                onClick={() => {
                  if (currentAnalyticsRequest.type === "Blank") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        type: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      type: "Blank",
                    })
                  );
                }}
              />
              <RadioButton
                label="Cerrado"
                checked={currentAnalyticsRequest.type === "Closed"}
                onClick={() => {
                  if (currentAnalyticsRequest.type === "Closed") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        type: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      type: "FilledOut",
                    })
                  );
                }}
              />
            </div>,
            <div
              key={3}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Estado
            </div>,
            <div key={4} style={{ alignItems: "center" }}>
              <RadioButton
                label="Creado"
                checked={currentAnalyticsRequest.status === "Created"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Created") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Created",
                    })
                  );
                }}
              />
              <RadioButton
                label="Firmado"
                checked={currentAnalyticsRequest.status === "Signed"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Signed") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Signed",
                    })
                  );
                }}
              />
              <RadioButton
                label="Diligenciado"
                checked={currentAnalyticsRequest.status === "Filled"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Filled") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Filled",
                    })
                  );
                }}
              />
              <RadioButton
                label="Endosado"
                checked={currentAnalyticsRequest.status === "Endosado"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Endosado") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Endosado",
                    })
                  );
                }}
              />
            </div>,
          ]}
        />
      );
    } else {
      return;
    }
  };

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const getCategoryItems = (): JSX.Element[] => {
    let items: JSX.Element[] = [];
    items.push(
      <Category
        key={0}
        name="Pagarés creados"
        last
        selected={activeComponent === "Pagares"}
        onClick={(e) => {
          e.preventDefault();
          navigate("/services/pagares/list");
        }}
      />
    );
    if (
      roles.includes("analytics.admin") ||
      roles.includes("analytics.pagares.view")
    ) {
      items.push(
        <Category
          key={1}
          name="Analítica"
          last
          selected={activeComponent === "Analytics"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Pagarés"));
            dispatch(changeActiveComponent("Analytics"));
            dispatch(resetStateAnalytics());
            navigate("/services/pagares/analytics");
          }}
        />
      );
    }

    return items;
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Pagarés"));
    dispatch(changeActiveComponent("Pagares"));
  }, []);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
