import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct } from "../../common/commonSlice";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import { ReactComponent as Score } from "../../../Assets/common/images/score.svg";
import LoadingOverlay from "react-loading-overlay-ts";

import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
import {
  changeCurrentFilters,
  changeCurrentFlowStatus,
  changeStatusById,
  getFilterOptions,
  selectCurrentFilterRequest,
  selectCurrentFlow,
  selectCurrentFlowStatus,
  selectFilteredOptions,
  selectHyperFlowActiveComponent,
  selectIsFlowDetail,
  selectLoadingHyperflow,
  selectRpaStatus,
  selectRpaStatusLoading,
  selectShowRpaStatus,
  sendContinueEmail,
  switchRpaStatus,
} from "./hyperFlowSlice";
import { StatusChangeSidePanel } from "../components/sidePanel/StatusChangeSidePanel";
import { StatusChangeRadio } from "../components/sidePanel/StatusChangeOptions/StatusChangeRadio";
import {
  getServiceName,
  getServicePath,
  getServicePathData,
  getStatusFlow,
} from "../../../helpers/utils";
import { CategoriesServicesComponent } from "../components/sidePanel/categories/CategoriesServicesComponent";
import { Badge } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ClipLoader } from "react-spinners";

export const useStyles = makeStyles(() =>
  createStyles({
    customBadge: {
      backgroundColor: "transparent",
      color: "white",
      fontSize: "30px",
    },
  })
);
export const HyperFlowDetail = () => {
  let filterOptions = useAppSelector(selectFilteredOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let isFlowDetail = useAppSelector(selectIsFlowDetail);
  let loadingStatus = useAppSelector(selectLoadingHyperflow);
  let currentFlow = useAppSelector(selectCurrentFlow);
  let activeComponent = useAppSelector(selectHyperFlowActiveComponent);
  let showRpaStatus = useAppSelector(selectShowRpaStatus);
  let rpaStatusLoading = useAppSelector(selectRpaStatusLoading);
  let rpaStatus = useAppSelector(selectRpaStatus);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const classes = useStyles();

  let getCurrentStatus = (
    statusConst: "APPROVED" | "PENDING" | "IN_PROGRESS" | "REJECTED" | string
  ) => {
    switch (statusConst) {
      case "APPROVED":
        return "Aprobado";
      case "IN_PROGRESS":
        return "En Progreso";
      case "PENDING":
        return "PENDIENTE";
      case "REJECTED":
        return "Rechazado";
      default:
        return "En Progreso";
    }
  };

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let searchInput = (
      <SearchInputFilter
        value={currentFilteredRequest.searchQuery}
        key={"SearchRegistrations"}
        onChange={(e) => {
          if (!e.target.value || e.target.value === "") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: null,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: e.target.value,
              })
            );
          }
        }}
        style={{ marginTop: "5px", width: "80%", alignSelf: "center" }}
        placeHolder="No. de documento"
      />
    );
    return (
      <>
        <FilterComponent
          key={0}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                endDate: null,
                startDate: null,
              })
            );
          }}
          items={[searchInput]}
        />
      </>
    );
  };

  function getRpaStatusName(status: "PENDING" | "ERROR" | "COMPLETE"): string {
    console.log("Status", status);
    switch (status) {
      case "PENDING":
        return "Pendiente";
      case "ERROR":
        return "Error";
      case "COMPLETE":
        return "Completo";
      default:
        return "Pendiente";
    }
  }

  useEffect(() => {
    if (!filterOptions) {
      dispatch(getFilterOptions());
    }
  }, [filterOptions]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={
            <LoadingOverlay
              active={loadingStatus === "pending"}
              spinner={<ClipLoader color="white" size="150px" />}
            >
              {currentFlow &&
                currentFlow?.registration &&
                loadingStatus !== "pending" && (
                  <div>
                    <div
                      className="text-small primary-color text-medium text-bold"
                      style={{ textAlign: "center" }}
                    >
                      {" "}
                      Score TOTAL
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        alignContent: "center",
                        alignSelf: "center",
                        alignItems: "center",
                        width: "100%",
                        padding: "20px",
                      }}
                    >
                      {currentFlow &&
                        currentFlow?.registration &&
                        currentFlow?.registration?.multiFactorMatrix && (
                          <Badge
                            badgeContent={`${currentFlow?.registration?.multiFactorMatrix?.finalScore.toFixed(
                              1
                            )}%`}
                            classes={{ badge: classes.customBadge }}
                            showZero
                          >
                            <Score />
                          </Badge>
                        )}
                    </div>
                    {
                      <div
                        className="text-small primary-color"
                        style={{ textAlign: "center" }}
                      >{`Estado de verificación: ${
                        currentFlow && currentFlow?.flowStatus
                          ? getStatusFlow(
                              currentFlow?.flowStatus,
                              currentFlow.acceptanceStatus
                            )
                          : ""
                      }`}</div>
                    }
                    <div
                      className="text-small primary-color text-medium text-bold"
                      style={{ textAlign: "center" }}
                    >
                      {" "}
                      Info Cliente
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "50px" }}>
                      <button
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(0,0,0,0.2)",
                          border: "none",
                          borderRadius: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "4px 10px",
                          fontSize: "14px",
                          fontWeight: 700,
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          dispatch(
                            sendContinueEmail({
                              execFlowId: currentFlow?.id ?? "",
                            })
                          ).then(() => {
                            alert("Correo enviado");
                          });
                        }}
                      >
                        Enviar recordatorio por correo
                      </button>
                    </div>
                    {/* Rpa Status */}
                    {showRpaStatus === true && (
                      <div>
                        {rpaStatusLoading === "pending" && (
                          <div
                            style={{
                              textAlign: "center",
                              alignContent: "center",
                              alignSelf: "center",
                              alignItems: "center",
                              width: "100%",
                              padding: "20px",
                            }}
                          >
                            <ClipLoader color="white" size="150px" />
                          </div>
                        )}
                        {rpaStatusLoading === "resolved" && (
                          <div
                            style={{
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.2)",
                              marginTop: "20px",
                              paddingTop: "20px",
                            }}
                          >
                            <div
                              className="text-small primary-color text-medium text-bold"
                              style={{ textAlign: "center" }}
                            >
                              {" "}
                              Estado RPA: {getRpaStatusName(rpaStatus)}
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                alignContent: "center",
                                alignSelf: "center",
                                alignItems: "center",
                                width: "100%",
                                padding: "20px",
                              }}
                            >
                              <button
                                style={{
                                  color: "#fff",
                                  backgroundColor: "rgba(0,0,0,0.2)",
                                  border: "none",
                                  borderRadius: "8px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "4px 10px",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  marginBottom: "10px",
                                }}
                                onClick={() => {
                                  dispatch(switchRpaStatus()).then(() => {
                                    // alert("Estado cambiado");
                                  });
                                }}
                              >
                                {rpaStatus === "PENDING"
                                  ? "Marcar como Completo"
                                  : "Marcar como Pendiente"}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
            </LoadingOverlay>
          }
          categories={
            <CategoriesComponent
              categories={[
                <Category
                  key={0}
                  name="HyperFlows"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(changeCurrentProduct("HyperFlow"));
                    navigate("/services/hyperFlow/flows");
                  }}
                  selected={activeComponent === "HyperFlows"}
                />,
              ]}
            />
          }
        />
        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "start" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
