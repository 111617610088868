import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./introStyles.scss";
import "./introdStyles.scss";

import {
  changeActiveComponent,
  selectActiveCoreIdComponent,
} from "../services/coreid/coreIdSlice";
import { emptyTokenString, selectTokenString } from "../login/loginSlice";
import {
  changeCurrentProduct,
  getClientAndUserData,
  selectActiveServices,
  selectClientName,
  selectInactiveServices,
  selectLoading,
  Service,
} from "../common/commonSlice";
import { getServiceName, getServicePath } from "../../helpers/utils";
import { ListService } from "./components/ListService";
import { TopPanel } from "../services/components/TopPanel";
import { SidePanel } from "../services/components/sidePanel/SidePanel";
import { CategoriesComponent } from "../services/components/sidePanel/categories/CategoriesComponent";
import { Category } from "../services/components/sidePanel/categories/Category";
import { CategoriesServicesComponent } from "../services/components/sidePanel/categories/CategoriesServicesComponent";

export const IntroSearch = () => {
  //Selectors
  let tokenString = useAppSelector(selectTokenString);
  let loading = useAppSelector(selectLoading);
  let activeServices = useAppSelector(selectActiveServices);
  let inactiveServices = useAppSelector(selectInactiveServices);
  let clientName = useAppSelector(selectClientName);
  const [selectedService, setSelectedService] = useState<Service>("");
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);

  // Dispatcher
  let dispatch = useAppDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    if (tokenString && tokenString !== "") {
      dispatch(emptyTokenString());
    }
    if (activeServices.length === 0 && inactiveServices.length === 0) {
      dispatch(getClientAndUserData());
    }
    if (activeComponent === "none") {
      dispatch(changeCurrentProduct(""));
    }
  });

  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />

      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "90%" }}
      >
        <SidePanel
          categories={
            <CategoriesServicesComponent
              categories={activeServices.map((service, index) => {
                return (
                  <Category
                    key={index}
                    name={getServiceName(service)}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(getServicePath(service));
                    }}
                    selected={selectedService === service}
                  />
                );
              })}
            />
          }
        />

        <div
          className="flex-col"
          style={{ justifyContent: "flex-end", width: "80%" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
