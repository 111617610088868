import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";

import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
// import {
//   changeCurrentFilters,
//   changeCurrentFlowStatus,
//   changeHyperflowActiveComponent,
//   getFilterOptions,
//   selectCurrentFilterRequest,
//   selectFilteredOptions,
//   selectHyperFlowActiveComponent,
// } from "./hyperFlowSlice";

import { DropDown } from "../../common/components/Btns/DropDown";
import {
  changeCurrentFiltersATK,
  selectConfigs,
  selectCurrentFilterRequestATK,
} from "./AuthentikatorSlice";

export const AuthentikatorSideBar = () => {
  //   let filterOptions = useAppSelector(selectFilteredOptions);
  //   let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  //   let hyperflowActiveComponent = useAppSelector(selectHyperFlowActiveComponent);
  //   let analyticsRequest = useAppSelector(selectHyperflowRequest);
  //   let roles = useAppSelector(selectRoles);
  //   let [search, setSearch] = useState(false);
  //   let dispatch = useAppDispatch();
  //   let navigate = useNavigate();
  let dispatch = useAppDispatch();
  //Selectors
  let configs = useAppSelector(selectConfigs);
  let currentFilters = useAppSelector(selectCurrentFilterRequestATK);

  //   let currentServiceTags = useAppSelector(selectCurrentTagsByService);

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let projectOptions: { value: string; label: string }[] = [];
    let statusOptions: { value: string; label: string }[] = [
      { label: "En proceso", value: "IN_PROGRESS" },
      { label: "En revisión", value: "AWAITING_MANUAL_INTERACTION" },
      { label: "En espera a usuario", value: "AWAITING_USER_INTERACTION" },
      { label: "Desistido", value: "ABANDONED" },
      { label: "Completado", value: "DONE" },
    ];
    let projects = configs;
    if (projects) {
      for (const project in projects) {
        let label = projects[project].name;
        let value = projects[project].id;
        projectOptions.push({ label, value });
      }
    }
    let projectDropdown = (
      <DropDown
        key={"Projects"}
        title='Proyecto'
        options={projectOptions}
        onChange={(e) => {
          if (e) {
            dispatch(
              changeCurrentFiltersATK({
                ...currentFilters,
                processConfigId: e.value,
              })
            );
          }
        }}
      />
    );
    // let statusDropdown = (
    //   <DropDown
    //     key={"Status"}
    //     title='Estado'
    //     options={statusOptions}
    //     onChange={(e) => {}}
    //   />
    // );
    let searchInput = (
      <SearchInputFilter
        value={currentFilters.searchQuery ?? ""}
        key={"SearchAuthentikator"}
        onChange={(e) => {
          if (e.target.value) {
            dispatch(
              changeCurrentFiltersATK({
                ...currentFilters,
                searchQuery: e.target.value,
              })
            );
          } else {
            dispatch(
              changeCurrentFiltersATK({
                ...currentFilters,
                searchQuery: undefined,
              })
            );
          }
        }}
        style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
        placeHolder='Buscar'
      />
    );
    return (
      <FilterComponent
        key={0}
        hasCalendar={true}
        endDate={
          currentFilters.endDate ? new Date(currentFilters.endDate) : null
        }
        startDate={
          currentFilters.startDate ? new Date(currentFilters.startDate) : null
        }
        setEndDate={(date: Date) => {
          if (date) {
            dispatch(
              changeCurrentFiltersATK({
                ...currentFilters,
                endDate: date.toISOString(),
              })
            );
          }
        }}
        setStartDate={(date: Date) => {
          if (date) {
            dispatch(
              changeCurrentFiltersATK({
                ...currentFilters,
                startDate: date.toISOString(),
              })
            );
          }
        }}
        resetFunction={() => {
          dispatch(
            changeCurrentFiltersATK({
              ...currentFilters,
              endDate: undefined,
              startDate: undefined,
            })
          );
        }}
        items={[
          //   statusDropdown,
          projectDropdown,
          <div
            key={0}
            className='text-normal text-small primary-color'
            style={{
              alignSelf: "start",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            Por identificación, id personalizado o correo
          </div>,
          searchInput,
        ]}
      />
    );
  };

  //   useEffect(() => {
  //     if (!search) {
  //       dispatch(getFilterOptions());
  //       setSearch(true);
  //     }
  //   }, [filterOptions, setSearch, hyperflowActiveComponent]);
  return (
    <div
      className='flex-col'
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className='flex-row'
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel filter={getFilterItemsByActiveComponent()} />

        <div
          className='flex-col'
          style={{ width: "80%", justifyContent: "space-between" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
