import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { persistor, store } from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import { CookiesProvider } from "react-cookie";
import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from "react-loading-overlay-ts";

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate
          loading={
            <LoadingOverlay
              active={true}
              spinner={<ClipLoader color="white" size="150px" />}
            />
          }
          persistor={persistor}
        >
          <App />
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
