export const FirmaHelp = () => {
  return (
    <div
      style={{
        overflow: "auto",
        color: "white",
        padding: "2% 2%",
        width: "100%",
        height: "100%",
      }}
    >
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "52px",
        }}
      >
        Introducción
      </h2>
      <p>
        El servicio Firma electrónica de anteia es una herramienta que permite
        realizar firmas y hacer seguimiento sobre ellas en esta plataforma.
      </p>
      <p>
        El siguiente manual le dará una descripción de cada una de las secciones
        del servicio dentro del backoffice. Esto le ayudará a entender como
        realizar la navegación, interpretar los datos de los firmantes y
        documentos, y descargar y visualizar documentos firmados y certificados.
      </p>

      <object
        data="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20FIRMA%20ELECTR%C3%93NICA.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Descarga alternativa:{" "}
          <a href="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20FIRMA%20ELECTR%C3%93NICA.pdf">
            Si no se ve puede dar click aquí para descargar el PDF
          </a>
        </p>
      </object>

      <h2
        style={{
          fontWeight: "bold",
          fontSize: "36px",
        }}
      >
        Documentación de API
      </h2>
      <p>
        Además de manejo de la plataforma también ofrecemos documentación del
        API de firma electrónica, la cual puede consultar en el siguiente
        enlace:
      </p>
      <a
        href="https://anteia.stoplight.io/docs/clients-api/gskbba2ja01fa-firma-electronica-api"
        target="blank
      "
      >
        Documentación API
      </a>
    </div>
  );
};
