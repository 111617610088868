import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import localforage from "localforage";
import { RootState } from "../../app/store";
import { LoadingStatus } from "../common/commonSlice";
import {
  ChangeInfoRequest,
  changeOwnInfoAPI,
  changeOwnRolesAPI,
  changeUserInfoAPI,
  changeUserRolesAPI,
  createUserAPI,
  CreateUserRequest,
  deleteUserAPI,
  FilteredEventsResponse,
  FilteredUsersRequest,
  FilteredUsersResponse,
  getAllUsersAPI,
  getCurrentRolesAPI,
  getFilteredEventsAPI,
  GetFilteredEventsRequest,
  getFilteredUsersAPI,
  getOtherUserDataAPI,
  getRoleOptionsAPI,
  getUserDataAPI,
  PasswordData,
  PasswordDataError,
  PredeterminedRoles,
  resetOwnPasswordAPI,
  restorePasswordAPI,
  toggleUserStatusAPI,
  UserData,
  UserDataErrorMessage,
} from "./configurationAPI";

export type ConfigurationActiveComponent =
  | "Account"
  | "Users"
  | "ActionHistory"
  | "User"
  | "Clients"
  | "Client"
  | "Help"
  | "None";
export type HelpActiveComponent =
  | "None"
  | "Documentacion Hyperflow"
  | "Documentacion CoreId"
  | "Documentacion MagicForms"
  | "Documentacion General"
  | "Documentacion Pagares"
  | "Documentacion Firma"
  | "Documentacion Configuracion";

export interface ConfigurationState {
  activeComponent: ConfigurationActiveComponent;
  helpActiveComponent: HelpActiveComponent;
  accountLoadingStatus: LoadingStatus;
  passwordChangeStatus: LoadingStatus;
  currentRolesLoading: LoadingStatus;
  roleOptionsLoading: LoadingStatus;
  createUserLoading: LoadingStatus;
  usersLoading: LoadingStatus;
  otherUserLoading: LoadingStatus;
  restorePasswordLoading: LoadingStatus;
  resetOwnPasswordLoading: LoadingStatus;
  changeOwnInfoLoading: LoadingStatus;
  changeUserInfoLoading: LoadingStatus;
  deleteUserLoading: LoadingStatus;
  changeRolesLoading: LoadingStatus;
  eventsLoading: LoadingStatus;
  currentUserData: UserData | null;
  passwordData: PasswordData;
  userDataError: UserDataErrorMessage;
  otherUserDataError: UserDataErrorMessage;
  passwordDataError: PasswordDataError;
  roleOptions: PredeterminedRoles[];
  currentRoles: string[];
  filteredRequest: FilteredUsersRequest;
  filteredEventRequest: GetFilteredEventsRequest;
  currentPage: number;
  currentNumPages: number;
  users: FilteredUsersResponse | null;
  allUsers: UserData[];
  events: FilteredEventsResponse | null;
  otherUser: UserData | null;
  generatedPassword: string | null;
  eventCurrentPage: number;
  eventCurrentNumPages: number;
}

const initialState: ConfigurationState = {
  filteredEventRequest: {
    completed: null,
    page: 0,
    finishedDate: null,
    entityId: null,
    service: null,
    startedDate: null,
    type: null,
    userId: null,
    pageSize: 10
  },
  activeComponent: "Account",
  helpActiveComponent: "None",
  accountLoadingStatus: "idle",
  passwordChangeStatus: "idle",
  roleOptionsLoading: "idle",
  currentRolesLoading: "idle",
  changeOwnInfoLoading: "idle",
  createUserLoading: "idle",
  restorePasswordLoading: "idle",
  changeUserInfoLoading: "idle",
  usersLoading: "idle",
  resetOwnPasswordLoading: "idle",
  deleteUserLoading: "idle",
  otherUser: null,
  otherUserLoading: "idle",
  changeRolesLoading: "idle",
  eventsLoading: "idle",
  currentUserData: null,
  passwordData: {
    newPassword: "",
    newPasswordRepeat: "",
    oldPassword: "",
  },
  userDataError: {
    emailError: "",
    identificationError: "",
    nameError: "",
    phoneNumberError: "",
  },
  otherUserDataError: {
    emailError: "",
    identificationError: "",
    nameError: "",
    phoneNumberError: "",
  },
  passwordDataError: {
    newPasswordError: "",
    newPasswordRepeatError: "",
    oldPasswordError: "",
  },
  roleOptions: [],
  currentRoles: [],
  currentNumPages: 0,
  currentPage: 1,
  eventCurrentPage: 1,
  eventCurrentNumPages: 0,
  filteredRequest: {
    page: 0,
    role: null,
    searchQuery: null,
  },
  users: null,
  events: null,
  generatedPassword: null,
  allUsers: [],
};

export const getAccountData = createAsyncThunk(
  "configuration/getUserData",
  async () => {
    const userData = await getUserDataAPI();
    return userData;
  }
);

export const restorePassword = createAsyncThunk(
  "configuration/restorePassword",
  async (id: string) => {
    const passwordData = await restorePasswordAPI(id);
    return passwordData;
  }
);

export const changeUserRoles = createAsyncThunk(
  "configuration/changeUserRoles",
  async (id: string, { getState }) => {
    const state = getState() as RootState;
    const passwordData = await changeUserRolesAPI({
      roles: state.configuration.currentRoles,
      userId: id,
    });
    return passwordData;
  }
);

export const changeOwnRoles = createAsyncThunk(
  "configuration/changeOwnRoles",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const passwordData = await changeOwnRolesAPI({
      roles: state.configuration.currentRoles,
      userId: "",
    });
    if (passwordData.result?.generatedToken) {
      await localforage.setItem("token", passwordData.result.generatedToken);
    }
    return passwordData;
  }
);

export const resetOwnPassword = createAsyncThunk(
  "configuration/resetOwnPassword",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let oldPassword = state.configuration.passwordData.oldPassword;
    let newPassword = state.configuration.passwordData.newPassword;
    const passwordData = await resetOwnPasswordAPI({
      newPassword,
      oldPassword,
    });
    return passwordData;
  }
);

export const getOtherUser = createAsyncThunk(
  "configuration/getOtherUser",
  async (id: string) => {
    const userData = await getOtherUserDataAPI(id);
    return userData;
  }
);

export const getRoleOptions = createAsyncThunk(
  "configuration/getRoleOptions",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const activeServices = state.common.activeServices;
    const isMetaClient = state.common.isMetaClient;
    const activeServicesRoles = activeServices.map((x) => {
      switch (x) {
        case "coreId":
          return "coreid";
        case "magicForms":
          return "magicforms";
        case "firmaNew":
          return "firma";
        case "hyperFlow":
          return "hyperflow";
        case "pagares":
          return "pagare";
        case "smartFlow":
          return "smartflow";
        default:
          return undefined;
      }
    });
    const roleOptions = await getRoleOptionsAPI();
    let roleOptionsResult: PredeterminedRoles[] = [];
    if (roleOptions && roleOptions.length > 0) {
      for (let activeService of activeServicesRoles) {
        if (activeService && activeService !== "smartflow") {
          let role = roleOptions.find((x) =>
            x.adminRole.role.includes(activeService!.toString())
          );

          if (role) {
            roleOptionsResult.push(role);
          }
        }
      }

      let analyticsRoles = roleOptions.find((x) =>
        x.adminRole.role.includes("analytics.admin")
      );
      if (analyticsRoles) {
        let filteredAnalyticsRole: PredeterminedRoles = {
          adminRole: analyticsRoles.adminRole,
          containedRoles: [],
        };
        for (let activeService of activeServicesRoles) {
          let role = analyticsRoles.containedRoles.find((x) =>
            x.role.includes(activeService!.toString())
          );
          if (role) {
            filteredAnalyticsRole.containedRoles.push(role);
          }
        }
        roleOptionsResult.push(filteredAnalyticsRole);
      }

      let usersRole = roleOptions.find((x) =>
        x.adminRole.role.includes("users.admin")
      );
      if (usersRole) {
        roleOptionsResult.push(usersRole);
      }

      if (isMetaClient) {
        let clientsRole = roleOptions.find((x) =>
          x.adminRole.role.includes("clients.admin")
        );
        if (clientsRole) {
          roleOptionsResult.push(clientsRole);
        }
      }
      let tagsRole = roleOptions.find((x) =>
        x.adminRole.role.includes("tags.admin")
      );
      if (tagsRole) {
        roleOptionsResult.push(tagsRole);
      }

      let listsRole = roleOptions.find((x) =>
        x.adminRole.role.includes("lists.admin")
      );
      if (listsRole) {
        roleOptionsResult.push(listsRole);
      }
    }

    return roleOptionsResult;
  }
);

export const getCurrentRoles = createAsyncThunk(
  "configuration/getCurrentRoles",
  async () => {
    const roles = await getCurrentRolesAPI();
    return roles;
  }
);

export const createUser = createAsyncThunk(
  "configuration/createUser",
  async (userData: UserData, { getState }) => {
    const state = getState() as RootState;
    let req: CreateUserRequest = {
      email: userData.email,
      identification: userData.identification,
      name: userData.name,
      password: "",
      phoneNumber: userData.phoneNumber,
      roles: state.configuration.currentRoles,
      userId: "",
      userName: userData.email,
    };
    const result = await createUserAPI(req);
    return result;
  }
);

export const changeOwnInfo = createAsyncThunk(
  "configuration/changeOwnInfo",
  async (_, { getState }) => {
    const state = getState() as RootState;
    let req: ChangeInfoRequest = {
      email: state.configuration.currentUserData?.email!,
      identification: state.configuration.currentUserData?.identification!,
      name: state.configuration.currentUserData?.name!,
      phoneNumber: state.configuration.currentUserData?.phoneNumber!,
    };
    const userData = await changeOwnInfoAPI(req);
    return userData;
  }
);

export const changeUserInfo = createAsyncThunk(
  "configuration/changeUserInfo",
  async (id: string, { getState }) => {
    const state = getState() as RootState;
    let req: ChangeInfoRequest = {
      email: state.configuration.otherUser?.email!,
      identification: state.configuration.otherUser?.identification!,
      name: state.configuration.otherUser?.name!,
      phoneNumber: state.configuration.otherUser?.phoneNumber!,
    };
    const userData = await changeUserInfoAPI(id, req);
    return userData;
  }
);

export const toggleUserStatus = createAsyncThunk(
  "configuration/toggleUserStatus",
  async (id: string) => {
    const userData = await toggleUserStatusAPI(id);
    return userData;
  }
);

export const deleteUser = createAsyncThunk(
  "configuration/deleteUser",
  async (id: string) => {
    const result = await deleteUserAPI(id);
    return result;
  }
);

export const getFilteredUsers = createAsyncThunk(
  "configuration/getFilteredUsers",
  async (params: FilteredUsersRequest, { rejectWithValue }) => {
    try {
      const filteredUsers = await getFilteredUsersAPI(params);
      if (filteredUsers.filteredUsers) {
        return filteredUsers;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFilteredEvents = createAsyncThunk(
  "configuration/getFilteredEvents",
  async (params: GetFilteredEventsRequest, { rejectWithValue }) => {
    try {
      const filteredEvents = await getFilteredEventsAPI(params);
      if (filteredEvents.filteredEvents) {
        return filteredEvents;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "configuration/getAllUsers",
  async () => {
    const users = await getAllUsersAPI();
    return users;
  }
);

export const configurationSlice = createSlice({
  name: "configurationSlice",
  initialState,
  reducers: {
    changeConfigurationActiveComponent: (
      state,
      action: PayloadAction<ConfigurationActiveComponent>
    ) => {
      state.activeComponent = action.payload;
    },
    changeHelpActiveComponent: (
      state,
      action: PayloadAction<HelpActiveComponent>
    ) => {
      state.helpActiveComponent = action.payload;
    },
    cleanCurrentRoles: (state) => {
      state.currentRoles = [];
      state.currentRolesLoading = "idle";
    },
    addRolesToCurrent: (state, action: PayloadAction<string[]>) => {
      let currentRolesTemp: string[] = [];
      action.payload.forEach((role) => {
        if (!state.currentRoles.includes(role)) {
          currentRolesTemp.push(role);
        }
      });

      state.currentRoles = [...state.currentRoles, ...currentRolesTemp];
    },
    removeRolesFromCurrent: (state, action: PayloadAction<string[]>) => {
      let currentRolesfiltered: string[] = [...state.currentRoles];
      action.payload.forEach((d) => {
        currentRolesfiltered = currentRolesfiltered.filter((r) => r !== d);
      });

      state.currentRoles = [...currentRolesfiltered];
    },
    setCurrentRoles: (state, action: PayloadAction<string[]>) => {
      state.currentRoles = action.payload;
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.filteredRequest;
      state.filteredRequest = { ...current, page: action.payload };
    },
    changeEventPageState: (state, action: PayloadAction<number>) => {
      let current = state.filteredEventRequest;
      state.filteredEventRequest = { ...current, page: action.payload };
    },
    changeEventPageSize: (state, action: PayloadAction<number>) => {
      let current = state.filteredEventRequest;
      state.filteredEventRequest = { ...current, pageSize: action.payload };
    },
    changeActiveComponent: (
      state,
      action: PayloadAction<ConfigurationActiveComponent>
    ) => {
      state.activeComponent = action.payload;
      state.currentPage = 1;
      state.currentNumPages = 0;
    },
    changeCurrentFilters: (
      state,
      action: PayloadAction<FilteredUsersRequest>
    ) => {
      state.filteredRequest = { ...action.payload, page: 0 };
    },
    changeCurrentEventFilters: (
      state,
      action: PayloadAction<GetFilteredEventsRequest>
    ) => {
      state.filteredEventRequest = { ...action.payload, page: 0 };
    },
    emptyGeneratedPassword: (state) => {
      state.generatedPassword = null;
      state.restorePasswordLoading = "idle";
      state.createUserLoading = "idle";
    },
    changePasswordData: (state, action: PayloadAction<PasswordData>) => {
      state.passwordData = action.payload;
    },
    changePasswordErrorData: (
      state,
      action: PayloadAction<PasswordDataError>
    ) => {
      state.passwordDataError = action.payload;
    },
    changeLoadingResetPassword: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => {
      state.resetOwnPasswordLoading = action.payload;
    },
    changeLoadingEditOwnInfo: (state, action: PayloadAction<LoadingStatus>) => {
      state.changeOwnInfoLoading = action.payload;
    },
    changeLoadingEditUserInfo: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => {
      state.changeUserInfoLoading = action.payload;
    },
    changeCurrentData: (state, action: PayloadAction<UserData | null>) => {
      state.currentUserData = action.payload;
    },
    changeOtherUserdata: (state, action: PayloadAction<UserData | null>) => {
      state.otherUser = action.payload;
    },
    changeCurrentDataError: (
      state,
      action: PayloadAction<UserDataErrorMessage>
    ) => {
      state.userDataError = action.payload;
    },
    changeOtheruserDataError: (
      state,
      action: PayloadAction<UserDataErrorMessage>
    ) => {
      state.otherUserDataError = action.payload;
    },
    changeAccountLoading: (state, action: PayloadAction<LoadingStatus>) => {
      state.accountLoadingStatus = action.payload;
    },
    changeOtherUserLoading: (state, action: PayloadAction<LoadingStatus>) => {
      state.otherUserLoading = action.payload;
    },
    changechangeRolesLoading: (state, action: PayloadAction<LoadingStatus>) => {
      state.changeRolesLoading = action.payload;
    },
    resetOtherUserData: (state) => {
      state.otherUser = null;
      state.otherUserLoading = "idle";
      state.currentRoles = [];
      state.generatedPassword = "";
      state.restorePasswordLoading = "idle";
      state.changeUserInfoLoading = "idle";
      state.deleteUserLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountData.pending, (state) => {
        state.accountLoadingStatus = "pending";
      })
      .addCase(getAccountData.fulfilled, (state, action) => {
        state.currentUserData = action.payload;
        if (action.payload) {
          state.accountLoadingStatus = "resolved";
        } else {
          state.accountLoadingStatus = "rejected";
        }
      })
      .addCase(getRoleOptions.pending, (state) => {
        state.roleOptionsLoading = "pending";
      })
      .addCase(getRoleOptions.fulfilled, (state, action) => {
        state.roleOptions = action.payload;
        if (action.payload.length > 0) {
          state.roleOptionsLoading = "resolved";
        } else {
          state.roleOptionsLoading = "rejected";
        }
      })
      .addCase(getCurrentRoles.pending, (state) => {
        state.currentRolesLoading = "pending";
      })
      .addCase(getCurrentRoles.fulfilled, (state, action) => {
        state.currentRoles = action.payload;
        if (action.payload.length > 0) {
          state.currentRolesLoading = "resolved";
        } else {
          state.currentRolesLoading = "rejected";
        }
      })
      .addCase(getFilteredUsers.fulfilled, (state, action) => {
        let users = action.payload;

        if (users.filteredUsers) {
          state.users = users.filteredUsers;
          state.currentPage = users.filteredUsers.currentPage;
          state.currentNumPages = users.filteredUsers.numPages;
          state.usersLoading = "resolved";
        } else {
          state.usersLoading = "rejected";
        }
      })
      .addCase(getFilteredUsers.rejected, (state) => {
        state.usersLoading = "rejected";
        state.currentPage = 0;
        state.currentNumPages = 0;
      })
      .addCase(getFilteredUsers.pending, (state) => {
        state.usersLoading = "pending";
      })
      .addCase(createUser.rejected, (state) => {
        state.generatedPassword = null;
        state.createUserLoading = "rejected";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.generatedPassword = action.payload.data.generatedPassword;
        }
        state.createUserLoading = "resolved";
      })
      .addCase(getOtherUser.pending, (state) => {
        state.otherUserLoading = "pending";
      })
      .addCase(getOtherUser.fulfilled, (state, action) => {
        state.otherUser = action.payload;
        if (action.payload) {
          state.otherUserLoading = "resolved";
          state.currentRoles = action.payload.roles;
        } else {
          state.otherUserLoading = "rejected";
        }
      })
      .addCase(restorePassword.pending, (state) => {
        state.restorePasswordLoading = "pending";
      })
      .addCase(restorePassword.fulfilled, (state, action) => {
        if (action.payload) {
          state.restorePasswordLoading = "resolved";
          state.generatedPassword = action.payload.generatedPassword;
        } else {
          state.restorePasswordLoading = "rejected";
        }
      })
      .addCase(resetOwnPassword.pending, (state) => {
        state.resetOwnPasswordLoading = "pending";
      })
      .addCase(resetOwnPassword.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.resetOwnPasswordLoading = "resolved";
        } else {
          state.resetOwnPasswordLoading = "rejected";
        }

        state.passwordDataError.oldPasswordError = action.payload.message;
      })
      .addCase(changeOwnInfo.pending, (state) => {
        state.changeOwnInfoLoading = "pending";
      })
      .addCase(changeOwnInfo.fulfilled, (state, action) => {
        if (action.payload.userData) {
          state.changeOwnInfoLoading = "resolved";
          state.currentUserData = action.payload.userData;
        } else {
          state.changeOwnInfoLoading = "rejected";
        }
      })
      .addCase(changeUserInfo.pending, (state) => {
        state.changeUserInfoLoading = "pending";
      })
      .addCase(changeUserInfo.fulfilled, (state, action) => {
        if (action.payload.userData) {
          state.changeUserInfoLoading = "resolved";
          state.currentUserData = action.payload.userData;
        } else {
          state.changeUserInfoLoading = "rejected";
        }
      })
      .addCase(toggleUserStatus.pending, (state) => {
        state.changeUserInfoLoading = "pending";
      })
      .addCase(toggleUserStatus.fulfilled, (state, action) => {
        if (action.payload.userData) {
          state.changeUserInfoLoading = "resolved";
          state.currentUserData = action.payload.userData;
        } else {
          state.changeUserInfoLoading = "rejected";
        }
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleteUserLoading = "pending";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.deleteUserLoading = "resolved";
        } else {
          state.deleteUserLoading = "rejected";
        }
      })
      .addCase(changeUserRoles.pending, (state) => {
        state.changeRolesLoading = "pending";
      })
      .addCase(changeUserRoles.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.changeRolesLoading = "resolved";
        } else {
          state.changeRolesLoading = "rejected";
        }
      })
      .addCase(changeOwnRoles.pending, (state) => {
        state.changeRolesLoading = "pending";
      })
      .addCase(changeOwnRoles.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.changeRolesLoading = "resolved";
          state.currentUserData = action.payload.result;
        } else {
          state.changeRolesLoading = "rejected";
        }
      });

    // Get filtered events
    builder
      .addCase(getFilteredEvents.pending, (state) => {
        state.eventsLoading = "pending";
      })
      .addCase(getFilteredEvents.fulfilled, (state, action) => {
        let events = action.payload;

        if (events.filteredEvents) {
          state.events = events.filteredEvents;
          state.eventCurrentPage = events.filteredEvents.currentPage;
          state.eventCurrentNumPages = events.filteredEvents.numPages;
          state.eventsLoading = "resolved";
        } else {
          state.eventsLoading = "rejected";
        }
      })
      .addCase(getFilteredEvents.rejected, (state) => {
        state.eventsLoading = "rejected";
        state.eventCurrentPage = 0;
        state.eventCurrentNumPages = 0;
      });

    // all users
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      let users = action.payload;

      if (users) {
        state.allUsers = users;
        state.usersLoading = "resolved";
      } else {
        state.usersLoading = "rejected";
      }
    });
  },
});

export const {
  changeConfigurationActiveComponent,
  cleanCurrentRoles,
  addRolesToCurrent,
  removeRolesFromCurrent,
  changePageState,
  changeActiveComponent,
  changeCurrentFilters,
  emptyGeneratedPassword,
  changePasswordData,
  changePasswordErrorData,
  changeLoadingResetPassword,
  changeCurrentData,
  changeCurrentDataError,
  changeLoadingEditOwnInfo,
  changeAccountLoading,
  changeOtherUserdata,
  changeLoadingEditUserInfo,
  changeOtherUserLoading,
  changeOtheruserDataError,
  resetOtherUserData,
  changechangeRolesLoading,
  changeHelpActiveComponent,
  setCurrentRoles,
  changeCurrentEventFilters,
  changeEventPageState,
  changeEventPageSize,
} = configurationSlice.actions;

export const selectActiveConfigurationComponent = (state: RootState) =>
  state.configuration.activeComponent;
export const selectLoadingAccount = (state: RootState) =>
  state.configuration.accountLoadingStatus;
export const selectAccountData = (state: RootState) =>
  state.configuration.currentUserData;
export const selectAccountDataError = (state: RootState) =>
  state.configuration.userDataError;
export const selectOtherUserDataError = (state: RootState) =>
  state.configuration.otherUserDataError;
export const selectPasswordChangeLoading = (state: RootState) =>
  state.configuration.passwordChangeStatus;
export const selectPasswordData = (state: RootState) =>
  state.configuration.passwordData;
export const selectPasswordDataError = (state: RootState) =>
  state.configuration.passwordDataError;
export const selectRoleOptions = (state: RootState) =>
  state.configuration.roleOptions;
export const selectRoleOptionsLoading = (state: RootState) =>
  state.configuration.roleOptionsLoading;
export const selectCurrentRoles = (state: RootState) =>
  state.configuration.currentRoles;
export const selectCurrentRolesLoading = (state: RootState) =>
  state.configuration.currentRolesLoading;
export const selectUsersLoading = (state: RootState) =>
  state.configuration.usersLoading;
export const selectUsers = (state: RootState) => state.configuration.users;
export const selectCurrentPageUsers = (state: RootState) =>
  state.configuration.currentPage;
export const selectCurrentNumPagesUsers = (state: RootState) =>
  state.configuration.currentNumPages;
export const selectCurrentFilterRequestUsers = (state: RootState) =>
  state.configuration.filteredRequest;
export const selectCreateUserLoading = (state: RootState) =>
  state.configuration.createUserLoading;
export const selectLoadingOtherUser = (state: RootState) =>
  state.configuration.otherUserLoading;
export const selectOtherUser = (state: RootState) =>
  state.configuration.otherUser;
export const selectGeneratedPassword = (state: RootState) =>
  state.configuration.generatedPassword;
export const selectRestorePasswordLoading = (state: RootState) =>
  state.configuration.restorePasswordLoading;
export const selectResetOwnPasswordLoading = (state: RootState) =>
  state.configuration.resetOwnPasswordLoading;
export const selectChangeOwnInfoLoading = (state: RootState) =>
  state.configuration.changeOwnInfoLoading;
export const selectChangeUserInfoLoading = (state: RootState) =>
  state.configuration.changeUserInfoLoading;
export const selectDeleteLoading = (state: RootState) =>
  state.configuration.deleteUserLoading;
export const selectChangeRolesLoading = (state: RootState) =>
  state.configuration.changeRolesLoading;
export const selectHelpActiveComponent = (state: RootState) =>
  state.configuration.helpActiveComponent;
export const selectCurrentEvents = (state: RootState) =>
  state.configuration.events;
export const selectCurrentEventFilters = (state: RootState) =>
  state.configuration.filteredEventRequest;
export const selectLoadingEvents = (state: RootState) =>
  state.configuration.eventsLoading;
export const selectEventsCurrentPage = (state: RootState) =>
  state.configuration.eventCurrentPage;
export const selectEventsCurrentNumPages = (state: RootState) =>
  state.configuration.eventCurrentNumPages;
export const selectEventsPageSize = (state: RootState) =>
  state.configuration.filteredEventRequest.pageSize;
export const selectAllUsers = (state: RootState) =>
  state.configuration.allUsers;

export default configurationSlice.reducer;
