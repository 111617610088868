import "./searchInputFilterStyles.scss";

export interface SearchFilterProps {
  placeHolder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  value: string | null;
}
export const SearchInputFilter = (props: SearchFilterProps) => {
  return (
    <div className="flex-col searchFilter" style={props.style}>
      <input
        value={props.value || undefined}
        onChange={props.onChange}
        className="text-normal text-small primary-color searchFilter"
        type="text"
        placeholder={props.placeHolder}
        style={{
          textAlign: "center",
          background: "rgba(255,255,255,0.1)",
          borderRadius: "8px",
          border: "none",
          height: "28px",
        }}
      />
    </div>
  );
};
