import axiosApiInstance from "../../../helpers/interceptor";

let getFiltered = "electronicSignature/getFiltered";
let getById = "electronicSignature/getById";
let getGeneratedDocumentsMethod =
  "electronicSignature/getGeneratedDocumentsById";
let getImagesMethod = "electronicSignature/getAuthenticationImageById";

let getSignaturesFilteredAPI = async (
  request: FirmaFilteredRequest
): Promise<{
  error: string;
  filteredFirmas: FirmasFiltered | null;
}> => {
  let FirmasFiltered = await axiosApiInstance.post(`${getFiltered}`, request);
  if (FirmasFiltered.status !== 200) {
    return { filteredFirmas: null, error: FirmasFiltered.data };
  } else {
    return {
      error: "",
      filteredFirmas: { ...FirmasFiltered.data, currentPage: request.page },
    };
  }
};

let getSignatureByIdAPI = async (
  id: string
): Promise<{ signature: SignatureDetail | null; error: string }> => {
  let signature = await axiosApiInstance.get(`${getById}/${id}`);

  if (signature.status !== 200) {
    return { error: "", signature: null };
  } else {
    return { signature: signature.data, error: "" };
  }
};

let getGeneratedDocumentsAPI = async (
  id: string
): Promise<{ documents: GeneratedDocuments | null; error: string }> => {
  let documents = await axiosApiInstance.get(
    `${getGeneratedDocumentsMethod}/${id}`
  );
  if (documents.status !== 200) {
    return { documents: null, error: "" };
  } else {
    return { documents: documents.data, error: "" };
  }
};

let getAuthenticationImageByIdAPI = async (
  id: string
): Promise<{ image: VerificationImage | null; error: string }> => {
  let image = await axiosApiInstance.get(`${getImagesMethod}/${id}`);
  if (image.status !== 200) {
    return { image: null, error: "" };
  } else {
    return { image: image.data, error: "" };
  }
};

export interface FirmasFiltered {
  signatures: Array<FirmaInfoFiltered>;
  numPages: number;
  currentPage: number;
}

export interface FirmaInfoFiltered {
  nombreApellido: string;
  fechaHora: string;
  email: string;
  status: string;
  alertas: number;
  signatureId: string;
  identification: string;
}

export interface FirmaFilteredRequest {
  endDate: string | null;
  startDate: string | null;
  page: number;
  status: string | null;
  verificationMethod: string | null;
}

export interface SignatureDetail {
  status: string;
  signers: Array<Signer>;
  id: string;
}

export interface Signer {
  name: string;
  email: string;
  lastName: string;
  registrationId: string;
  phoneNumber: string;
  validationMethod: string;
  status: string;
  date: string;
  alerts: number;
}

export interface GeneratedDocuments {
  certificate: File;
  document: File;
  finishedSignature: boolean;
}

export interface VerificationImage {
  validationImage: File;
}

export interface File {
  documentName: string;
  documentBase64: string;
}

export {
  getSignaturesFilteredAPI,
  getSignatureByIdAPI,
  getGeneratedDocumentsAPI,
  getAuthenticationImageByIdAPI,
};
