import ReactECharts from "echarts-for-react";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getAlertsCoreId,
  getAlertsHyperFlow,
  getAsesores,
  getAsesoresAnalyticsHyperflow,
  getMFMAData,
  getMFMAHyperflow,
  getStatusHyperflow,
  getStatusHyperflowAsesoresByDate,
  getStatusHyperflowByDate,
  selectAnalyticsAlerts,
  selectAsesores,
  selectAsesoresAnalytics,
  selectAsesoresByDate,
  selectHyperflowRequest,
  selectLoadingAnalyticsAlerts,
  selectLoadingAsesoresAnalytics,
  selectLoadingAsesoresByDate,
  selectLoadingMFMA,
  selectLoadingStatusByDate,
  selectLoadingStatusResult,
  selectMFMAData,
  selectStatusByDate,
  selectStatusResult,
} from "../../analytics/analyticsSlice";
import {
  changeHyperflowActiveComponent,
  selectHyperFlowActiveComponent,
} from "../hyperFlowSlice";
import {
  converMFMAElementToPieChart,
  convertAlertsToPieChart,
  convertAsesorToPieChart,
  convertStatusByDateToStackedBar,
  convertStatusByDateToStackedBarAsesores,
  convertStatusToPieChart,
} from "../../analytics/analyticsUtils";
import { changeCurrentProduct } from "../../../common/commonSlice";
import { MFMAResult } from "../../analytics/analyticsAPI";

export const AnalyticsHyperFlow = () => {
  let activeComponent = useAppSelector(selectHyperFlowActiveComponent);
  let loadingMFMA = useAppSelector(selectLoadingMFMA);
  let mfmaData = useAppSelector(selectMFMAData);
  let loadingCoreIdAlerts = useAppSelector(selectLoadingAnalyticsAlerts);
  let coreIdAlerts = useAppSelector(selectAnalyticsAlerts);
  let loadingStatusResult = useAppSelector(selectLoadingStatusResult);
  let statusResult = useAppSelector(selectStatusResult);
  let loadingStatusByDate = useAppSelector(selectLoadingStatusByDate);
  let statusByDate = useAppSelector(selectStatusByDate);
  let hyperflowAnalyticsRequest = useAppSelector(selectHyperflowRequest);
  let asesores = useAppSelector(selectAsesores);
  let asesoresAnalytics = useAppSelector(selectAsesoresAnalytics);
  let loadingAsesoresAnalytics = useAppSelector(selectLoadingAsesoresAnalytics);
  let loadingAsesoresByDate = useAppSelector(selectLoadingAsesoresByDate);
  let asesoresByDate = useAppSelector(selectAsesoresByDate);

  let dispatch = useAppDispatch();

  const [mfmaPercentage, setMfmaPercentage] = useState(false);
  const [alertsPercentage, setAlertsPercentage] = useState(false);
  const [statusPercentage, setStatusPercentage] = useState(false);
  const [asesoresPercentage, setAsesoresPercentage] = useState(false);

  const [selectedSection, setSelectedSection] = useState<
    "matriz" | "general" | "asesores"
  >("general");

  const mfmaHasActive = (mfmaData: MFMAResult) => {
    let objectEntries = Object.entries(mfmaData);
    for (let i = 0; i < objectEntries.length; i++) {
      if (objectEntries[i][1].active) {
        return true;
      }
    }
  };

  const getMFMAHalves = (half: "first" | "second") => {
    let objectEntries = Object.entries(mfmaData!);
    if (half === "first") {
      return objectEntries.slice(0, Math.ceil(objectEntries.length / 2));
    } else {
      return objectEntries.slice(
        Math.ceil(objectEntries.length / 2),
        objectEntries.length
      );
    }
  };

  const showContent = () => {
    switch (selectedSection) {
      case "general":
        return getGeneralContent();
      case "matriz":
        return getMFMAContent();
      case "asesores":
        return getAsesoresContent();
      default:
        return getGeneralContent();
    }
  };

  const getMFMAContent = () => {
    if (loadingMFMA === "pending") {
      return (
        <LoadingOverlay
          active={true}
          spinner={<ClipLoader color="white" size="100px" />}
        />
      );
    } else {
      return (
        <div
          className="flex-col"
          style={{
            width: "94%",
            height: "90%",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "8px",
            margin: "0 0 0 1%",
            padding: "1% 1% 1% 1%",
            background: "rgba(0,0,0,0.2)",
            justifySelf: "end",
          }}
        >
          <div
            className="flex-col"
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4
              style={{
                marginTop: "12px",
                color: "#fff",
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              Matriz de verificación
            </h4>
          </div>
          <div
            className="flex-row"
            style={{ width: "100%", justifyContent: "start" }}
          >
            <div
              className="flex-row"
              style={{ justifyContent: "start", marginLeft: "20px" }}
            >
              <button
                onClick={(e) => {
                  setMfmaPercentage(true);
                }}
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px 0 0 8px ",
                  background: mfmaPercentage
                    ? "rgba(0,0,0,1)"
                    : "rgba(13,60,80,1)",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  color: "#fff",
                }}
              >
                %
              </button>
              <button
                onClick={(e) => {
                  setMfmaPercentage(false);
                }}
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "0 8px 8px 0 ",
                  background: mfmaPercentage
                    ? "rgba(13,60,80,1)"
                    : "rgba(0,0,0,1)",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  color: "#fff",
                }}
              >
                #
              </button>
            </div>
          </div>
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "90%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="flex-row"
              style={{
                width: "100%",
                height: "40%",
                justifyContent: "space-evenly",
                alignItems: "center",
                margin: "0 0 3% 0",
              }}
            >
              {mfmaData && mfmaHasActive(mfmaData) ? (
                getMFMAHalves("first").map((v, i) => {
                  if (v[1].active) {
                    return (
                      <ReactECharts
                        key={i}
                        style={{ height: "100%", width: "15%" }}
                        option={converMFMAElementToPieChart(
                          v[1],
                          v[0],
                          mfmaPercentage
                        )}
                        showLoading={!mfmaData || loadingMFMA === "pending"}
                        loadingOption={{
                          text: "Cargando",
                          color: "#FFF",
                          textColor: "#FFF",
                          maskColor: "rgba(0, 0, 0, 0.6)",
                          zlevel: 0,
                        }}
                      />
                    );
                  }
                })
              ) : (
                <h4 style={{ color: "#fff" }}>
                  No hay información para los filtros seleccionados
                </h4>
              )}
            </div>
            <div
              className="flex-row"
              style={{
                width: "100%",
                height: "42%",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {mfmaData &&
                getMFMAHalves("second").map((v, i) => {
                  if (v[1].active) {
                    return (
                      <ReactECharts
                        key={i}
                        style={{ height: "100%", width: "15%" }}
                        option={converMFMAElementToPieChart(
                          v[1],
                          v[0],
                          mfmaPercentage
                        )}
                        showLoading={!mfmaData || loadingMFMA === "pending"}
                        loadingOption={{
                          text: "Cargando",
                          color: "#FFF",
                          textColor: "#FFF",
                          maskColor: "rgba(0, 0, 0, 0.6)",
                          zlevel: 0,
                        }}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
      );
    }
  };

  const getGeneralContent = () => {
    return (
      <div
        className="flex-col"
        style={{
          width: "94%",
          height: "90%",
          alignItems: "center",
          borderRadius: "8px",
          margin: "0 0 0 1%",
          padding: "1% 1% 1% 1%",
          justifyContent: "space-between",
          background: "rgba(0,0,0,0.2)",
        }}
      >
        <div className="flex-row" style={{ width: "100%", height: "48%" }}>
          <div
            className="flex-row"
            style={{
              width: "70%",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-evenly",
              borderRadius: "8px",
              background: "rgba(0,0,0,0.2)",
              alignSelf: "start",
            }}
          >
            {!coreIdAlerts || loadingStatusResult === "pending" ? (
              <LoadingOverlay
                active={true}
                spinner={<ClipLoader color="white" size="100px" />}
              />
            ) : (
              <>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "start",
                    margin: "1%",
                    alignSelf: "start",
                  }}
                >
                  <button
                    onClick={(e) => {
                      setAlertsPercentage(true);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "8px 0 0 8px ",
                      background: alertsPercentage
                        ? "rgba(0,0,0,1)"
                        : "rgba(13,60,80,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    %
                  </button>
                  <button
                    onClick={(e) => {
                      setAlertsPercentage(false);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "0 8px 8px 0 ",
                      background: alertsPercentage
                        ? "rgba(13,60,80,1)"
                        : "rgba(0,0,0,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    #
                  </button>
                </div>
                <ReactECharts
                  style={{ height: "90%", width: "50%" }}
                  option={convertAlertsToPieChart(
                    coreIdAlerts,
                    alertsPercentage
                  )}
                  showLoading={
                    !coreIdAlerts || loadingCoreIdAlerts === "pending"
                  }
                  loadingOption={{
                    text: "Cargando",
                    color: "#FFF",
                    textColor: "#FFF",
                    maskColor: "rgba(0, 0, 0, 0.6)",
                    zlevel: 0,
                  }}
                />
                <ReactECharts
                  style={{ height: "90%", width: "50%" }}
                  option={convertStatusToPieChart(
                    statusResult,
                    statusPercentage,
                    "HyperFlow"
                  )}
                  showLoading={false}
                  loadingOption={{
                    text: "Cargando",
                    color: "#FFF",
                    textColor: "#FFF",
                    maskColor: "rgba(0, 0, 0, 0.6)",
                    zlevel: 0,
                  }}
                />
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "start",
                    margin: "1%",
                    alignSelf: "start",
                  }}
                >
                  <button
                    onClick={(e) => {
                      setStatusPercentage(true);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "8px 0 0 8px ",
                      background: statusPercentage
                        ? "rgba(0,0,0,1)"
                        : "rgba(13,60,80,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    %
                  </button>
                  <button
                    onClick={(e) => {
                      setStatusPercentage(false);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "0 8px 8px 0 ",
                      background: statusPercentage
                        ? "rgba(13,60,80,1)"
                        : "rgba(0,0,0,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    #
                  </button>
                </div>
              </>
            )}
          </div>
          <div></div>
          {/* TODO: Add elements */}
        </div>
        <div className="flex-row" style={{ width: "100%", height: "48%" }}>
          {!statusByDate || loadingStatusByDate === "pending" ? (
            <LoadingOverlay
              active={true}
              spinner={<ClipLoader color="white" size="100px" />}
            />
          ) : (
            <ReactECharts
              style={{ height: "90%", width: "100%" }}
              option={convertStatusByDateToStackedBar(
                statusByDate,
                "HyperFlow"
              )}
              showLoading={false}
              loadingOption={{
                text: "Cargando",
                color: "#FFF",
                textColor: "#FFF",
                maskColor: "rgba(0, 0, 0, 0.6)",
                zlevel: 0,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const getAsesoresContent = () => {
    return (
      <div
        className="flex-col"
        style={{
          width: "94%",
          height: "90%",
          alignItems: "center",
          borderRadius: "8px",
          margin: "0 0 0 1%",
          padding: "1% 1% 1% 1%",
          justifyContent: "space-between",
          background: "rgba(0,0,0,0.2)",
        }}
      >
        <div className="flex-row" style={{ width: "100%", height: "48%" }}>
          <div
            className="flex-row"
            style={{
              width: "40%",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-evenly",
              borderRadius: "8px",
              background: "rgba(0,0,0,0.2)",
              alignSelf: "start",
            }}
          >
            {loadingAsesoresAnalytics === "pending" ? (
              <LoadingOverlay
                active={true}
                spinner={<ClipLoader color="white" size="100px" />}
              />
            ) : (
              <>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "start",
                    margin: "1%",
                    alignSelf: "start",
                  }}
                >
                  <button
                    onClick={(e) => {
                      setAsesoresPercentage(true);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "8px 0 0 8px ",
                      background: asesoresPercentage
                        ? "rgba(0,0,0,1)"
                        : "rgba(13,60,80,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    %
                  </button>
                  <button
                    onClick={(e) => {
                      setAsesoresPercentage(false);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "0 8px 8px 0 ",
                      background: asesoresPercentage
                        ? "rgba(13,60,80,1)"
                        : "rgba(0,0,0,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    #
                  </button>
                </div>
                <ReactECharts
                  style={{ height: "90%", width: "50%" }}
                  option={convertAsesorToPieChart(
                    asesoresAnalytics,
                    asesoresPercentage,
                    asesores
                  )}
                  showLoading={false}
                  loadingOption={{
                    text: "Cargando",
                    color: "#FFF",
                    textColor: "#FFF",
                    maskColor: "rgba(0, 0, 0, 0.6)",
                    zlevel: 0,
                  }}
                />
              </>
            )}
          </div>
          <div></div>
        </div>
        <div className="flex-row" style={{ width: "100%", height: "48%" }}>
          {!asesoresByDate || loadingAsesoresByDate === "pending" ? (
            <LoadingOverlay
              active={true}
              spinner={<ClipLoader color="white" size="100px" />}
            />
          ) : (
            <ReactECharts
              key={"asesoresByDate"}
              style={{ height: "90%", width: "100%" }}
              option={convertStatusByDateToStackedBarAsesores(
                asesoresByDate,
                asesores,
                "Asesores con flujos por fecha"
              )}
              showLoading={false}
              loadingOption={{
                text: "Cargando",
                color: "#FFF",
                textColor: "#FFF",
                maskColor: "rgba(0, 0, 0, 0.6)",
                zlevel: 0,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("HyperFlow"));
    if (activeComponent !== "Analytics") {
      dispatch(changeHyperflowActiveComponent("Analytics"));
    }

    if (loadingMFMA === "idle") {
      dispatch(getMFMAHyperflow());
    }

    if (loadingCoreIdAlerts === "idle") {
      dispatch(getAlertsHyperFlow());
    }

    if (loadingStatusResult === "idle") {
      dispatch(getAsesores());
      dispatch(getStatusHyperflow());
    }
    if (loadingStatusByDate === "idle") {
      dispatch(getStatusHyperflowByDate());
    }

    if (loadingAsesoresAnalytics === "idle") {
      dispatch(getAsesoresAnalyticsHyperflow());
    }

    if (loadingAsesoresByDate === "idle") {
      dispatch(getStatusHyperflowAsesoresByDate());
    }
    return () => {};
  }, [activeComponent, hyperflowAnalyticsRequest]);

  return (
    <div
      className="flex-col"
      style={{
        width: "100%",
        height: "90%",
        justifyContent: "space-evenly",
        alignItems: "start",
      }}
    >
      <div className="flex-row" style={{ width: "95%", alignSelf: "center" }}>
        <button
          onClick={(e) => {
            setSelectedSection("general");
          }}
          style={{
            border: "none",
            textAlign: "center",
            borderRadius: "8px 8px 8px 8px ",
            background:
              selectedSection === "general"
                ? "rgba(0,0,0,1)"
                : "rgba(13,60,80,1)",
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingRight: "12px",
            paddingLeft: "12px",
            color: "#fff",
            marginRight: "1%",
          }}
        >
          General
        </button>
        <button
          onClick={(e) => {
            setSelectedSection("matriz");
          }}
          style={{
            border: "none",
            textAlign: "center",
            borderRadius: "8px 8px 8px 8px ",
            background:
              selectedSection === "matriz"
                ? "rgba(0,0,0,1)"
                : "rgba(13,60,80,1)",
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingRight: "12px",
            paddingLeft: "12px",
            color: "#fff",
            marginRight: "1%",
          }}
        >
          Matriz de verificación
        </button>
        {asesores.length > 0 && (
          <button
            onClick={(e) => {
              setSelectedSection("asesores");
            }}
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px 8px 8px 8px ",
              background:
                selectedSection === "asesores"
                  ? "rgba(0,0,0,1)"
                  : "rgba(13,60,80,1)",
              paddingTop: "6px",
              paddingBottom: "6px",
              paddingRight: "12px",
              paddingLeft: "12px",
              color: "#fff",
              marginRight: "1%",
            }}
          >
            Asesores
          </button>
        )}
      </div>
      {showContent()}
    </div>
  );
};
