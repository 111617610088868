import { useEffect, useState } from "react";
import "../../../servicesCommonStyles.scss";
import { RadioButton } from "../../../../common/components/Btns/RadioButton";
import { LoadingStatus } from "../../../../common/commonSlice";
import ClipLoader from "react-spinners/ClipLoader";

export interface StateComponentProps {
  currentState: string;
  eventChangeState?: Function;
  eventChangeCustom?: Function;
  updateRe?: string;
  stepDescriptionId?: string;
  stateOptions?: Object;
  doneState?: Object;
  title?: string;
  loadingIndicator?: LoadingStatus;
}

export const StateComponent = (props: StateComponentProps) => {
  const [state, setState] = useState("");
  const [currentId, setcurrentId] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (state === "") {
      setState(props.currentState);
    }
    if (currentId !== props.updateRe) {
      setcurrentId(props.updateRe ? props.updateRe : "");

      setState(props.currentState);
    }

    return () => {};
  }, [state, props.currentState, currentId, props.updateRe]);
  if (props.stateOptions) {
    if (props.doneState && props.doneState.hasOwnProperty(props.currentState)) {
      return (
        <div
          style={{
            background: "rgba(0,0,0,0.1)",
            borderRadius: "8px",
            padding: "2%",
          }}
        >
          <div className="flex-row">
            <div
              className="text-normal text-medium alert-color"
              style={{
                textAlign: "center",
                borderBottom: "1px solid  rgba(255,255,255,0.5)",
                width: "100%",
                textTransform: "uppercase",
                marginBottom: "10px",
              }}
            >
              Estado de verificación
            </div>
          </div>

          <div
            className="flex-row"
            style={{
              width: "100%",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <div
              className="flex-col"
              style={{
                width: "100%",
                alignContent: "center",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <div
                className="text-medium text-bold primary-color"
                style={{
                  marginTop: "auto",
                  background: "rgba(0,0,0,0.4)",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                COMPLETADO
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        props.currentState === "DONE" ||
        props.currentState === "APPROVED" ||
        props.currentState === "REJECTED"
      ) {
        return (
          <div
            style={{
              background: "rgba(0,0,0,0.1)",
              borderRadius: "8px",
              padding: "2%",
            }}
          >
            <div className="flex-row">
              <div
                className="text-normal text-medium alert-color"
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid  rgba(255,255,255,0.5)",
                  width: "100%",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                }}
              >
                Estado de verificación
              </div>
            </div>

            <div
              className="flex-row"
              style={{
                width: "100%",
                alignContent: "center",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  alignContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="text-medium text-bold primary-color"
                  style={{
                    marginTop: "auto",
                    background: "rgba(0,0,0,0.4)",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  COMPLETADO
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <>
            <div
              style={{
                background: "rgba(0,0,0,0.1)",
                borderRadius: "8px",
                padding: "2%",
              }}
            >
              <div className="flex-row">
                <div
                  className="text-normal text-medium alert-color"
                  style={{
                    textAlign: "right",
                    borderBottom: "1px solid white",
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                >
                  {props.title ? props.title : "DEFINIENDO ESTADO"}
                </div>
              </div>

              <div className="flex-row" style={{ alignItems: "center" }}>
                <div className="flex-col" style={{ width: "60%" }}>
                  {Object.entries(props.stateOptions).map((option, i) => {
                    return (
                      <RadioButton
                        key={i}
                        checked={state === option[0]}
                        label={option[1]}
                        onClick={() => {
                          setState(option[0]);
                        }}
                      />
                    );
                  })}
                  <label
                    htmlFor="comment"
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#FFF",
                        fontWeight: "400",
                        textAlign: "start",
                        margin: "0 0 2px 2px",
                      }}
                    >
                      Comentario
                    </p>
                    <input
                      id="comment"
                      name="comment"
                      type="text"
                      onChange={(e) => {
                        if (e.target.value.length < 80) {
                          setComment(e.target.value);
                        }
                      }}
                      value={comment}
                      style={{
                        border: "0.5px solid rgba(255,255,255,0.2)",
                        borderRadius: "6px",
                        background: "rgba(255,255,255,0.05)",
                        minHeight: "30px",
                        fontSize: "12px",
                        fontWeight: "600",
                        textAlign: "start",
                        width: "100%",
                        color: "#FFF",
                      }}
                    />
                  </label>
                </div>
                <div className="flex-col" style={{ width: "40%" }}>
                  <button
                    className="text-medium text-bold primary-color"
                    style={{
                      marginTop: "auto",
                      marginRight: "2%",
                      marginLeft: "auto",
                      background: "rgba(0,0,0,0.4)",
                      paddingRight: "8%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "8%",
                      borderRadius: "8px",
                      textAlign: "center",
                      border: "none",
                    }}
                    onClick={() => {
                      if (props.eventChangeState) {
                        props.eventChangeState(state);
                      } else if (props.eventChangeCustom) {
                        props.eventChangeCustom({ comment, state });
                      }
                      setState(state);
                    }}
                  >
                    {props.loadingIndicator &&
                    props.loadingIndicator === "pending" ? (
                      <ClipLoader
                        color="#FFF"
                        css={{
                          name: "loadingChangeState",
                          styles: "margin: 0px 20px 0px 20px",
                        }}
                      />
                    ) : (
                      <>
                        CONFIRMAR <br /> CAMBIO
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  } else {
    if (
      props.currentState === "DONE" ||
      props.currentState === "APPROVED" ||
      props.currentState === "REJECTED"
    ) {
      return (
        <div
          style={{
            background: "rgba(0,0,0,0.1)",
            borderRadius: "8px",
            padding: "2%",
          }}
        >
          <div className="flex-row">
            <div
              className="text-normal text-medium alert-color"
              style={{
                textAlign: "center",
                borderBottom: "1px solid  rgba(255,255,255,0.5)",
                width: "100%",
                textTransform: "uppercase",
                marginBottom: "10px",
              }}
            >
              Estado de verificación
            </div>
          </div>

          <div
            className="flex-row"
            style={{
              width: "100%",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <div
              className="flex-col"
              style={{
                width: "100%",
                alignContent: "center",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <div
                className="text-medium text-bold primary-color"
                style={{
                  marginTop: "auto",
                  background: "rgba(0,0,0,0.4)",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                COMPLETADO
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      props.currentState === "AWAITING_MANUAL_INTERACTION" &&
      props.stepDescriptionId === "MANUAL_RETRY"
    ) {
      return <div></div>;
    } else if (props.currentState === "AWAITING_MANUAL_INTERACTION") {
      return (
        <>
          <div
            style={{
              background: "rgba(0,0,0,0.1)",
              borderRadius: "8px",
              padding: "2%",
            }}
          >
            <div className="flex-row">
              <div
                className="text-normal text-medium alert-color"
                style={{
                  textAlign: "right",
                  borderBottom: "1px solid white",
                  width: "100%",
                }}
              >
                DEFINIENDO ESTADO
              </div>
            </div>

            <div className="flex-row">
              <div className="flex-col" style={{ width: "60%" }}>
                <RadioButton
                  checked={state === "AWAITING_MANUAL_INTERACTION"}
                  label="PARA REVISAR"
                  onClick={() => {
                    setState("AWAITING_MANUAL_INTERACTION");
                  }}
                />
                <RadioButton
                  checked={state === "DONE-Y"}
                  label="ACEPTAR"
                  onClick={() => {
                    setState("DONE-Y");
                  }}
                />
                <RadioButton
                  checked={state === "FAILED_MANUAL_RETRY"}
                  label="RECHAZAR"
                  onClick={() => {
                    setState("FAILED_MANUAL_RETRY");
                  }}
                />

                <label
                  htmlFor="comment"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Comentario
                  </p>
                  <input
                    id="comment"
                    name="comment"
                    type="text"
                    onChange={(e) => {
                      if (e.target.value.length < 80) {
                        setComment(e.target.value);
                      }
                    }}
                    value={comment}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      minHeight: "30px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "start",
                      width: "100%",
                      color: "#FFF",
                    }}
                  />
                </label>
              </div>
              <div className="flex-col" style={{ width: "40%" }}>
                <button
                  className="text-medium text-bold primary-color"
                  style={{
                    marginTop: "auto",
                    marginRight: "2%",
                    marginLeft: "auto",
                    background: "rgba(0,0,0,0.4)",
                    paddingRight: "8%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "8%",
                    borderRadius: "8px",
                    textAlign: "center",
                    border: "none",
                  }}
                  onClick={() => {
                    if (props.eventChangeState) {
                      props.eventChangeState({
                        state: state.split("-")[0],
                        comment: comment,
                      });
                    }
                    setState(state.split("-")[0]);
                  }}
                >
                  CONFIRMAR <br /> CAMBIO
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else if (props.currentState === "PENDING") {
      return (
        <>
          <div
            style={{
              background: "rgba(0,0,0,0.1)",
              borderRadius: "8px",
              padding: "2%",
            }}
          >
            <div className="flex-row">
              <div
                className="text-normal text-medium alert-color"
                style={{
                  textAlign: "right",
                  borderBottom: "1px solid white",
                  width: "100%",
                }}
              >
                DEFINIENDO ESTADO
              </div>
            </div>

            <div className="flex-row">
              <div className="flex-col" style={{ width: "60%" }}>
                <RadioButton
                  checked={state === "APPROVED"}
                  label="ACEPTAR"
                  onClick={() => {
                    setState("APPROVED");
                  }}
                />
                <RadioButton
                  checked={state === "REJECTED"}
                  label="RECHAZAR"
                  onClick={() => {
                    setState("REJECTED");
                  }}
                />
                <label
                  htmlFor="comment"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Comentario
                  </p>
                  <input
                    id="comment"
                    name="comment"
                    type="text"
                    onChange={(e) => {
                      if (e.target.value.length < 80) {
                        setComment(e.target.value);
                      }
                    }}
                    value={comment}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      minHeight: "30px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "start",
                      width: "100%",
                      color: "#FFF",
                    }}
                  />
                </label>
              </div>
              <div className="flex-col" style={{ width: "40%" }}>
                <button
                  className="text-medium text-bold primary-color"
                  style={{
                    marginTop: "auto",
                    marginRight: "2%",
                    marginLeft: "auto",
                    background: "rgba(0,0,0,0.4)",
                    paddingRight: "8%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "8%",
                    borderRadius: "8px",
                    textAlign: "center",
                    border: "none",
                  }}
                  onClick={() => {
                    if (props.eventChangeState) {
                      props.eventChangeState({
                        state: state.split("-")[0],
                        comment: comment,
                      });
                    }
                    setState(state.split("-")[0]);
                  }}
                >
                  CONFIRMAR <br /> CAMBIO
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
};
