import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";

import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import {
  changeCurrentActiveComponent,
  changeCurrentFilters,
  getFilterOptions,
  selectCurrentFilterRequest,
  selectFilteredOptions,
  selectSmartFlowActiveComponent,
} from "./smartFlowSlice";
import { DropDown } from "../../common/components/Btns/DropDown";
import {
  changeHyperflowFilters,
  changeSmartflowFilters,
  resetStateAnalytics,
  selectHyperflowRequest,
  selectSmartFlowRequest,
} from "../analytics/analyticsSlice";

export const SmartFlow = () => {
  let filterOptions = useAppSelector(selectFilteredOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let analyticsRequest = useAppSelector(selectSmartFlowRequest);
  let analyticsRequestHyper = useAppSelector(selectHyperflowRequest);

  let roles = useAppSelector(selectRoles);
  let activeComponent = useAppSelector(selectSmartFlowActiveComponent);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let statusOptions: { value: string; label: string }[] = [
      { label: "En proceso", value: "IN_PROGRESS" },
      { label: "Pendiente", value: "PENDING" },
      { label: "Aceptado", value: "APPROVED" },
      { label: "Rechazado", value: "REJECTED" },
    ];
    let statusComponent = (
      <DropDown
        key={"Status"}
        title="Estado"
        options={statusOptions}
        onChange={(e) => {
          if (activeComponent === "flows") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                status: e?.value!,
              })
            );
          } else if (activeComponent === "Analytics") {
            dispatch(
              changeSmartflowFilters({
                ...analyticsRequest,
                status: e?.value!,
              })
            );
          }
        }}
      />
    );

    if (activeComponent === "flows") {
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={false}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      endDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      startDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            resetFunction={() => {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  endDate: null,
                  startDate: null,
                })
              );
            }}
            items={[statusComponent]}
          />
        </>
      );
    } else if (activeComponent === "Analytics") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          calendarType="double"
          endDate={
            analyticsRequest.endDate ? new Date(analyticsRequest.endDate) : null
          }
          startDate={
            analyticsRequest.startDate
              ? new Date(analyticsRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeSmartflowFilters({
                    ...analyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
                dispatch(
                  changeHyperflowFilters({
                    ...analyticsRequestHyper,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeSmartflowFilters({
                    ...analyticsRequest,
                    startDate: date.toISOString(),
                  })
                );

                dispatch(
                  changeHyperflowFilters({
                    ...analyticsRequestHyper,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          items={[statusComponent]}
        />
      );
    }else {
      return <div></div>
    }
  };

  const getCategoryItems = (): JSX.Element[] => {
    let items: JSX.Element[] = [];
    items.push(
      <Category
        key={0}
        name="SmartFlows"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Smart Flow"));
          dispatch(changeCurrentActiveComponent("flows"));
          navigate("/services/smartFlow/flows");
        }}
        selected={activeComponent === "flows"}
      />
    );
    if (
      roles.includes("analytics.admin") ||
      roles.includes("analytics.smartflow.view")
    ) {
      items.push(
        <Category
          key={1}
          name="Analitica"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Smart Flow"));
            dispatch(changeCurrentActiveComponent("flows"));
            dispatch(resetStateAnalytics());
            navigate("/services/smartFlow/analytics");
          }}
          selected={activeComponent === "Analytics"}
        />
      );
    }
    return items;
  };

  useEffect(() => {
    if (!filterOptions) {
      dispatch(getFilterOptions());
    }
  }, [filterOptions]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "center" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
