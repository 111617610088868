import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  getTemplateById,
  selectCurrentTemplate,
  selectNotFoundMessageMagicForms,
  selectTemplateDetailsLoading,
} from "../magicFormsSlice";

export const TemplateDetail = () => {
  let dispatch = useAppDispatch();
  let currentTemplate = useAppSelector(selectCurrentTemplate);
  let loadingStatus = useAppSelector(selectTemplateDetailsLoading);
  let notFoundMessage = useAppSelector(selectNotFoundMessageMagicForms);
  let { id } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    if ((!currentTemplate || currentTemplate.id) !== id && id !== "") {
      dispatch(getTemplateById(id || ""));
    }
    dispatch(changeActiveComponent("none"));
  }, [currentTemplate]);
  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {notFoundMessage && notFoundMessage !== "" ? (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "idle"
              ? ""
              : " No hay más documentos en esta página"}
          </h1>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            paddingLeft: "0.5%",
            paddingTop: "0.5%",
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div className="flex-col" style={{ width: "100%", height: "100%" }}>
            <div
              className="flex-row"
              style={{
                height: "15%",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "5%",
              }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="flex-row"
                style={{ alignItems: "center", justifyContent: "space-evenly" }}
              >
                <LeftArrow style={{ marginRight: "15px" }} />
                <div className="text-bold text-xx-large primary-color">
                  Tipo de documentos
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{
                height: "80%",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <div
                className="flex-col"
                style={{
                  alignItems: "start",
                  width: "30%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    height: "40%",
                    borderRadius: "8px 8px 8px 8px",
                  }}
                >
                  <div
                    className="flex-col text-normal text-medium primary-color-35"
                    style={{
                      marginLeft: "25px",
                      marginTop: "15px",
                      marginRight: "25px",
                      marginBottom: "15px",
                      justifyContent: "space-evenly",
                      height: "100%",
                    }}
                  >
                    <div>ID de documento: {currentTemplate?.id}</div>
                    <div>Descripción: {currentTemplate?.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
