import axiosApiInstance from "../../../helpers/interceptor";

let filteredAlertsMethod = "alerts/getFiltered";
let getFilterByMethod = (service: string, itemId: string) =>
  `alerts/getByItemId/${service}/${itemId}`;
let getNumAlerts = "alerts/numAlerts";
let dashBoardAlertsMethod = "alerts/getCriticality";
let changeStatusMethod = (id: string) => `alerts/changeStatus/${id}`;

let getUnifiedAlertsFilteredAPI = async (
  params: UnifiedAlertsFilteredRequest
): Promise<{ error: string; alerts: UnifiedAlertsFiltered | null }> => {
  try {
    let unifiedAlerts = await axiosApiInstance.post(
      `/${filteredAlertsMethod}`,
      params
    );

    return { error: "error", alerts: unifiedAlerts.data };
  } catch (err: any) {
    return { error: "Error getting alerts", alerts: null };
  }
};

export const getDashboardAlertsAPI = async (): Promise<{
  error: string;
  dashboard: DashboardAlerts | null;
}> => {
  try {
    let dashboardAlerts = await axiosApiInstance.get(
      `/${dashBoardAlertsMethod}`
    );
    return { error: "error", dashboard: dashboardAlerts.data };
  } catch (err: any) {
    return { error: "Error getting Dashboard", dashboard: null };
  }
};

export const changeAlertStatusAPI = async (
  req: AlertStatusChangeRequest
): Promise<{ error: string; alert: UnifiedAlerts | null }> => {
  try {
    let alert = await axiosApiInstance.post(`/${changeStatusMethod(req.id)}`, {
      newStatus: req.status,
    });
    return { error: "error", alert: alert.data };
  } catch (err: any) {
    return { error: "Error changin status", alert: null };
  }
};

let getAlertsByItemIdAPI = async (
  itemId: string,
  service: string
): Promise<{ error: string; alerts: UnifiedAlertsFiltered | null }> => {
  let unifiedAlerts = await axiosApiInstance.get(
    `/${getFilterByMethod(service, itemId)}`
  );

  if (unifiedAlerts.status !== 200) {
    return { error: "error", alerts: null };
  } else {
    return { error: "", alerts: unifiedAlerts.data };
  }
};
let getNumberAlerts = async (): Promise<{
  error: string;
  alerts: NumAlerts | null;
}> => {
  let unifiedAlerts = await axiosApiInstance.get(`/${getNumAlerts}`);

  if (unifiedAlerts.status !== 200) {
    return { error: "error", alerts: null };
  } else {
    return { error: "", alerts: unifiedAlerts.data };
  }
};
export interface NumAlerts {
  numAlerts: number | null;
}

export interface DashboardAlerts {
  compliance: string;
  biometry: string;
  incongruences: string;
  dates: string;
  authenticity: string;
  other: string;
  attachments: string;
}

export interface AlertStatusChangeResponse {
  id: string;
  newStatus: string;
}

export interface AlertStatusChangeRequest {
  id: string;
  status: string;
}

export interface UnifiedAlertsFilteredRequest {
  page: number;
  category:
    | "COMPLIANCE"
    | "BIOMETRY"
    | "INCONGRUENCE"
    | "DATE"
    | "AUTHENTICITY"
    | "ATTACHMENT"
    | "OTHERS"
    | null;
  service: "COREID" | "FIRMA" | "MAGICFORMS" | "HYPERFLOW" | "CLIENTS" | null;
  criticality: "HIGH" | "MEDIUM" | "LOW" | "OTHER" | "NONE" | null;
  status: "ACTIVE" | "ACK" | "CLOSED" | null;
  startDate: string | null;
  endDate: string | null;
}

export interface UnifiedAlertsFiltered {
  numPages: number;
  currentPage: number;
  alerts: Array<UnifiedAlerts>;
}
export interface UnifiedAlerts {
  id: string;
  userId: string;
  category: string;
  criticality: string;
  status: string;
  service: string;
  date: string;
  name: string;
  description: string;
  entityId: string;
  userName: string;
}

export { getUnifiedAlertsFilteredAPI, getAlertsByItemIdAPI, getNumberAlerts };
