import { ClipLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  deleteCurrentProcessId,
  dequeueError,
  getDocumentConfigs,
  getProcessById,
  resetProcess,
  selectConfigs,
  selectCurrentErrorATK,
  selectCurrentProcessATK,
  selectDocumentConfigsMapATK,
  selectDocumentUploadingATK,
  selectErrorQueueATK,
  selectProcessLoadingATK,
  uploadDocument,
} from "./AuthentikatorSlice";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../../Assets/common/images/leftArrow.svg";
import { useEffect, useState } from "react";
import {
  ATKProcess,
  ATKProcessDataPoint,
  ATKProcessDocument,
  downloadDocumentAPI,
} from "./AuthentikatorAPI";
import Modal, { Styles } from "react-modal";
import fileDownload from "js-file-download";

export const AuthentikatorProcessDetail = () => {
  const customStylesModal3: Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",

      width: "40%",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "1%",
    },
    overlay: {
      background: "rgba(0,0,0,0.6)",
    },
  };
  Modal.setAppElement("#root");

  //Selectors
  let processLoading = useAppSelector(selectProcessLoadingATK);
  let currentProcess = useAppSelector(selectCurrentProcessATK);
  let documentConfigs = useAppSelector(selectDocumentConfigsMapATK);
  let uploadingDocumentLoader = useAppSelector(selectDocumentUploadingATK);
  let processConfigs = useAppSelector(selectConfigs);
  let errorQueue = useAppSelector(selectErrorQueueATK);
  //LocalState
  let [currentSection, setCurrentSection] = useState<"data" | "documents">(
    "documents"
  );
  let [selectedDocument, setSelectedDocument] =
    useState<ATKProcessDocument | null>(null);
  let [selectedDataPoint, setSelectedDataPoint] =
    useState<ATKProcessDataPoint | null>(null);

  let [showModalUpload, setShowModalUpload] = useState(false);
  let [uploadDisabled, setUploadDisabled] = useState(true);
  let [selectedFile, setSelectedFile] = useState<File | null>(null);
  let [selectedFileBase64, setSelectedFileBase64] = useState<string | null>(
    null
  );
  let [fileName, setFileName] = useState<string | null>(null);
  let [currentError, setCurrentError] = useState<string | null>(null);

  //react stuff
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let id = useParams<{ id: string }>();

  //Effects
  useEffect(() => {
    if (id.id && !currentProcess) {
      dispatch(getProcessById(id.id));
    } else if (id.id && id.id !== currentProcess?.id) {
      dispatch(getProcessById(id.id));
    } else if (!id.id) {
      dispatch(deleteCurrentProcessId());
      navigate(-1);
    }
    if (selectedDocument !== null) {
      setSelectedDocument(
        currentProcess?.documents.find(
          (doc) => doc.documentId === selectedDocument?.documentId
        ) ?? null
      );
    }
  }, [currentProcess]);

  useEffect(() => {
    if (Object.keys(documentConfigs).length === 0) {
      dispatch(getDocumentConfigs());
      dispatch(deleteCurrentProcessId());
    }
  }, []);

  useEffect(() => {
    if (selectedFile) {
      let reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = function () {
        setSelectedFileBase64(reader.result?.toString().split(",")[1] ?? "");
        setUploadDisabled(false);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
        alert("error");
        setUploadDisabled(true);
      };
    }
  }, [selectedFile]);

  useEffect(() => {
    if (uploadingDocumentLoader === "resolved") {
      closeUploadModal();
    }
  }, [uploadingDocumentLoader]);

  useEffect(() => {
    if (errorQueue.length > 0) {
      setCurrentError(errorQueue[0]);
    }
  }, [errorQueue]);

  //Helper funtions
  const getDocumentName = (id: string) => {
    return documentConfigs[id]?.name ?? "";
  };
  const getDocumentDescription = (id: string) => {
    return documentConfigs[id]?.description ?? "";
  };

  const getDocumentNameFromId = (id: string) => {
    let docConfigId = currentProcess?.documents.find(
      (doc) => doc.documentId === id
    )?.documentConfigId;
    return getDocumentName(docConfigId ?? "");
  };

  const closeErrorModal = () => {
    setCurrentError(null);
    dispatch(dequeueError());
  };

  const closeUploadModal = () => {
    setShowModalUpload(false);
    setSelectedFile(null);
    setSelectedFileBase64(null);
    setFileName(null);
    setUploadDisabled(true);
  };

  const getUploadedTag = (doc: ATKProcessDocument) => {
    if (doc.uploaded && doc.validityScore >= 50) {
      return (
        <div
          style={{
            background: "rgba(96, 255, 82, 0.69)",
            borderRadius: "8px",
            fontSize: "12px",
            padding: "5px",
          }}
        >
          Cargado: Válido
        </div>
      );
    } else if (doc.uploaded && doc.validityScore < 50) {
      return (
        <div
          style={{
            background: "rgba(255, 173, 35, 0.84)",
            borderRadius: "8px",
            fontSize: "12px",
            padding: "5px",
          }}
        >
          Cargado: No Válido
        </div>
      );
    } else {
      return (
        <div
          style={{
            background: "rgb(255, 92, 92)",
            borderRadius: "8px",
            fontSize: "12px",
            padding: "5px",
          }}
        >
          No cargado
        </div>
      );
    }
  };

  const getCongruentTag = (dataPoint: ATKProcessDataPoint) => {
    if (dataPoint.allValid) {
      return (
        <div
          style={{
            background: "rgba(96, 255, 82, 0.69)",
            borderRadius: "8px",
            fontSize: "12px",
            padding: "5px",
          }}
        >
          Coherente
        </div>
      );
    } else {
      return (
        <div
          style={{
            background: "rgba(255, 92, 92)",
            borderRadius: "8px",
            fontSize: "12px",
            padding: "5px",
          }}
        >
          No Coherente
        </div>
      );
    }
  };

  const getDataNameById = (id: string) => {
    return (
      processConfigs[currentProcess?.configId ?? ""]?.dataNameMapper[id] ?? id
    );
  };

  const getDocumentTypeByExtension = (fileName: string) => {
    let extension = fileName.split(".").pop();
    if (extension === "pdf") {
      return "application/pdf";
    } else if (extension === "png") {
      return "image/png";
    } else if (extension === "jpeg" || extension === "jpg") {
      return "image/jpg";
    } else {
      return "application/pdf";
    }
  };

  const downloadDocument = async (processId: string, documentId: string) => {
    let resp = await downloadDocumentAPI(processId, documentId);
    if (
      resp.result === false ||
      resp.data === undefined ||
      resp.data?.docBase64 === "" ||
      resp.data?.docBase64 === null ||
      resp.data?.fileName === "" ||
      resp.data?.fileName === null
    ) {
      alert("Error al descargar el documento");
      return;
    }
    let url = `data:${getDocumentTypeByExtension(resp.data?.fileName)};base64,${
      resp.data?.docBase64
    }`;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        fileDownload(
          blob,
          `${currentProcess?.id}_${getDocumentNameFromId(documentId).replace(
            " ",
            "-"
          )}_${resp.data?.fileName}`
        );
      });
  };
  // components
  const getDocumentView = (
    currentProcessLocal: ATKProcess | null,
    selectedDocumentLocal: ATKProcessDocument | null
  ) => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.4)",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* Document list */}
        <div
          style={{
            width: "30%",
            height: "100%",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          {currentProcessLocal?.documents.map((doc, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedDocument(doc);
                }}
                key={doc.documentId}
                style={{
                  background:
                    selectedDocumentLocal?.documentId === doc.documentId
                      ? "rgba(0,0,0,0.7)"
                      : "rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                  width: "100%",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div className='flex-col'>
                  {getDocumentName(doc.documentConfigId)}
                </div>
                <div className='flex-col'>{getUploadedTag(doc)}</div>
              </div>
            );
          })}
        </div>
        {/* Document Detail */}
        {selectedDocumentLocal !== null && (
          <div
            style={{
              width: "70%",
              height: "100%",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              fontSize: "18px",
            }}
          >
            <div className='flex-row justify-content-between align-items-center'>
              <div className='flex-col'>
                <h1 style={{ fontSize: "16px" }}>
                  Nombre del archivo: {selectedDocumentLocal.providedFileName}
                </h1>
              </div>
              <div className='flex-col' style={{ marginRight: "20px" }}>
                {selectedDocumentLocal.uploaded && (
                  <button
                    onClick={() => {
                      downloadDocument(
                        currentProcessLocal?.id ?? "",
                        selectedDocumentLocal.documentId
                      );
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                      textAlign: "center",
                      borderRadius: "8px",
                      background:
                        currentSection === "documents"
                          ? "rgba(0,0,0,0.6)"
                          : "rgba(0,0,0,0.2)",
                      width: "250px",
                      height: "40px",
                      color: "white",
                    }}
                  >
                    Descargar documento
                  </button>
                )}
              </div>
              <div className='flex-col'>
                <button
                  onClick={() => {
                    setShowModalUpload(true);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none",
                    textAlign: "center",
                    borderRadius: "8px",
                    background:
                      currentSection === "documents"
                        ? "rgba(0,0,0,0.6)"
                        : "rgba(0,0,0,0.2)",
                    width: "300px",
                    height: "40px",
                    color: "white",
                  }}
                >
                  {selectedDocumentLocal.uploaded
                    ? "Reemplazar documento"
                    : "Cargar documento"}
                </button>
              </div>
            </div>
            <div className='flex-row' style={{ marginTop: "20px" }}>
              Validez: {selectedDocumentLocal.validityScore}%
            </div>
            <div className='flex-row'>
              Detalle de validez: {selectedDocumentLocal.validityReason}
            </div>
            {getDataTable(selectedDocumentLocal)}
          </div>
        )}
      </div>
    );
  };

  const getDataView = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.4)",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* Data list */}
        <div
          style={{
            width: "30%",
            height: "53vh",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            overflowY: "scroll",
          }}
        >
          {currentProcess?.data.map((data, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedDataPoint(data);
                }}
                key={data.dataId}
                style={{
                  background:
                    selectedDataPoint?.dataId === data.dataId
                      ? "rgba(0,0,0,0.7)"
                      : "rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                  width: "100%",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div className='flex-col' style={{ width: "100%" }}>
                  <div
                    className='flex-row justify-content-between'
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    <div className='flex-col'>
                      {getDataNameById(data.dataId)}
                    </div>
                    <div className='flex-col'>{getCongruentTag(data)}</div>
                  </div>
                  <div
                    className='divider'
                    style={{
                      width: "100%",
                      height: "1px",
                      background: "rgba(255, 255, 255, 0.4)",
                      margin: "20px 0",
                    }}
                  ></div>
                  <div className='flex-row'>{data.mayorityAgreement ?? ""}</div>
                  <br />
                </div>
              </div>
            );
          })}
        </div>
        {/* Data Detail */}
        {selectedDataPoint !== null && (
          <div
            style={{
              width: "70%",
              height: "100%",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <div className='flex-row justify-content-between align-items-center'>
              <div className='flex-col'>
                <h1 style={{ fontSize: "18px" }}>
                  Id Dato: {selectedDataPoint.dataId}
                </h1>
              </div>
            </div>
            <div className='flex-row'>
              Dato leído: {selectedDataPoint.mayorityAgreement ?? ""}
            </div>
            <div className='flex-row'>
              Coherencia:{" "}
              {selectedDataPoint.allValid
                ? "Todos coherentes"
                : "No todos coherentes"}
            </div>
            <div className='flex-row' style={{ width: "100%" }}>
              {getDocumentsDataTable(selectedDataPoint)}
            </div>
          </div>
        )}
      </div>
    );
  };

  const modalUploadView = () => {
    return (
      <Modal
        isOpen={showModalUpload}
        onRequestClose={() => {
          closeUploadModal();
        }}
        style={customStylesModal3}
        contentLabel='SubirDocumento'
        shouldCloseOnOverlayClick={true}
      >
        {uploadingDocumentLoader === "pending" ? (
          <div
            className='flex-col'
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <ClipLoader color='#49829C' size={200} />
            <p style={{ color: "black" }}>Cargando documento...</p>
          </div>
        ) : (
          <div
            className='flex-col'
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {" "}
            <div
              className='flex-row'
              style={{
                justifyContent: "end",
                alignItems: "end",
                marginTop: "10px",
                marginBottom: "10px",
                marginRight: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  closeUploadModal();
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "24px",
                  background: "#1A617D",
                  width: "40px",
                  height: "35px",
                  color: "white",
                }}
              >
                X
              </button>
            </div>
            <h4 style={{ fontSize: "20px", fontWeight: 700, color: "#000" }}>
              Cargar documento:{" "}
              {getDocumentName(selectedDocument?.documentConfigId ?? "")}
            </h4>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000",
                width: "80%",
              }}
            >
              {getDocumentDescription(selectedDocument?.documentConfigId ?? "")}
            </p>{" "}
            <input
              type='file'
              onChange={(event) => {
                let file = event?.target?.files?.[0];
                if (file) {
                  setSelectedFile(file);
                  setFileName(file.name);
                  // setUploadDisabled(false);
                }
              }}
            />
            <div
              className='flex-row'
              style={{
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "100px",
              }}
            >
              <div
                className='flex-row text-bold primary-color'
                style={{
                  fontSize: "13px",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                <button
                  onClick={() => {
                    if (
                      selectedDocument?.documentId &&
                      selectedFileBase64 &&
                      fileName &&
                      currentProcess?.id
                    ) {
                      dispatch(
                        uploadDocument({
                          documentId: selectedDocument?.documentId,
                          processId: currentProcess?.id,
                          fileName: fileName,
                          fileBase64: selectedFileBase64,
                        })
                      );
                    } else {
                      alert("Falta algo");
                    }
                  }}
                  disabled={uploadDisabled}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none",
                    textAlign: "center",
                    borderRadius: "8px",
                    fontSize: "24px",
                    background: uploadDisabled === true ? "#808080" : "#1A617D",
                    width: "200px",
                    height: "35px",
                    color: "white",
                  }}
                >
                  Subir
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  };

  const modalError = () => {
    return (
      <Modal
        isOpen={currentError !== null && currentError !== ""}
        onRequestClose={() => {
          closeErrorModal();
        }}
        style={customStylesModal3}
        contentLabel='Advertencia'
        shouldCloseOnOverlayClick={true}
      >
        <div
          className='flex-col'
          style={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {" "}
          <div
            className='flex-row'
            style={{
              justifyContent: "end",
              alignItems: "end",
              marginTop: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              width: "100%",
            }}
          >
            <button
              onClick={() => {
                closeErrorModal();
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                fontSize: "24px",
                background: "#1A617D",
                width: "40px",
                height: "35px",
                color: "white",
              }}
            >
              X
            </button>
          </div>
          <div className='flex-row'>
            <h4 style={{ fontSize: "20px", fontWeight: 700, color: "#000" }}>
              Advertencia:
            </h4>
          </div>
          <div className='flex-row'>
            <h4 style={{ fontSize: "16px", fontWeight: 600, color: "#000" }}>
              {currentError}
            </h4>
          </div>
        </div>
      </Modal>
    );
  };

  const getDocumentsDataTable = (dataPoint: ATKProcessDataPoint) => {
    return (
      <div className='flex-col' style={{ width: "100%" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            borderRadius: "8px",
          }}
        >
          <thead
            className='text-normal text-medium primary-color-35'
            style={{
              background: "rgba(0,0,0,0.4)",
              height: "30px",
              textAlign: "center",
              borderRadius: "8px",
            }}
          >
            <tr>
              <th style={{ width: "50%" }}>Id</th>
              <th style={{ width: "50%" }}>Dato</th>
            </tr>
          </thead>
          <tbody
            className='text-normal text-x-small primary-color'
            style={{
              background: "rgba(0,0,0,0.2)",
              textAlign: "center",
            }}
          >
            {Object.keys(dataPoint.extractedData).map((documentId, i) => {
              return (
                <tr
                  className='list-hover'
                  key={documentId}
                  style={{
                    margin: "10px",
                    height: "30px",
                  }}
                >
                  <td>{getDocumentNameFromId(documentId)}</td>
                  <td>{dataPoint.extractedData[documentId]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const getDataTable = (document: ATKProcessDocument) => {
    return (
      <div className='flex-row'>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            borderRadius: "8px",
          }}
        >
          <thead
            className='text-normal text-medium primary-color-35'
            style={{
              background: "rgba(0,0,0,0.4)",
              height: "30px",
              textAlign: "center",
              borderRadius: "8px",
            }}
          >
            <tr>
              <th style={{ width: "50%" }}>Id</th>
              <th style={{ width: "50%" }}>Dato</th>
            </tr>
          </thead>
          <tbody
            className='text-normal text-x-small primary-color'
            style={{
              background: "rgba(0,0,0,0.2)",
              textAlign: "center",
            }}
          >
            {Object.keys(document.data).map((dataPointKey, i) => {
              return (
                <tr
                  className='list-hover'
                  key={dataPointKey}
                  style={{
                    margin: "10px",
                    height: "30px",
                  }}
                >
                  <td>{getDataNameById(dataPointKey)}</td>
                  <td>{document.data[dataPointKey]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {Object.keys(document.data).forEach((dataPoint, index) => {})}
      </div>
    );
  };

  return (
    <LoadingOverlay
      active={processLoading === "pending"}
      spinner={<ClipLoader color='white' size='150px' />}
    >
      {modalUploadView()}
      {modalError()}
      <div
        style={{
          padding: "50px",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          color: "white",
        }}
      >
        <div
          className='flex-row justify-content-between'
          style={{ marginBottom: "30px" }}
        >
          <h1>Detalle del proceso</h1>
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetProcess());
              dispatch(deleteCurrentProcessId());
              navigate(-1);
            }}
            className='flex-row'
            style={{
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <LeftArrow style={{ marginRight: "15px" }} />
            <div className='text-bold text-xx-large primary-color'>
              Regresar a lista de procesos
            </div>
          </div>
        </div>
        <div
          className='flex-row justify-content-between'
          style={{ width: "100%" }}
        >
          <div
            className='flex-col'
            style={{
              width: "40%",
              borderRadius: "8px",
              background: "rgba(0,0,0,0.4)",
              fontSize: "14px",
              padding: "20px",
            }}
          >
            <div className='flex-row' style={{ marginBottom: "10px" }}>
              Nombres y Apellidos: {currentProcess?.manualInfo.fullName}
            </div>
            <div className='flex-row' style={{ marginBottom: "10px" }}>
              Correo: {currentProcess?.manualInfo.email}
            </div>
            <div className='flex-row' style={{ marginBottom: "10px" }}>
              Documento: {currentProcess?.manualInfo.document}
            </div>
            <div className='flex-row' style={{ marginBottom: "10px" }}>
              Id personalizado: {currentProcess?.manualInfo.customId}
            </div>
          </div>
          <div
            className='flex-col justify-content-end'
            style={{ paddingLeft: "20px", width: "300px" }}
          >
            <button
              onClick={() => {
                setCurrentSection("documents");
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                fontSize: "20px",
                background:
                  currentSection === "documents"
                    ? "rgba(0,0,0,0.6)"
                    : "rgba(0,0,0,0.2)",
                width: "200px",
                height: "45px",
                color: "white",
              }}
            >
              Ver documentos
            </button>
          </div>
          <div
            className='flex-col justify-content-end'
            style={{ paddingLeft: "20px", width: "300px" }}
          >
            <button
              onClick={() => {
                setCurrentSection("data");
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                fontSize: "20px",
                background:
                  currentSection === "data"
                    ? "rgba(0,0,0,0.6)"
                    : "rgba(0,0,0,0.2)",
                width: "200px",
                height: "45px",
                color: "white",
              }}
            >
              Ver datos
            </button>
          </div>
        </div>
        <div
          className='flex-row'
          style={{ width: "100%", height: "100%", marginTop: "30px" }}
        >
          {currentSection === "documents" &&
            getDocumentView(currentProcess, selectedDocument)}
          {currentSection === "data" && getDataView()}
        </div>
      </div>
    </LoadingOverlay>
  );
};
