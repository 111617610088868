import { ClipLoader } from "react-spinners";
import { LoadingStatus } from "../../../common/commonSlice";
import { PasswordData, PasswordDataError } from "../../configurationAPI";

export interface ChangeAccountPasswordProps {
  passwordData: PasswordData;
  passwordDataError: PasswordDataError;
  width: string;
  loading: LoadingStatus;
  confirmChanges: Function;
  cancelChanges: Function;
  onChangeCurrent: React.ChangeEventHandler<HTMLInputElement>;
  onChangeNew: React.ChangeEventHandler<HTMLInputElement>;
  onChangeNewRepeat: React.ChangeEventHandler<HTMLInputElement>;
  confirmLoading: LoadingStatus;
}

export const ChangeAccountPassword = (props: ChangeAccountPasswordProps) => {
  const buttonDisabled = () => {
    return (
      props.passwordDataError.newPasswordError !== "" ||
      props.passwordDataError.newPasswordRepeatError !== "" ||
      props.passwordDataError.oldPasswordError !== "" ||
      props.passwordData.newPassword.length === 0 ||
      props.passwordData.newPasswordRepeat.length === 0 ||
      props.passwordData.oldPassword.length === 0
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: props.width,
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "8px 0px 8px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: "13px",
          backgroundColor: "rgba(0,0,0,0.4)",
          color: "rgba(255,255,255, 0.35)",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        Información básica
      </div>
      <div
        style={{
          width: "100%",
          padding: "8px 16px 8px 16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          fontSize: "13px",
          backgroundColor: "rgba(0,0,0,0.2)",
          minHeight: "280px",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        {props.loading === "pending" ? (
          <ClipLoader color="#FFF" size="120" />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <label
              htmlFor="oldPassword"
              style={{ width: "100%", marginRight: "10px" }}
            >
              <p
                style={{
                  fontSize: "11px",
                  color: "#FFF",
                  fontWeight: "400",
                  textAlign: "start",
                  margin: "0 0 2px 2px",
                }}
              >
                Contraseña Actual
              </p>
              <input
                id="oldPassword"
                name="oldPassword"
                type="password"
                onChange={props.onChangeCurrent}
                value={props.passwordData?.oldPassword}
                style={{
                  border: "0.5px solid rgba(255,255,255,0.2)",
                  borderRadius: "6px",
                  background: "rgba(255,255,255,0.05)",
                  padding: "9px 0px 9px 0px",
                  minHeight: "24px",
                  fontSize: "12px",
                  fontWeight: "600",
                  textAlign: "center",
                  color: "#FFF",
                  width: "100%",
                }}
              />
              <p
                style={{
                  fontSize: "10px",
                  color: "rgba(255, 105, 105, 0.7)",
                  fontWeight: "400",
                  textAlign: "end",
                  margin: "2px 2px 0 0",
                  visibility: props.passwordDataError.oldPasswordError
                    ? "visible"
                    : "hidden",
                }}
              >
                {props.passwordDataError.oldPasswordError}
              </p>
            </label>
            <label
              htmlFor="newPassword"
              style={{ width: "100%", marginRight: "10px" }}
            >
              <p
                style={{
                  fontSize: "11px",
                  color: "#FFF",
                  fontWeight: "400",
                  textAlign: "start",
                  margin: "0 0 2px 2px",
                }}
              >
                Nueva contraseña{" "}
              </p>
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                onChange={props.onChangeNew}
                value={props.passwordData?.newPassword}
                style={{
                  border: "0.5px solid rgba(255,255,255,0.2)",
                  borderRadius: "6px",
                  background: "rgba(255,255,255,0.05)",
                  padding: "9px 0px 9px 0px",
                  minHeight: "24px",
                  fontSize: "12px",
                  fontWeight: "600",
                  textAlign: "center",
                  color: "#FFF",
                  width: "100%",
                }}
              />

              <p
                style={{
                  fontSize: "10px",
                  color: "rgba(255, 105, 105, 0.7)",
                  fontWeight: "400",
                  textAlign: "end",
                  margin: "2px 2px 0 0",
                  visibility: props.passwordDataError.newPasswordError
                    ? "visible"
                    : "hidden",
                }}
              >
                {props.passwordDataError.newPasswordError}
              </p>
            </label>

            <label
              htmlFor="newPasswordRepeat"
              style={{ width: "100%", marginRight: "10px" }}
            >
              <p
                style={{
                  fontSize: "11px",
                  color: "#FFF",
                  fontWeight: "400",
                  textAlign: "start",
                  margin: "2px 2px 0 0 ",
                }}
              >
                Confirmar nueva contraseña
              </p>
              <input
                onChange={props.onChangeNewRepeat}
                id="newPasswordRepeat"
                name="newPasswordRepeat"
                type="password"
                value={props.passwordData?.newPasswordRepeat}
                style={{
                  border: "0.5px solid rgba(255,255,255,0.2)",
                  borderRadius: "6px",
                  background: "rgba(255,255,255,0.05)",
                  padding: "9px 0px 9px 0px",
                  minHeight: "24px",
                  fontSize: "12px",
                  fontWeight: "600",
                  textAlign: "center",
                  color: "#FFF",
                  width: "100%",
                }}
              />

              <p
                style={{
                  fontSize: "10px",
                  color: "rgba(255, 105, 105, 0.7)",
                  fontWeight: "400",
                  textAlign: "end",
                  margin: "2px 2px 0 0",
                  visibility: props.passwordDataError.newPasswordRepeatError
                    ? "visible"
                    : "hidden",
                }}
              >
                {props.passwordDataError.newPasswordRepeatError}
              </p>
            </label>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "18px",
                marginRight: "16px",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  props.cancelChanges();
                }}
                style={{
                  width: "115px",
                  padding: "5px 0px 5px 0px",
                  textAlign: "center",
                  color: "#FFF",
                  background: "rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  border: "none",
                  maxWidth: "120px",
                }}
              >
                Cancelar
              </button>

              <button
                disabled={buttonDisabled()}
                onClick={(e) => {
                  e.preventDefault();
                  props.confirmChanges();
                }}
                style={{
                  width: "115px",
                  padding: "5px 0px 5px 0px",
                  textAlign: "center",
                  color: "#FFF",
                  background: "rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  border: "none",
                  maxWidth: "120px",
                }}
              >
                {props.confirmLoading === "pending" ? (
                  <ClipLoader color={"#FFF"} size={"25px"} />
                ) : (
                  <>
                    Cambiar <br />
                    contraseña
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
