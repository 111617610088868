import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import "../magicFormsStyles.scss";
import {
  changeActiveComponent,
  changePageState,
  getFilteredTemplates,
  selectActiveMagicComponent,
  selectCurrentNumPages,
  selectCurrentPageMagic,
  selectTemplatesLoading,
  selectTemplatesMagic,
} from "../magicFormsSlice";
import ClipLoader from "react-spinners/ClipLoader";

export const TemplatesComponent = () => {
  let currentPage = useAppSelector(selectCurrentPageMagic);
  let currentNumPages = useAppSelector(selectCurrentNumPages);
  let loadingStatus = useAppSelector(selectTemplatesLoading);
  let activeComponent = useAppSelector(selectActiveMagicComponent);
  let templates = useAppSelector(selectTemplatesMagic);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  useEffect(() => {
    if (activeComponent !== "Templates") {
      dispatch(changeActiveComponent("Templates"));
    }

    dispatch(getFilteredTemplates());
    return () => {};
  }, [currentPage, activeComponent]);
  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {templates && templates.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "20%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Tipo de documentos
            </div>
          </div>

          <div className="flex-col" style={{ height: "70%" }}>
            <div style={{ overflow: "auto" }}>
              <table
                style={{
                  width: "98%",
                  borderCollapse: "collapse",
                  marginLeft: "1%",
                }}
              >
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Id Documento</th>
                    <th>Descripcion</th>
                    <th>Nombre</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {templates.map((template, i) => {
                    return (
                      <tr
                        key={i}
                        className="list-hover"
                        style={{ height: "30px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `/services/magicForms/templates/${template.id}`
                          );
                        }}
                      >
                        <td></td>
                        <td>{template.id}</td>
                        <td>{template.name}</td>
                        <td>{template.nombre}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending" || loadingStatus === "idle"
              ? ""
              : "No hay más documentos en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
