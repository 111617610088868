import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  getAuthImages,
  resetImageLoading,
  selectAuthImages,
  selectCurrentSigner,
  selectImageLoading,
} from "../firmaNewSlice";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { SmallSquare } from "../../firma/detail/components/Squares";
import { getStatusName } from "../../firma/helpers/utils";
import { formatDateStandar } from "../../../../helpers/utils";
import { FirmarImagesNew } from "./components/FirmarImagesNew";

export const SignerDetail = () => {
  let currentSigner = useAppSelector(selectCurrentSigner);
  let loadingImage = useAppSelector(selectImageLoading);
  let authImages = useAppSelector(selectAuthImages);
  let dispatch = useAppDispatch();

  let { signerId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (currentSigner && loadingImage === "idle") {
      dispatch(getAuthImages());
    }

    dispatch(changeActiveComponent("none"));

    return () => {};
  }, [signerId, currentSigner]);
  return (
    <div style={{ height: "100%" }}>
      {currentSigner && currentSigner.signerId !== "" ? (
        <div className="flex-col" style={{ height: "100%", overflow: "auto" }}>
          <div
            className="flex-row"
            style={{
              height: "12%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetImageLoading());
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar a la firma
              </div>
            </div>
          </div>
          <div
            className="flex-col"
            style={{
              height: "85%",
              width: "100%",
              justifyContent: "start",
              padding: "1%",
            }}
          >
            <div
              className="flex-row"
              style={{
                justifyContent: "start",
                height: "15%",
              }}
            >
              <SmallSquare
                parentWidth="23%"
                title="Estado de la firma"
                content={<div>{getStatusName(currentSigner.status)}</div>}
              />
              <SmallSquare
                parentWidth="23%"
                title="Fecha de la firma"
                content={
                  <div>{formatDateStandar(currentSigner.startedDate)}</div>
                }
              />
              <SmallSquare
                parentWidth="23%"
                title="Alertas"
                content={
                  <div>
                    {currentSigner.numAlerts === 0
                      ? "Ninguna"
                      : currentSigner.numAlerts}
                  </div>
                }
              />
              <SmallSquare
                parentWidth="23%"
                title="Método de verificación"
                content={<div>{currentSigner.validationMethod}</div>}
              />
            </div>
            <div
              className="flex-row"
              style={{
                height: "65%",
                justifyContent: "start",
              }}
            >
              <div
                className="flex-col"
                style={{ width: "47%", marginRight: "1%" }}
              >
                <div
                  className="flex-col text-medium text-normal primary-color-35"
                  style={{
                    marginTop: "3%",
                    background: "rgba(0,0,0,0.4)",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    height: "8%",
                  }}
                >
                  Datos del usuario
                </div>
                <div
                  className="flex-col text-medium text-normal primary-color"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    height: "85%",
                    marginBottom: "3%",
                    boxSizing: "border-box",
                    padding: "5%",
                  }}
                >
                  {currentSigner && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        Nombre :
                      </div>
                      <div className="text-normal text-medium primary-color">
                        {currentSigner.name}
                      </div>
                    </div>
                  )}
                  {currentSigner.lastName && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        Apellido :
                      </div>
                      <div className="text-normal text-medium primary-color">
                        {currentSigner.lastName}
                      </div>
                    </div>
                  )}
                  {currentSigner.identification && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        Identificación :
                      </div>
                      <div className="text-normal text-medium primary-color">
                        {currentSigner.identification}
                      </div>
                    </div>
                  )}
                  {currentSigner.identificationType && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        Identificación :
                      </div>
                      <div className="text-normal text-medium primary-color">
                        {currentSigner.identificationType}
                      </div>
                    </div>
                  )}
                  {currentSigner.userId && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        Identidad Digital No. :
                      </div>
                      <div
                        className="text-normal text-medium hover-link greyish"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            `/services/coreId/registrations/${currentSigner.userId}`
                          );
                        }}
                      >
                        {currentSigner.userId}
                      </div>
                    </div>
                  )}
                  {currentSigner.status && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        Estado :
                      </div>
                      <div className="text-normal text-medium primary-color">
                        {getStatusName(currentSigner.status)}
                      </div>
                    </div>
                  )}
                  {currentSigner.email && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        E-mail :
                      </div>
                      <div className="text-normal text-medium primary-color">
                        {currentSigner.email}
                      </div>
                    </div>
                  )}
                  {currentSigner.phoneNumber && (
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div className="text-normal text-medium primary-color">
                        No. de celular :
                      </div>
                      <div className="text-normal text-medium primary-color">
                        {currentSigner.phoneNumber}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-col" style={{ width: "47%" }}>
                <div
                  className="flex-col text-medium text-normal primary-color-35"
                  style={{
                    marginTop: "3%",
                    background: "rgba(0,0,0,0.4)",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    height: "8%",
                  }}
                >
                  Imagen del firmante
                </div>
                <div
                  className="flex-col text-medium text-normal primary-color"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    height: "85%",
                    marginBottom: "3%",
                    boxSizing: "border-box",
                    padding: "5%",
                  }}
                >
                  <FirmarImagesNew
                    currentImages={authImages}
                    verificationMethod={currentSigner.validationMethod}
                    loadingImages={loadingImage}
                    userId={currentSigner.userId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            No se ha seleccionado a un firmante
          </h1>
        </div>
      )}
    </div>
  );
};
