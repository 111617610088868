import React, { useState } from "react";
import { ReactComponent as DownMenu } from "../../../../../../Assets/common/images/downMenu.svg";
import { ReactComponent as UpMenu } from "../../../../../../Assets/common/images/upMenu.svg";
import { RadioButtonMedium } from "../../../../../common/components/Btns/RadioButton";

export interface ChooseStateProps {
  status: string;
  onConfirm: (status: "ACCEPTED" | "REJECTED" | "REVISION" | "") => void;
}

export const ChooseState = (props: ChooseStateProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [radioStatus, setRadioStatus] = useState<
    "ACCEPTED" | "REJECTED" | "REVISION" | ""
  >("");

  const getStatusName = () => {
    switch (props.status) {
      case "ACCEPTED":
        return "Aprobado";

      case "IN_PROGRESS":
        return "En proceso";
      case "REJECTED":
        return "Rechazado";
      case "REVISION":
        return "Por revisar";
      default:
        return "";
    }
  };

  if (props.status === "IN_PROGRESS") {
    return <div></div>;
  } else {
    return (
      <div
        style={{
          marginTop: "8%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4
          style={{
            textTransform: "uppercase",
            color: "rgba(255,255,255,0.3)",
            fontSize: "9px",
            textAlign: "center",
          }}
        >
          | Menú desplegable |{" "}
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "95%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
              border: "none",
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: "rgba(0,0,0,0.2)",
              marginBottom: "1px",
            }}
          >
            <h4
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "700",
                color: "rgba(255,255,255,0.35)",
                marginBottom: "7px",
              }}
            >
              Definiendo estado
            </h4>
            <h4
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "700",
                color: props.status === "REVISION" ? "#C7FF4F" : "white",
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
            >
              {getStatusName()}
            </h4>
          </div>
          {isExpanded && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: "1px",
                backgroundColor: "rgba(0,0,0,0.2)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5%",
                  marginRight: "5%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    width: "70%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "white",
                    }}
                  >
                    Aceptar
                  </h6>
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "11px",
                      fontWeight: "600",
                      color: "rgba(255,255,255,0.4)",
                    }}
                  >
                    Al seleccionar esta casilla usted aprobará a la persona
                    descrita en el registro
                  </p>
                </div>
                <RadioButtonMedium
                  label=""
                  checked={radioStatus === "ACCEPTED"}
                  onClick={() => {
                    if (radioStatus === "ACCEPTED") {
                      setRadioStatus("");
                    }
                  }}
                  onChange={() => {
                    if (radioStatus === "ACCEPTED") {
                      setRadioStatus("");
                    } else {
                      setRadioStatus("ACCEPTED");
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5%",
                  marginRight: "5%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    width: "70%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "white",
                    }}
                  >
                    Rechazar
                  </h6>
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "11px",
                      fontWeight: "600",
                      color: "rgba(255,255,255,0.4)",
                    }}
                  >
                    Al seleccionar esta casilla usted rechazará este proceso
                  </p>
                </div>
                <RadioButtonMedium
                  label=""
                  checked={radioStatus === "REJECTED"}
                  onClick={() => {
                    if (radioStatus === "REJECTED") {
                      setRadioStatus("");
                    }
                  }}
                  onChange={() => {
                    if (radioStatus === "REJECTED") {
                      setRadioStatus("");
                    } else {
                      setRadioStatus("REJECTED");
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5%",
                  marginRight: "5%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    width: "70%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "white",
                    }}
                  >
                    A revisar
                  </h6>
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "11px",
                      fontWeight: "600",
                      color: "rgba(255,255,255,0.4)",
                    }}
                  >
                    Al seleccionar esta casilla usted pondra este registro a
                    revisión
                  </p>
                </div>
                <RadioButtonMedium
                  label=""
                  checked={radioStatus === "REVISION"}
                  onClick={() => {
                    if (radioStatus === "REVISION") {
                      setRadioStatus("");
                    }
                  }}
                  onChange={() => {
                    if (radioStatus === "REVISION") {
                      setRadioStatus("");
                    } else {
                      setRadioStatus("REVISION");
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "none",
              borderRadius: "0px 0px 8px 8px",
              paddingTop: "15px",
              paddingBottom: "15px",
              backgroundColor: "rgba(0,0,0,0.2)",
            }}
          >
            {isExpanded ? (
              <>
                <button
                  disabled={radioStatus === ""}
                  onClick={(e) => {
                    e.preventDefault();
                    props.onConfirm(radioStatus);
                  }}
                  style={{
                    width: "50%",
                    padding: "6px",
                    border: "none",
                    borderRadius: "6px",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    color:
                      radioStatus === "" ? "rgba(255,255,255,0.5)" : "white",
                    fontSize: "12px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  Confirmar cambio de estado del registro
                </button>
                <UpMenu
                  onClick={(e) => {
                    e.preventDefault();
                    setIsExpanded(!isExpanded);
                  }}
                  style={{ position: "absolute", bottom: "-7px" }}
                />
              </>
            ) : (
              <div
                style={{ width: "100%", cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsExpanded(!isExpanded);
                }}
              >
                {" "}
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "rgba(255,255,255,0.6)",
                  }}
                >
                  Cambiar estado
                </h4>
                <DownMenu
                  onClick={(e) => {
                    e.preventDefault();
                    setIsExpanded(!isExpanded);
                  }}
                  style={{ position: "absolute", bottom: "-7px", left: "47%" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};
