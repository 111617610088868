import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  changeCurrentQuery,
  selectDatos,
  getSearch,
  selectBusquedaLoading,
} from "./BusquedaSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { formatDateStandar } from "../../../helpers/utils";
import {
  changeActiveComponent,
  selectActiveCoreIdComponent,
} from "../../services/coreid/coreIdSlice";
import Modal, { Styles } from "react-modal";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const ListBusquedas = () => {
  let loading = useAppSelector(selectBusquedaLoading);
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);
  let datos = useAppSelector(selectDatos);
  const [modalIsOpen, setIsOpen] = useState(true);
  const [searchB, setSearchB] = useState("");

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let { query } = useParams();

  const closeModal = () => {
    setIsOpen(false);
  };
  const getRowData = (reg: any, type: string, i: number) => {
    var id = "";
    var fecha = "";
    var userId = "";
    var path = "";

    switch (type) {
      case "Core ID":
        id = reg.id;
        fecha = reg.startDatetime;
        userId = reg.userId;
        path = `/services/coreId/registrations/${id}`;
        break;
      case "Magic Forms":
        id = reg.id;
        fecha = reg.startDatetime;
        userId = reg.userId;
        path = `/services/magicForms/filledForms/${id}`;
        break;
      case "Firma":
        id = reg.userId;
        fecha = reg.dateTime;
        userId = reg.userId;
        path = `/services/electronicSignatureNew/signatures/${id}`;
        break;
      case "HyperFlow":
        id = reg.hyperFlowId;
        userId = reg.userId;
        path = `/services/hyperFlow/flows/${id}`;
        break;
      default:
        break;
    }
    return (
      <tr
        key={i}
        className="list-hover"
        style={{
          height: "30px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.preventDefault();
          navigate(path);
        }}
      >
        <td></td>
        <td style={{ textTransform: "uppercase" }}>{type}</td>
        <td>{id}</td>
        <td>{fecha}</td>
        <td> {userId}</td>
        <td>
          <div
            className="text-medium text-bold primary-color"
            style={{
              marginTop: "auto",
              marginRight: "2%",
              marginLeft: "auto",
              background: "rgba(0,0,0,0.4)",
              paddingRight: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "30px",
              borderRadius: "8px",
              textAlign: "center",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate(path);
            }}
          >
            Ver
          </div>
        </td>
        <td></td>
      </tr>
    );
  };
  useEffect(() => {
    var data = {
      query: query,
    };
    dispatch(changeCurrentQuery(data));

    if (query && query !== "" && (searchB == "" || searchB != query)) {
      setSearchB(query);
      dispatch(getSearch(data));
      if (
        datos.coreIdResults.length == 0 &&
        datos.elecSigResult.length == 0 &&
        datos.hyperFlowResult.length == 0 &&
        datos.magicFormsResult.length == 0
      ) {
        setIsOpen(true);
      }
    }

    dispatch(changeActiveComponent("none"));
  }, [searchB, datos, query, modalIsOpen]);
  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {searchB != "" &&
        datos.coreIdResults.length == 0 &&
        datos.elecSigResult.length == 0 &&
        datos.hyperFlowResult.length == 0 &&
        datos.magicFormsResult.length == 0 && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStylesModal}
            contentLabel="Error de búsqueda"
            shouldCloseOnOverlayClick={true}
          >
            <div
              className="flex-col"
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                className="text-medium text-normal"
                style={{
                  color: "black",
                  marginBottom: "10px",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                }}
              >
                Solicitud ingresada no encontrada
              </div>
              <button
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  closeModal();
                }}
              >
                Intente nuevamente
              </button>
            </div>
          </Modal>
        )}
      {datos &&
      (datos.coreIdResults.length > 0 ||
        datos.elecSigResult.length > 0 ||
        datos.hyperFlowResult.length > 0 ||
        datos.magicFormsResult.length > 0) ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "13%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              {` Búsqueda ${query}`}
            </div>
          </div>
          <div
            className="flex-row"
            style={{
              height: "15%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginLeft: "20px",
              marginRight: "20px",
              marginBottom: "2%",
            }}
          >
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Registros CoreId
              </div>
              <div className="text-huge text-bold primary-color">
                {datos.coreIdResults.length}
              </div>
            </div>
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Registros Magic Form
              </div>
              <div className="text-huge text-bold primary-color">
                {datos.magicFormsResult.length}
              </div>
            </div>
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Registros Firmas
              </div>
              <div className="text-huge text-bold primary-color">
                {datos.elecSigResult.length}
              </div>
            </div>
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Registros HyperFlow
              </div>
              <div className="text-huge text-bold primary-color">
                {datos.hyperFlowResult.length}
              </div>
            </div>
          </div>
          {datos ? (
            <div className="flex-col" style={{ height: "60%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Servicio</th>
                      <th>ID</th>
                      <th>Fecha de Creación</th>
                      <th>UserId</th>
                      <th>Ver Detalle</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {datos.coreIdResults.map((reg, i) => {
                      return getRowData(reg, "Core ID", i);
                    })}
                    {datos.magicFormsResult.map((reg, i) => {
                      return getRowData(reg, "Magic Forms", i);
                    })}
                    {datos.elecSigResult.map((reg, i) => {
                      return getRowData(reg, "Firma", i);
                    })}
                    {datos.hyperFlowResult.map((reg, i) => {
                      return getRowData(reg, "HyperFlow", i);
                    })}

                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
                No hay más registros en esta página
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          ></div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "pending" || loading === "idle"
              ? ""
              : "No hay más registros en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
