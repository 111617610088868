import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PasswordData } from "../configurationAPI";
import Modal, { Styles } from "react-modal";
import {
  changeAccountLoading,
  changeActiveComponent,
  changechangeRolesLoading,
  changeCurrentData,
  changeCurrentDataError,
  changeLoadingEditOwnInfo,
  changeLoadingResetPassword,
  changeOwnInfo,
  changeOwnRoles,
  changePasswordData,
  changePasswordErrorData,
  getAccountData,
  getCurrentRoles,
  getRoleOptions,
  resetOwnPassword,
  selectAccountData,
  selectAccountDataError,
  selectActiveConfigurationComponent,
  selectChangeOwnInfoLoading,
  selectChangeRolesLoading,
  selectCurrentRoles,
  selectCurrentRolesLoading,
  selectLoadingAccount,
  selectPasswordChangeLoading,
  selectPasswordData,
  selectPasswordDataError,
  selectResetOwnPasswordLoading,
  selectRoleOptions,
  selectRoleOptionsLoading,
  setCurrentRoles,
} from "../configurationSlice";
import { AccountView } from "./components/AccountView";
import { ChangeAccountPassword } from "./components/ChangeAccountPassword";
import { RolesComponent } from "./components/RolesComponent";
import { selectRoles, setRoles } from "../../common/commonSlice";
import { useCookies } from "react-cookie";
import { addHours } from "date-fns";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const Account = () => {
  let dispatch = useAppDispatch();

  let loadingAccount = useAppSelector(selectLoadingAccount);
  let userData = useAppSelector(selectAccountData);
  let userDataError = useAppSelector(selectAccountDataError);
  let passwordData = useAppSelector(selectPasswordData);
  let passwordDataError = useAppSelector(selectPasswordDataError);
  let loadingPasswordChange = useAppSelector(selectPasswordChangeLoading);
  let roleOptionsLoading = useAppSelector(selectRoleOptionsLoading);
  let roleOptions = useAppSelector(selectRoleOptions);
  let currentRoles = useAppSelector(selectCurrentRoles);
  let currentRolesLoading = useAppSelector(selectCurrentRolesLoading);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let resetPasswordLoading = useAppSelector(selectResetOwnPasswordLoading);
  let changeInfoLoading = useAppSelector(selectChangeOwnInfoLoading);
  let changeRolesLoading = useAppSelector(selectChangeRolesLoading);
  let roles = useAppSelector(selectRoles);

  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);
  const [modalChangeInfoIsOpen, setModalChangeInfoIsOpen] = useState(false);
  const [modalChangeRolesIsOpen, setModalChangeRolesIsOpen] = useState(false);

  const [_, setCookie] = useCookies(["token"]);

  const closePasswordModal = () => {
    setModalPasswordIsOpen(false);
  };

  const closeModalChangeInfo = () => {
    setModalChangeInfoIsOpen(false);
  };

  const closeModalChangeRoles = () => {
    setModalChangeRolesIsOpen(false);
  };

  const rolesVisible = () => {
    if (roles.includes("users.admin") || roles.includes("users.edit")) {
      return "visible";
    } else {
      return "hidden";
    }
  };

  const checkPasswordErrors = (passwordData: PasswordData) => {
    let oldPasswordError = "";
    let newPasswordError = "";
    let newPasswordRepeatError = "";

    if (passwordData.oldPassword.length === 0) {
      oldPasswordError = "Debes ingresar una contraseña";
    } else {
      oldPasswordError = "";
    }

    if (
      passwordData.newPassword.length < 8 &&
      passwordData.newPassword.length !== 0
    ) {
      newPasswordError = "Debe ser de 8 dígitos o más";
    } else if (
      passwordData.newPassword.length >= 8 &&
      passwordData.newPasswordRepeat.length >= 8
    ) {
      if (passwordData.newPassword !== passwordData.newPasswordRepeat) {
        newPasswordError = "Las contraseñas deben ser iguales";
        newPasswordRepeatError = "Las contraseñas deben ser iguales";
      } else {
        newPasswordError = "";
        newPasswordRepeatError = "";
      }
    } else {
      newPasswordError = "";
    }

    if (
      passwordData.newPasswordRepeat.length < 8 &&
      passwordData.newPasswordRepeat.length !== 0
    ) {
      newPasswordRepeatError = "Debe ser de 8 dígitos o más";
    } else if (
      passwordData.newPassword.length >= 8 &&
      passwordData.newPasswordRepeat.length >= 8
    ) {
      if (passwordData.newPassword !== passwordData.newPasswordRepeat) {
        newPasswordError = "Las contraseñas deben ser iguales";
        newPasswordRepeatError = "Las contraseñas deben ser iguales";
      } else {
        newPasswordError = "";
        newPasswordRepeatError = "";
      }
    } else {
      newPasswordRepeatError = "";
    }

    dispatch(
      changePasswordErrorData({
        newPasswordError,
        newPasswordRepeatError,
        oldPasswordError,
      })
    );
  };

  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    if (activeComponent !== "Account") {
      dispatch(changeActiveComponent("Account"));
    }
    if (!userData && loadingAccount === "idle") {
      dispatch(getAccountData());
    }
    if (roleOptions.length === 0 && roleOptionsLoading === "idle") {
      dispatch(getRoleOptions());
    }
    if (currentRoles.length === 0 && currentRolesLoading === "idle") {
      dispatch(getCurrentRoles());
    }

    if (resetPasswordLoading === "resolved") {
      setModalPasswordIsOpen(true);
    }

    if (changeInfoLoading === "resolved") {
      setModalChangeInfoIsOpen(true);
    }

    if (changeRolesLoading === "resolved" && userData?.generatedToken) {
      let now = new Date();
      let inAnHour = addHours(now, 1);
      setCookie(
        "token",
        { token: userData?.generatedToken, expiry: inAnHour.getTime() },
        {
          path: "/",
          expires: inAnHour,
          sameSite: "lax",
          secure: true,
        }
      );
      setModalChangeRolesIsOpen(true);
    }
  }, [resetPasswordLoading, changeRolesLoading]);
  if (loadingAccount === "idle") {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1%",
        }}
      >
        <ClipLoader color="#FFF" size={"200px"} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          padding: "1%",
        }}
      >
        <Modal
          isOpen={modalPasswordIsOpen}
          onRequestClose={closePasswordModal}
          style={customStylesModal}
          contentLabel="Restablecimiento de contraseña"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="text-medium text-normal">
                Se ha cambiado la contraseña de manera exitosa
              </h5>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                setModalPasswordIsOpen(false);
                dispatch(changeLoadingResetPassword("idle"));
                setChangePassword(false);
                dispatch(
                  changePasswordData({
                    newPassword: "",
                    newPasswordRepeat: "",
                    oldPassword: "",
                  })
                );
                dispatch(
                  changePasswordErrorData({
                    newPasswordError: "",
                    newPasswordRepeatError: "",
                    oldPasswordError: "",
                  })
                );
              }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalChangeInfoIsOpen}
          onRequestClose={closeModalChangeInfo}
          style={customStylesModal}
          contentLabel="Cambió información"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="text-medium text-normal">
                Se ha cambiado la información de manera exitosa
              </h5>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                setModalPasswordIsOpen(false);
                dispatch(changeLoadingEditOwnInfo("idle"));
                setChangePassword(false);
                dispatch(
                  changeCurrentDataError({
                    emailError: "",
                    identificationError: "",
                    nameError: "",
                    phoneNumberError: "",
                  })
                );
                dispatch(changeAccountLoading("idle"));
                dispatch(changeCurrentData(null));
              }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalChangeRolesIsOpen}
          onRequestClose={closeModalChangeRoles}
          style={customStylesModal}
          contentLabel="Cambió roles"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="text-medium text-normal">
                Se han cambiado los roles de manera exitosa
              </h5>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(setRoles(currentRoles));
                dispatch(changechangeRolesLoading("idle"));
                dispatch(changeAccountLoading("idle"));
                dispatch(changeCurrentData(null));
                closeModalChangeRoles();
              }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
        {userData && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "1%",
            }}
          >
            <AccountView
              changePassword={() => setChangePassword(!changePassword)}
              confirmChanges={() => {
                dispatch(changeOwnInfo());
              }}
              errorAccountData={userDataError}
              accountData={userData}
              width="48%"
              loading={loadingAccount}
              changeUserData={changeCurrentData}
              changeErrorData={changeCurrentDataError}
            />
            <RolesComponent
              width="48%"
              currentRoles={currentRoles}
              cancelChangesFunction={() => {
                dispatch(setCurrentRoles(userData?.roles!));
              }}
              changeRolesFunction={() => {
                dispatch(changeOwnRoles());
              }}
              immutableRoles={userData?.roles!}
              predeterminedRoles={roleOptions}
              loading={roleOptionsLoading}
              visibility={rolesVisible()}
            />
          </div>
        )}
        {changePassword && (
          <ChangeAccountPassword
            loading={loadingPasswordChange}
            onChangeCurrent={(e) => {
              dispatch(
                changePasswordData({
                  ...passwordData,
                  oldPassword: e.target.value,
                })
              );
              checkPasswordErrors({
                ...passwordData,
                oldPassword: e.target.value,
              });
            }}
            onChangeNew={(e) => {
              dispatch(
                changePasswordData({
                  ...passwordData,
                  newPassword: e.target.value,
                })
              );
              checkPasswordErrors({
                ...passwordData,
                newPassword: e.target.value,
              });
            }}
            onChangeNewRepeat={(e) => {
              dispatch(
                changePasswordData({
                  ...passwordData,
                  newPasswordRepeat: e.target.value,
                })
              );
              checkPasswordErrors({
                ...passwordData,
                newPasswordRepeat: e.target.value,
              });
            }}
            cancelChanges={() => {
              setChangePassword(false);
              dispatch(
                changePasswordData({
                  newPassword: "",
                  newPasswordRepeat: "",
                  oldPassword: "",
                })
              );
              dispatch(
                changePasswordErrorData({
                  newPasswordError: "",
                  newPasswordRepeatError: "",
                  oldPasswordError: "",
                })
              );
            }}
            confirmChanges={() => {
              dispatch(resetOwnPassword());
            }}
            passwordData={passwordData}
            passwordDataError={passwordDataError}
            width={"48%"}
            confirmLoading={resetPasswordLoading}
          />
        )}
      </div>
    );
  }
};
