import React from "react";
import { AlertSeverity } from "../alertsSlice";

export interface AlertDotProps {
  color?: string;
  levelAlert?: AlertSeverity | string;
}

export const AlertDot = (props: AlertDotProps) => {
  const getLevelColor = (level?: AlertSeverity | string) => {
    switch (level) {
      case "HIGH":
        return "#FF5C5C";
      case "MEDIUM":
        return "#F7CD00";
      case "LOW":
        return "#C7FF4F";
      case "OTHER":
        return "#66BFFF";
      default:
        return "transparent";
    }
  };

  return (
    <div
      style={{
        height: "14px",
        width: "14px",
        borderRadius: "9999px",
        background: props.color ? props.color : getLevelColor(props.levelAlert),
        margin: "3px 0 0 0",
      }}
    ></div>
  );
};
