import React from "react";
import { ReactComponent as LeftMenu } from "../../../../../Assets/common/images/leftMenu.svg";
import { ReactComponent as RightMenu } from "../../../../../Assets/common/images/rightMenu.svg";
import "./categoryStyles.scss";

export interface CategoryProp {
  name: string;
  last?: boolean;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const Category = (props: CategoryProp) => {
  return (
    <button
      onClick={props.onClick}
      className="flex-row text-medium text-bold primary-color"
      style={{
        border: "none",
        height: "40px",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1px",
        background: "rgba(0,0,0,0.2)",
        boxSizing: "border-box",
        paddingLeft: "18%",
        paddingRight: "10%",
        borderRadius: props.last ? "0px 0px 8px 8px" : "",
      }}
    >
      <div className="text-medium text-bold primary-color">{props.name}</div>
      <div
        className="flex-col"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        {props.selected ? <RightMenu /> : <LeftMenu />}
      </div>
    </button>
  );
};
