import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { DropDown } from "../../common/components/Btns/DropDown";
import {
  changeMagicformsFilters,
  resetStateAnalytics,
  selectAnalyticsMagicFormsRequest,
} from "../analytics/analyticsSlice";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import {
  changeActiveComponent,
  changeCurrentFilters,
  changeEndDate,
  changeStartDate,
  getFilterOptions,
  selectActiveMagicComponent,
  selectEndDateMagic,
  selectFilteredFormRequest,
  selectFilterOptionsFilledForms,
  selectStartDateMagic,
} from "./magicFormsSlice";
import "./magicFormsStyles.scss";
import { selectCurrentTagsByService } from "../tags/tagSlice";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";

export const MagicForms = () => {
  let activeComponent = useAppSelector(selectActiveMagicComponent);
  let startDate = useAppSelector(selectStartDateMagic);
  let endDate = useAppSelector(selectEndDateMagic);
  let currentFilteredRequest = useAppSelector(selectFilteredFormRequest);
  let roles = useAppSelector(selectRoles);
  let filterOptionsFilledforms = useAppSelector(selectFilterOptionsFilledForms);
  let magicFormsAnalyticsRequest = useAppSelector(
    selectAnalyticsMagicFormsRequest
  );

  let currentServiceTags = useAppSelector(selectCurrentTagsByService);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let tagsDropdown = <></>;
    if (activeComponent === "Filledforms") {
      const searchInput = (
        <SearchInputFilter
          value={currentFilteredRequest.searchQuery}
          key={"SearchMagicForms"}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: null,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: e.target.value,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
          placeHolder="No. Identidad"
        />
      );
      if (roles.includes("tags.view")) {
        let tagsOptions: { value: string; label: string }[] = [];
        let tags = currentServiceTags;
        if (tags) {
          for (const tag of tags) {
            let label = tag.tag;
            let value = tag.id;
            if (tag.associatedEntities.length > 0) {
              tagsOptions.push({ label: label!, value });
            }
          }
        }
        tagsDropdown = (
          <DropDown
            key={"Tags"}
            title="Etiqueta"
            options={tagsOptions}
            onChange={(e) => {
              if (e) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    tagId: e?.value!,
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    tagId: null,
                  })
                );
              }
            }}
          />
        );
      }
      let templateOptions: { value: string; label: string }[] = [];
      let templates = filterOptionsFilledforms?.templates;
      if (templates) {
        for (const template in templates) {
          let label = templates[template];
          let value = template;
          templateOptions.push({ label, value });
        }
      }
      let templateDropdown = (
        <DropDown
          key={"Tipo de documento"}
          title="Tipo de documento"
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                templateId: e?.value!,
              })
            );
          }}
          options={templateOptions}
        />
      );
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          endDate={endDate ? new Date(endDate) : null}
          startDate={startDate ? new Date(startDate) : null}
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(changeEndDate(date.toISOString()));
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(changeStartDate(date.toISOString()));
              }
            }
          }}
          resetFunction={() => {
            dispatch(changeStartDate(null));
            dispatch(changeEndDate(null));
          }}
          items={[
            templateDropdown,
            tagsDropdown,
            <div
              key={0}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por identificación
            </div>,
            searchInput,
          ]}
        />
      );
    } else if (activeComponent === "Templates") {
      return;
    } else if (activeComponent === "Analytics") {
      let templateOptions: { value: string; label: string }[] = [];
      let templates = filterOptionsFilledforms?.templates;
      if (templates) {
        for (const template in templates) {
          let label = templates[template];
          let value = template;
          templateOptions.push({ label, value });
        }
      }
      let templateDropdown = (
        <DropDown
          key={"Tipo de documento"}
          title="Tipo de documento"
          onChange={(e) => {
            dispatch(
              changeMagicformsFilters({
                ...magicFormsAnalyticsRequest,
                templateId: e?.value!,
              })
            );
          }}
          options={templateOptions}
        />
      );
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          calendarType="double"
          endDate={
            magicFormsAnalyticsRequest.endDate
              ? new Date(magicFormsAnalyticsRequest.endDate)
              : null
          }
          startDate={
            magicFormsAnalyticsRequest.startDate
              ? new Date(magicFormsAnalyticsRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeMagicformsFilters({
                    ...magicFormsAnalyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
                dispatch(
                  changeMagicformsFilters({
                    ...magicFormsAnalyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeMagicformsFilters({
                    ...magicFormsAnalyticsRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          items={[templateDropdown]}
        />
      );
    } else {
      return;
    }
  };

  const getCategoryItems = () => {
    let categoryItems: JSX.Element[] = [];
    categoryItems.push(
      <Category
        key={0}
        name="Formularios Realizados"
        selected={activeComponent === "Filledforms"}
        onClick={(e) => {
          e.preventDefault();
          navigate("/services/magicForms/filledForms");
        }}
      />,
      <Category
        key={1}
        name="Tipo de documentos"
        last
        selected={activeComponent === "Templates"}
        onClick={(e) => {
          e.preventDefault();
          navigate("/services/magicForms/templates");
        }}
      />
    );
    if (
      roles.includes("analytics.admin") ||
      roles.includes("analytics.magicforms.view")
    ) {
      categoryItems.push(
        <Category
          key={2}
          name="Analitica"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Magic Forms"));
            dispatch(changeActiveComponent("Analytics"));
            dispatch(resetStateAnalytics());
            navigate("/services/magicForms/analytics");
          }}
          selected={activeComponent === "Analytics"}
        />
      );
    }

    return categoryItems;
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Magic Forms"));
    if (!filterOptionsFilledforms) {
      dispatch(getFilterOptions());
    }
  }, [activeComponent, filterOptionsFilledforms]);

  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
