import "../servicesCommonStyles.scss";
export interface LittleSquareProps {
  title: string;
  content?: React.ReactNode;
  styles?: React.CSSProperties | undefined;
}

export interface MediumSquareProps {
  title?: string;
  slider?: React.ReactNode;
  content?: React.ReactNode;
}

export const TitleSubtitleBox = (props: LittleSquareProps) => {
  return (
    <div style={props.styles}>
      <div
        className="flex-col"
        style={{
          background: "rgba(0,0,0,0.4)",
          height: "29px",
          textAlignLast: "center",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
          paddingTop: "9px",
          paddingBottom: "9px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div className="text-normal text-medium primary-color-35">
          {props.title}
        </div>
      </div>
      <div
        className="flex-col"
        style={{
          textAlign: "center",
          color: "white",
          background: "rgba(0,0,0,0.2)",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          paddingTop: "20px",
          paddingBottom: "20px",
          paddingLeft: "60px",
          paddingRight: "60px",
        }}
      >
        <div className="text-normal text-medium primary-color">
          {props.content}
        </div>
      </div>
    </div>
  );
};
