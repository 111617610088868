export const PagaresHelp = () => {
  return (
    <div
      style={{
        overflow: "auto",
        color: "white",
        padding: "2% 2%",
        width: "100%",
        height: "100%",
      }}
    >
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "52px",
        }}
      >
        Introducción
      </h2>
      <p>
        El servicio de Pagarés de anteia es una herramienta que permite la
        firma, custodia y administración de pagarés.
      </p>
      <p>
        Esta herramienta se divide en varias funcionalidades y secciones. Por
        este motivo estos manuales dividiran la información en dos:
      </p>
      <ul>
        <li>
          <strong>Custodia de pagarés:</strong> Esto hace referencia a la
          navegación de los pagarés, se explica como encontrar, revisar y
          descargar pagarés, así como entender la interpretación de la
          información presentada.
        </li>
        <li>
          <strong>Administración de pagarés:</strong> Esto hace referencia a la
          gestión de los pagarés, se explica como crear, diligenciar, endosar y
          administrar pagarés en general, esta es una guía más directa de como
          realizar acciones sobre el pagaré.
        </li>
      </ul>

      <h3
        style={{
          fontWeight: "bold",
          fontSize: "36px",
        }}
      >
        Custodia de pagarés
      </h3>
      <p>
        Este manual permite entender como navegar en la sección de pagarés e
        interpretar la información que se muestra en cada pagaré. Si se requiere
        información sobre como realizar acciones sobre el pagaré, puede
        consultar el manual de administración de pagarés más adelante.
      </p>
      <object
        data="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20CUSTODIA%20DE%20PAGAR%C3%89S.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Descarga alternativa:{" "}
          <a href="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20CUSTODIA%20DE%20PAGAR%C3%89S.pdf">
            Si no se ve puede dar click aquí para descargar el PDF
          </a>
        </p>
      </object>
      <h3
        style={{
          fontWeight: "bold",
          fontSize: "36px",
        }}
      >
        Administración de pagarés
      </h3>
      <p>
        Este manual permite entender como crear, diligenciar, endosar y realizar
        acciones sobre los pagarés, estas funcionalidades son avanzadas y solo
        deben ser realizadas por personas autorizadas, debido a que cada proceso
        tiene implicaciones legales y financieras.
      </p>
      <object
        data="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20ADMINISTRACI%C3%93N%20DE%20PAGAR%C3%89S.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Descarga alternativa:{" "}
          <a href="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20ADMINISTRACI%C3%93N%20DE%20PAGAR%C3%89S.pdf">
            Si no se ve puede dar click aquí para descargar el PDF
          </a>
        </p>
      </object>
    </div>
  );
};
