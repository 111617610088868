import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import Login from "./features/login/Login";
import { selectBackgroundImageUrl } from "./features/customization/customizationSlice";
import "./features/common/commonStyles.scss";
import { useCookies } from "react-cookie";

import { MagicForms } from "./features/services/magicforms/MagicForms";
import { Page404 } from "./features/Page404/Page404";
import { useEffect } from "react";
import {
  resetState,
  selectRoles,
  selectTokenExpiryTimestamp,
  setTokenInfo,
} from "./features/common/commonSlice";
import { TemplatesComponent } from "./features/services/magicforms/magicComponents/TemplatesComponent";
import { FilledFormsComponent } from "./features/services/magicforms/magicComponents/FilledForms";
import { FilledFormsDetail } from "./features/services/magicforms/detail/FilledFormsDetail";
import { TemplateDetail } from "./features/services/magicforms/detail/TemplateDetail";
import { Signatures } from "./features/services/firma/firmaComponents/Signatures";
import { CoreId } from "./features/services/coreid/CoreId";
import { Registrations } from "./features/services/coreid/coreIdComponents/Registrations";
import { DetailFirma } from "./features/services/firma/detail/FirmaDetail";
import { Registration } from "./features/services/coreid/detail/Registration";
import { UnifiedAlerts } from "./features/services/alerts/UnifiedAlerts";
import { CoreIdAlerts } from "./features/services/coreid/coreIdComponents/CoreIdAlerts";
import { FirmaElectronicaNew } from "./features/services/firmanew/FirmaElectronicaNew";
import { FirmasNew } from "./features/services/firmanew/firmaComponents/Firmas";
import { DetailFirmaNew } from "./features/services/firmanew/detail/FirmaNewDetail";
import { HyperFlow } from "./features/services/hyperFlow/HyperFlow";
import { SignerDetail } from "./features/services/firmanew/detail/SignerDetail";
import axiosApiInstance from "./helpers/interceptor";
import localforage from "localforage";
import { ConsultaManual } from "./features/services/coreid/coreIdComponents/ConsultaManual";
import { Introv2 } from "./features/intro/introd";
import { FlowsList } from "./features/services/hyperFlow/hyperFlowComponents/FlowsList";
//import { Flowv2 } from "./features/services/hyperFlow/detail/Flowv2";
import { HyperFlowDetail } from "./features/services/hyperFlow/HyperFlowDetail";
//import { Flowv3 } from "./features/services/hyperFlow/detail/Flowv3";
import { ListBusquedas } from "./features/services/components/ListBusquedas";
import { IntroSearch } from "./features/intro/introSearch";
import { Flowv2 } from "./features/services/hyperFlow/detail/Flowv2";
import { SmartFlow } from "./features/services/smartflow/SmartFlow";
import { Flows } from "./features/services/smartflow/smartFlowComponents/Flows";
import { Flow } from "./features/services/smartflow/detail/Flow";
import { Pagares } from "./features/services/pagares/Pagares";
import { PagaresList } from "./features/services/pagares/pagareComponents/PagaresList";
import { DetailPagare } from "./features/services/pagares/detail/PagareDetail";
import { PagareFill } from "./features/services/pagares/detail/PagareFill";
import { PagareEndoso } from "./features/services/pagares/detail/PagareEndoso";
import { PagareCreate } from "./features/services/pagares/detail/PagareCreate";
import { Configuration } from "./features/configuration/Configuration";
import { Account } from "./features/configuration/configurationComponents/Account";
import { Users } from "./features/configuration/configurationComponents/Users";
import { CreateUser } from "./features/configuration/configurationComponents/CreateUser";
import { UserDetail } from "./features/configuration/configurationComponents/UserDetail";
import { AnalyticsCoreId } from "./features/services/coreid/coreIdComponents/Analytics";
import { AnalyticsHyperFlow } from "./features/services/hyperFlow/hyperFlowComponents/Analytics";
import { AnalyticsMagicForms } from "./features/services/magicforms/magicComponents/Analytics";
import { AnalyticsFirma } from "./features/services/firmanew/firmaComponents/Analytics";
import { AnalyticsPagares } from "./features/services/pagares/pagareComponents/Analytics";
import { AnalyticsSmartFlow } from "./features/services/smartflow/smartFlowComponents/Analytics";
import { ResetPassword } from "./features/login/ResetPassword";
import { SubClients } from "./features/configuration/clients/Subclients";
import { SubClientDetail } from "./features/configuration/clients/SubClientDetail";
import { EditSubclient } from "./features/configuration/clients/EditSubclient";
import { CreateSubClient } from "./features/configuration/clients/CreateSubclient";
import { GeneralHelp } from "./features/configuration/help/GeneralHelp";
import { HyperflowHelp } from "./features/configuration/help/Hyperflow/HyperflowHelp";
import { LiveMonitoringPage } from "./features/services/liveMonitoring/LiveMonitoringPage";
import { ActionHistory } from "./features/configuration/configurationComponents/ActionHistory";
import { CoreIdHelp } from "./features/configuration/help/CoreId/CoreIdHelp";
import { MagicFormsHelp } from "./features/configuration/help/MagicForms/MagicFormsHelp";
import { PagaresHelp } from "./features/configuration/help/Pagares/PagaresHelp";
import { ConfiguracionHelp } from "./features/configuration/help/Configuracion/ConfiguracionHelp";
import { FirmaHelp } from "./features/configuration/help/Firma/FirmaHelp";
import { AuthentikatorProcessList } from "./features/services/authentikator/AuthentikatorProcessList";
import { AuthentikatorProcessDetail } from "./features/services/authentikator/AuthentikatorProcessDetail";
import { AuthentikatorSideBar } from "./features/services/authentikator/AuthentikatorSideBar";

function App() {
  let dispatch = useAppDispatch();
  let backgroundImage = useAppSelector(selectBackgroundImageUrl);
  let expiryTimestamp = useAppSelector(selectTokenExpiryTimestamp);
  let roles = useAppSelector(selectRoles);
  const [cookies, _, removeCookie] = useCookies(["token"]);
  let checkCookie = async () => {
    if (cookies.token && expiryTimestamp !== -1) {
      if (expiryTimestamp < new Date().getTime()) {
        dispatch(resetState());
        delete axiosApiInstance.defaults.headers.common["Authorization"];
        await localforage.clear();
        removeCookie("token", {
          path: "/",
          sameSite: "lax",
          secure: true,
        });
      }
    } else if (cookies.token && expiryTimestamp === -1) {
      dispatch(setTokenInfo(cookies.token));
    } else if (
      (!cookies || !cookies.token || cookies.token === "") &&
      expiryTimestamp !== -1
    ) {
      dispatch(resetState());
      delete axiosApiInstance.defaults.headers.common["Authorization"];
      await localforage.clear();
      if (cookies.token === "") {
        removeCookie("token", {
          path: "/",
          sameSite: "lax",
          secure: true,
        });
      }
    }
  };

  useEffect(() => {
    const intervalCookies = setInterval(() => {
      checkCookie();
    }, 1000);
    return () => clearInterval(intervalCookies);
  }, [cookies, expiryTimestamp]);
  return (
    <div
      style={{
        background: `url(${backgroundImage})`,
        height: "100vh",
        width: "100vw",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      }}
    >
      <Router>
        <Routes>
          <Route
            path='liveMonitoring'
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["Admin.liveMonitoring"],
              <LiveMonitoringPage />
            )}
          ></Route>
          <Route path='/resetPassword' element={<ResetPassword />}></Route>
          <Route
            path='/'
            element={
              cookies.token && cookies.token !== "" ? (
                <Navigate to='/intro' />
              ) : (
                <Navigate to='/login' />
              )
            }
          />
          <Route
            path='/login'
            element={
              !cookies.token || cookies.token === "" ? (
                <Login />
              ) : (
                <Navigate to='/intro' />
              )
            }
          />
          <Route
            path='/intro'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <Introv2 />
              )
            }
          >
            <Route path='intro' element={<Introv2 />} />
          </Route>
          <Route
            path='/configuration'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <Configuration />
              )
            }
          >
            <Route path='help/general' element={<GeneralHelp />}></Route>
            <Route path='help/hyperflow' element={<HyperflowHelp />}></Route>
            <Route path='help/coreid' element={<CoreIdHelp />}></Route>´
            <Route path='help/magicforms' element={<MagicFormsHelp />}></Route>
            <Route path='help/firma' element={<FirmaHelp />}></Route>
            <Route path='help/pagares' element={<PagaresHelp />}></Route>
            <Route
              path='help/configuracion'
              element={<ConfiguracionHelp />}
            ></Route>
            <Route path='account' element={<Account />}></Route>
            <Route
              path='actionHistory'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["users.admin", "users.audit"],
                <ActionHistory />
              )}
            ></Route>
            <Route
              path='users'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["users.view"],
                <Users />
              )}
            ></Route>
            <Route
              path='users/create'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["users.create"],
                <CreateUser />
              )}
            />
            <Route
              path='users/:id'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["users.view"],
                <UserDetail />
              )}
            />
            <Route
              path='subclients'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["clients.view", "clients.admin"],
                <SubClients />
              )}
            />
            <Route
              path='subclients/:id'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["clients.view", "clients.admin"],
                <SubClientDetail />
              )}
            />
            <Route
              path='subclients/edit/:id'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["clients.view", "clients.admin", "clients.edit"],
                <EditSubclient />
              )}
            />
            <Route
              path='subclients/create'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["clients.view", "clients.admin", "clients.create"],
                <CreateSubClient />
              )}
            />
          </Route>
          <Route
            path='/search/'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <IntroSearch />
              )
            }
          >
            <Route path=':query' element={<ListBusquedas />} />
          </Route>
          <Route
            path='/services'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <Navigate to='/intro' />
              )
            }
          />
          <Route
            path='/services/coreId/'
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["coreid.view", "coreid.admin"],
              <CoreId />
            )}
          >
            <Route path='registrations' element={<Registrations />} />
            <Route path='registrations/:id' element={<Registration />} />
            {/* <Route path="flow" element={<Flujos />} /> */}
            <Route path='alerts' element={<CoreIdAlerts />} />
            <Route
              path='analytics'
              element={
                roles.includes("analytics.admin") ||
                roles.includes("analytics.coreid.view") ? (
                  <AnalyticsCoreId />
                ) : (
                  <Navigate to='/' />
                )
              }
            />
            <Route
              path='manualLists'
              element={redirectBasedOnRolesOrCookies(
                cookies,
                roles,
                ["lists.view", "lists.consult"],
                <ConsultaManual />
              )}
            />
          </Route>
          <Route
            path='/services/magicForms/'
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["magicforms.view", "magicforms.admin"],
              <MagicForms />
            )}
          >
            <Route path='templates' element={<TemplatesComponent />} />
            <Route path='filledForms' element={<FilledFormsComponent />} />
            <Route path='filledForms/:id' element={<FilledFormsDetail />} />
            <Route path='templates/:id' element={<TemplateDetail />} />
            <Route
              path='analytics'
              element={
                roles.includes("analytics.admin") ||
                roles.includes("analytics.magicforms.view") ? (
                  <AnalyticsMagicForms />
                ) : (
                  <Navigate to='/' />
                )
              }
            />
          </Route>

          <Route
            path='/services/electronicSignatureNew/'
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["firma.view", "firma.admin"],
              <FirmaElectronicaNew />
            )}
          >
            <Route path='signatures' element={<FirmasNew />} />
            <Route path='signatures/:id' element={<DetailFirmaNew />} />
            <Route
              path='signatures/:id/signer/:signerId'
              element={<SignerDetail />}
            />
            <Route
              path='analytics'
              element={
                roles.includes("analytics.admin") ||
                roles.includes("analytics.firma.view") ? (
                  <AnalyticsFirma />
                ) : (
                  <Navigate to='/' />
                )
              }
            />
          </Route>
          <Route
            path='/services/pagares/'
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["pagare.view"],
              <Pagares />
            )}
          >
            <Route path='list' element={<PagaresList />} />
            <Route path='list/:id' element={<DetailPagare />} />
            <Route path='fill/:id' element={<PagareFill />} />
            <Route path='endosar/:id' element={<PagareEndoso />} />
            <Route path='create' element={<PagareCreate />} />
            <Route
              path='analytics'
              element={
                roles.includes("analytics.admin") ||
                roles.includes("analytics.pagares.view") ? (
                  <AnalyticsPagares />
                ) : (
                  <Navigate to='/' />
                )
              }
            />
          </Route>
          <Route
            path='/services/hyperFlow/'
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["hyperflow.view", "hyperflow.admin"],
              <HyperFlow />
            )}
          >
            <Route path='flows' element={<FlowsList />} />
            <Route
              path='analytics'
              element={
                roles.includes("analytics.admin") ||
                roles.includes("analytics.hyperflow.view") ? (
                  <AnalyticsHyperFlow />
                ) : (
                  <Navigate to='/' />
                )
              }
            />
          </Route>

          <Route
            path='/services/hyperFlow/'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <HyperFlowDetail />
              )
            }
          >
            <Route path='flows/:id' element={<Flowv2 />} />
          </Route>
          <Route
            path='/services/smartFlow/'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <SmartFlow />
              )
            }
          >
            <Route path='flows' element={<Flows />} />
            <Route path='flows/:id' element={<Flow />} />
            <Route
              path='analytics'
              element={
                roles.includes("analytics.admin") ||
                roles.includes("analytics.smartflow.view") ? (
                  <AnalyticsSmartFlow />
                ) : (
                  <Navigate to='/' />
                )
              }
            />
          </Route>
          {/* Authentikator */}
          <Route
            path='/services/authentikator/'
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["authentikator.view", "authentikator.admin"],
              <AuthentikatorSideBar />
            )}
          >
            <Route path='processes' element={<AuthentikatorProcessList />} />
            <Route path='processes/:id' element={<AuthentikatorProcessDetail />} />
          </Route>
          <Route
            path='services/alerts'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <UnifiedAlerts />
              )
            }
          />
          <Route
            path='/*'
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to='/login' />
              ) : (
                <Page404 />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

function redirectBasedOnRolesOrCookies(
  cookies: { token?: any },
  roles: string[],
  allowedRoles: string[],
  routeComponent: JSX.Element
) {
  if (!cookies.token || cookies.token === "") {
    return <Navigate to='/login' />;
  }
  for (const allowedRole of allowedRoles) {
    if (roles.includes(allowedRole)) {
      return routeComponent;
    }
  }
  return <Navigate to='/' />;
}

export default App;
