import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useAppDispatch } from "../../../../app/hooks";
import { LoadingStatus } from "../../../common/commonSlice";
import { UserData, UserDataErrorMessage } from "../../configurationAPI";

export interface AccountViewProps {
  accountData: UserData | null;
  errorAccountData: UserDataErrorMessage;
  width: string;
  loading: LoadingStatus;
  confirmChanges: Function;
  changePassword: Function;
  resetPasswordFunction?: Function;
  resetsPassword?: boolean;
  resetPasswordLoading?: LoadingStatus;
  changeInfoLoading?: LoadingStatus;
  changeErrorData: (payload: UserDataErrorMessage) => {
    payload: UserDataErrorMessage;
    type: string;
  };
  changeUserData: (payload: UserData | null) => {
    payload: UserData | null;
    type: string;
  };
}

export const AccountView = (props: AccountViewProps) => {
  let dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState(false);

  const [userDataTemp, setUserDataTemp] = useState<UserData | null>();

  const confirmDisabled = () => {
    return (
      props.errorAccountData.emailError !== "" ||
      props.errorAccountData.nameError !== "" ||
      props.errorAccountData.identificationError !== "" ||
      props.errorAccountData.phoneNumberError !== ""
    );
  };

  const checkIfChanged = () => {
    if (userDataTemp && props.accountData) {
      return (
        userDataTemp.name !== props.accountData.name ||
        userDataTemp.identification !== props.accountData.identification ||
        userDataTemp.phoneNumber !== props.accountData.phoneNumber
      );
    } else {
      return false;
    }
  };

  const checkErrorData = (userData: UserData) => {
    let nameError = "";
    let identificationError = "";
    let phoneNumberError = "";

    if (!userData.name || userData.name.length === 0) {
      nameError = "Debes ingresar un nombre";
    } else if (userData.name.length > 20) {
      nameError = "El nombre es muy largo";
    } else {
      nameError = "";
    }

    if (!userData.identification || userData.identification.length === 0) {
      identificationError = "Debes ingresar un documento";
    } else if (
      userData.identification.length < 4 ||
      userData.identification.length > 15
    ) {
      identificationError = "Ingresa un documento válido";
    } else {
      identificationError = "";
    }
    if (!userData.phoneNumber || userData.phoneNumber.length === 0) {
      phoneNumberError = "Debes ingresar un documento";
    } else if (
      userData.phoneNumber.length < 7 ||
      userData.phoneNumber.length > 15
    ) {
      phoneNumberError = "Ingresa un número de teléfono válido";
    } else {
      phoneNumberError = "";
    }

    dispatch(
      props.changeErrorData({
        emailError: "",
        identificationError,
        nameError,
        phoneNumberError,
      })
    );
  };

  useEffect(() => {
    if (props.accountData && !userDataTemp) {
      setUserDataTemp(props.accountData);
    }
  }, [props.accountData, userDataTemp]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: props.width,
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "8px 0px 8px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: "15px",
          backgroundColor: "rgba(0,0,0,0.4)",
          color: "rgba(255,255,255, 0.35)",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        Información básica
      </div>
      <div
        style={{
          width: "100%",
          padding: "8px 0px 8px 16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          fontSize: "13px",
          backgroundColor: "rgba(0,0,0,0.2)",
          minHeight: "280px",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        {props.loading === "pending" ? (
          <ClipLoader color="#FFF" size="120px" />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <label
                  htmlFor="name"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Nombre
                  </p>
                  <input
                    disabled={!editInfo}
                    id="name"
                    name="name"
                    onChange={(e) => {
                      dispatch(
                        props.changeUserData({
                          ...props.accountData!,
                          name: e.target.value,
                        })
                      );
                      checkErrorData({
                        ...props.accountData!,
                        name: e.target.value,
                      });
                    }}
                    type="text"
                    value={props.accountData?.name || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "end",
                      margin: "2px 2px 0 0",
                      visibility: props.errorAccountData.nameError
                        ? "hidden"
                        : "visible",
                    }}
                  >
                    {props.errorAccountData.nameError}
                  </p>
                </label>
                <label
                  htmlFor="email"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Correo electrónico
                  </p>
                  <input
                    disabled={true}
                    id="email"
                    name="email"
                    type="email"
                    value={props.accountData?.email || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />

                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "end",
                      margin: "2px 2px 0 0",
                      visibility: props.errorAccountData.emailError
                        ? "hidden"
                        : "visible",
                    }}
                  >
                    {props.errorAccountData.emailError}
                  </p>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <label
                  htmlFor="identification"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    No. Documento
                  </p>
                  <input
                    onChange={(e) => {
                      const regex = new RegExp(/^\d+$/);
                      if (
                        regex.test(e.target.value) ||
                        e.target.value.length === 0
                      ) {
                        dispatch(
                          props.changeUserData({
                            ...props.accountData!,
                            identification: e.target.value,
                          })
                        );
                        checkErrorData({
                          ...props.accountData!,
                          identification: e.target.value,
                        });
                      }
                    }}
                    disabled={!editInfo}
                    id="identification"
                    name="identification"
                    type="text"
                    value={props.accountData?.identification || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                  {props.errorAccountData.identificationError && (
                    <p
                      style={{
                        fontSize: "10px",
                        color: "rgba(255, 105, 105, 0.7)",
                        fontWeight: "400",
                        textAlign: "end",
                        margin: "2px 2px 0 0",
                      }}
                    >
                      {props.errorAccountData.identificationError}
                    </p>
                  )}
                </label>
                <label
                  htmlFor="phoneNumber"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Número celular
                  </p>
                  <input
                    disabled={!editInfo}
                    onChange={(e) => {
                      const regex = new RegExp(/^\d+$/);
                      if (
                        regex.test(e.target.value) ||
                        e.target.value.length === 0
                      ) {
                        dispatch(
                          props.changeUserData({
                            ...props.accountData!,
                            phoneNumber: e.target.value,
                          })
                        );
                        checkErrorData({
                          ...props.accountData!,
                          phoneNumber: e.target.value,
                        });
                      }
                    }}
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    value={props.accountData?.phoneNumber || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                  {props.errorAccountData.phoneNumberError && (
                    <p
                      style={{
                        fontSize: "10px",
                        color: "rgba(255, 105, 105, 0.7)",
                        fontWeight: "400",
                        textAlign: "end",
                        margin: "2px 2px 0 0",
                      }}
                    >
                      {props.errorAccountData.phoneNumberError}
                    </p>
                  )}
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "18px",
                marginRight: "16px",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (editInfo) {
                    dispatch(
                      props.changeUserData({
                        ...props.accountData!,
                        name: userDataTemp?.name!,
                        identification: userDataTemp?.identification!,
                        phoneNumber: userDataTemp?.phoneNumber!,
                      })
                    );
                    dispatch(
                      props.changeErrorData({
                        emailError: "",
                        identificationError: "",
                        nameError: "",
                        phoneNumberError: "",
                      })
                    );
                  }
                  setEditInfo(!editInfo);
                }}
                style={{
                  width: "115px",
                  padding: "5px 0px 5px 0px",
                  textAlign: "center",
                  color: "#FFF",
                  background: "rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  border: "none",
                  maxWidth: "120px",
                }}
              >
                {editInfo ? (
                  <>Cancelar</>
                ) : (
                  <>
                    Editar <br />
                    información
                  </>
                )}
              </button>
              {checkIfChanged() && (
                <>
                  {props.resetPasswordLoading &&
                  props.resetPasswordLoading === "pending" ? (
                    <ClipLoader color="#FFF" size={"25px"} />
                  ) : (
                    <button
                      disabled={confirmDisabled()}
                      onClick={(e) => {
                        e.preventDefault();
                        props.confirmChanges();
                      }}
                      style={{
                        width: "115px",
                        padding: "5px 0px 5px 0px",
                        textAlign: "center",
                        color: "#FFF",
                        background: "rgba(0,0,0,0.1)",
                        borderRadius: "8px",
                        border: "none",
                        maxWidth: "120px",
                        visibility: editInfo ? "visible" : "hidden",
                      }}
                    >
                      Confirmar <br />
                      datos
                    </button>
                  )}
                </>
              )}
              {props.resetsPassword ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.resetPasswordFunction &&
                      props.resetPasswordFunction();
                  }}
                  style={{
                    width: "115px",
                    padding: "5px 0px 5px 0px",
                    textAlign: "center",
                    color: "#FFF",
                    background: "rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                    border: "none",
                    maxWidth: "120px",
                  }}
                >
                  {props.resetPasswordLoading &&
                  props.resetPasswordLoading === "pending" ? (
                    <ClipLoader color="#FFF" size={"25px"} />
                  ) : (
                    <span>
                      Restablecer <br />
                      contraseña
                    </span>
                  )}
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.changePassword();
                  }}
                  style={{
                    width: "115px",
                    padding: "5px 0px 5px 0px",
                    textAlign: "center",
                    color: "#FFF",
                    background: "rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                    border: "none",
                    maxWidth: "120px",
                  }}
                >
                  Cambiar <br />
                  contraseña
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
