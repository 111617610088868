import { LoadingStatus } from "../../../../common/commonSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router-dom";
import { File } from "../../../firma/firmaAPI";

export interface FirmaImagesProps {
  currentImages: File[];
  loadingImages: LoadingStatus;
  verificationMethod: string;
  userId?: string;
}

let getImages = (imagesParam: File[]) => {
  let imageList: { original: string; originalHeight: number }[] = [];
  for (let i = 0; i < imagesParam.length; i++) {
    imageList.push({
      original: `data:image/png;base64,${imagesParam[i].documentBase64}`,
      originalHeight: 320,
    });
  }
  return imageList;
};

export const FirmarImagesNew = (props: FirmaImagesProps) => {
  let navigate = useNavigate();
  let getFirmaImages = (
    images: File[],
    loadingImages: LoadingStatus,
    verificationMethod: string
  ) => {
    switch (loadingImages) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingImages === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        if (verificationMethod === "CoreId") {
          return (
            <div
              onClick={() => {
                navigate(`/services/coreId/registrations/${props.userId}`);
              }}
              className="flex-row hover-white "
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Se autenticó con verificación de identidad. Para mas información
              click aquí
            </div>
          );
        } else if (verificationMethod !== "FaceMatch") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se autenticó con Biometría Facial
            </div>
          );
        } else {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No pudimos cargar las imagenes
            </div>
          );
        }
      case "resolved":
        if (verificationMethod === "CoreId") {
          return (
            <div
              onClick={() => {
                navigate(`/services/coreId/registrations/${props.userId}`);
              }}
              className="flex-row hover-white "
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Se autenticó con verificación de identidad. Para mas información
              click aquí
            </div>
          );
        } else if (verificationMethod !== "FaceMatch") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se autenticó con Biometría Facial
            </div>
          );
        } else {
          return (
            <div className="flex-row" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showNav={false}
                  infinite
                  showThumbnails={false}
                  showBullets
                  items={getImages(images)}
                />
              </div>
            </div>
          );
        }
      default:
        return (
          <div className="flex-row" style={{ width: "100%" }}>
            Imagenes de prueba
          </div>
        );
    }
  };

  return (
    <>
      {getFirmaImages(
        props.currentImages,
        props.loadingImages,
        props.verificationMethod
      )}
    </>
  );
};
