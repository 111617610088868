import { ReactComponent as DownArrow } from "../../../../Assets/common/images/downArrow.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  downloadFile,
  getDocumentsPagare,
  getPagareById,
  resetPagareDetail,
  selectCurrentDocumentsPagare,
  selectCurrentPagare,
  selectDownloadingStatus,
  selectLoadingDocuments,
  selectLoadingPagares,
} from "../pagareSlice";
import ClipLoader from "react-spinners/ClipLoader";
import {
  LoadingStatus,
  selectClientId,
  selectRoles,
} from "../../../common/commonSlice";
import {
  MediumSquare,
  SmallSquare,
} from "../../firma/detail/components/Squares";
import {
  getStatusPagareName,
  getTipoPagareName,
} from "../../firma/helpers/utils";
import fileDownload from "js-file-download";
import { GeneratedDocumentsPagare } from "../pagareAPI";
import {
  base64PDFToBlobUrl,
  formatDateStandar,
} from "../../../../helpers/utils";
import { getActionString } from "../utils";
import Modal, { Styles } from "react-modal";

Modal.setAppElement("#root");
export const DetailPagare = () => {
  const customStylesModalDeudores: Styles = {
    content: {
      top: "50%",
      left: "60%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "1.2%",
      width: "60%",
      overflow: "auto",
    },
    overlay: {
      background: "rgba(0,0,0,0.3)",
    },
  };
  let loadingStatus = useAppSelector(selectLoadingPagares);
  let documentsLoading = useAppSelector(selectLoadingDocuments);
  let downloading = useAppSelector(selectDownloadingStatus);
  let currentPagare = useAppSelector(selectCurrentPagare);
  let documents = useAppSelector(selectCurrentDocumentsPagare);
  let roles = useAppSelector(selectRoles);
  let clientId = useAppSelector(selectClientId);

  const [documentToVisualize, setDocumentToVisualize] = useState<{
    documentBase64: string;
    documentName: string;
    url: string;
  }>({
    documentBase64: "",
    documentName: "",
    url: "",
  });

  const [deudoresModalOpen, setDeudoresModalOpen] = useState(false);

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let downloadAtatchment = (fileName: string, index: number) => {
    dispatch(downloadFile({ fileName, index }));
  };

  const canBeEndosado = () => {
    if (currentPagare && roles.includes("pagare.endosos")) {
      if (
        currentPagare.statusData.hasBeenSigned &&
        currentPagare.currentClientId == clientId &&
        currentPagare.status !== "BlockedByEndosoValidation" 
        && currentPagare.status !== "Nullified"
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const canBeFilledOut = () => {
    if (currentPagare && roles.includes("pagare.fill")) {
      if (
        currentPagare.statusData.hasBeenSigned &&
        !currentPagare.statusData.hasBeenFilled &&
        currentPagare.currentClientId == clientId &&
        currentPagare.type === "Blank"
        && currentPagare.status !== "Nullified"
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  let getOtherDeudores = () => {
    let otherDeudores = [];
    if (currentPagare && currentPagare?.deudores.length > 1) {
      otherDeudores = currentPagare?.deudores.slice(1);
      return (
        <div
          className="flex-col"
          style={{ justifyContent: "space-evenly", marginTop: "10px" }}
        >
          <div className="flex-row" style={{ justifyContent: "space-evenly" }}>
            <h6 style={{ color: "black", fontWeight: 600 }}>Nombre</h6>
            <h6 style={{ color: "black", fontWeight: 600 }}>Apellido</h6>
            <h6 style={{ color: "black", fontWeight: 600 }}>Identificación</h6>
            <h6 style={{ color: "black", fontWeight: 600 }}>Correo</h6>
            <h6 style={{ color: "black", fontWeight: 600 }}>Celular</h6>
          </div>
          {otherDeudores.map((deudor, index) => {
            return (
              <div
                key={index}
                className="flex-row"
                style={{ justifyContent: "space-evenly" }}
              >
                <h6
                  style={{ color: "black", fontWeight: 500, fontSize: "15px" }}
                >
                  {deudor.nombre}
                </h6>
                <h6
                  style={{ color: "black", fontWeight: 500, fontSize: "15px" }}
                >
                  {deudor.apellido}
                </h6>
                <h6
                  style={{ color: "black", fontWeight: 500, fontSize: "15px" }}
                >
                  {deudor.identification}
                </h6>
                <h6
                  style={{ color: "black", fontWeight: 500, fontSize: "15px" }}
                >
                  {deudor.email}
                </h6>
                <h6
                  style={{ color: "black", fontWeight: 500, fontSize: "15px" }}
                >
                  {deudor.phoneNumber}
                </h6>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  let getDocument = (
    loadingDocuments: LoadingStatus,
    generatedDocuments: GeneratedDocumentsPagare,
    documentType: "Pagare" | "Carta" | "Certificate",
    status: string,
    setDocumentVisualizer: React.Dispatch<
      React.SetStateAction<{
        documentBase64: string;
        documentName: string;
        url: string;
      }>
    >
  ) => {
    switch (loadingDocuments) {
      case "pending":
        return (
          <LoadingOverlay
            active={documentsLoading === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "resolved":
        if (currentPagare?.status == "Created") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha firmado el pagaré
            </div>
          );
        } else {
          if (documentType === "Carta") {
            return (
              <>
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    let url = base64PDFToBlobUrl(
                      generatedDocuments.carta.documentBase64
                    );
                    setDocumentVisualizer({
                      documentBase64: generatedDocuments.carta.documentBase64,
                      documentName: generatedDocuments.carta.documentName,
                      url,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "80%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.carta.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          generatedDocuments.carta.documentName
                        );
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "80%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          } else if (documentType == "Pagare") {
            return (
              <>
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    let url = base64PDFToBlobUrl(
                      generatedDocuments.pagare.documentBase64
                    );
                    setDocumentVisualizer({
                      documentBase64: generatedDocuments.pagare.documentBase64,
                      documentName: generatedDocuments.pagare.documentName,
                      url,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "80%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.pagare.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          generatedDocuments.pagare.documentName
                        );
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "80%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          } else {
            return <></>;
          }
        }
      case "rejected":
        if (status !== "Signed") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha firmado el pagaré
            </div>
          );
        } else {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No pudimos cargar los documentos
            </div>
          );
        }
      default:
        return (
          <>
            <div
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginBottom: "2%",
              }}
            >
              <div style={{ marginRight: "5%" }}>Visualizar</div>
              <EyeIcon />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "80%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <div style={{ marginRight: "5%" }}>Descargar</div>
              <EyeIcon />
            </div>
          </>
        );
    }
  };

  let { id } = useParams();

  useEffect(() => {
    if ((!currentPagare || currentPagare.id !== id) && id !== "") {
      dispatch(getPagareById(id || ""));
    }

    if (currentPagare && documentsLoading === "idle") {
      dispatch(getDocumentsPagare(currentPagare.id));
    }

    dispatch(changeActiveComponent("none"));

    return () => {};
  }, [id, currentPagare]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      <Modal
        isOpen={deudoresModalOpen}
        style={customStylesModalDeudores}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setDeudoresModalOpen(false);
        }}
      >
        <div className="flex-col">
          <div
            className="text-large text-bold"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Codeudores
          </div>
          {getOtherDeudores()}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setDeudoresModalOpen(false);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>
      {currentPagare && loadingStatus === "resolved" ? (
        <div
          className="flex-col"
          style={{ height: "100%", overflow: "auto", width: "100%" }}
        >
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "5%",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetPagareDetail());
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar a lista de pagarés
              </div>
            </div>
          </div>
          <div className="flex-row" style={{ height: "100%", width: "100%" }}>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "50%",
                justifyContent: "start",
                padding: "1%",
                marginTop: "5%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  justifyContent: "start",
                  height: "14%",
                }}
              >
                <SmallSquare
                  parentWidth="42%"
                  title="Tipo de Pagaré"
                  content={<div>{getTipoPagareName(currentPagare.type)}</div>}
                />
                <SmallSquare
                  parentWidth="26%"
                  title="Estado"
                  content={
                    <div>{getStatusPagareName(currentPagare.status)}</div>
                  }
                />
                <SmallSquare
                  parentWidth="26%"
                  title="Valor"
                  content={
                    <div>
                      {`${
                        currentPagare.value === "0"
                          ? "N/A"
                          : currentPagare.value
                      } ${
                        currentPagare.currency && currentPagare.currency !== ""
                          ? currentPagare.currency
                          : ""
                      }`}
                    </div>
                  }
                />
              </div>
              <div
                className="flex-col"
                style={{
                  width: "96%",
                  height: "35%",
                  marginRight: "1%",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "29px",
                    textAlignLast: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <div className="text-normal text-medium primary-color-35">
                    Datos del deudor
                  </div>
                </div>
                {currentPagare.deudores[0] ? (
                  <div
                    className="flex-col"
                    style={{
                      textAlign: "center",
                      color: "white",
                      background: "rgba(0,0,0,0.2)",
                      justifyContent: "center",
                      alignItems: "start",
                      boxSizing: "border-box",
                      paddingTop: "20px",
                      paddingBottom: "5px",
                      paddingLeft: "4%",
                      paddingRight: "4%",
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="flex-col"
                        style={{ width: "40%", alignItems: "start" }}
                      >
                        <div className="text-x-thin text-medium  primary-color">
                          Nombre:
                        </div>
                        <div className="text-x-thin text-medium  primary-color">
                          Apellido:
                        </div>
                        <div className="text-x-thin text-medium  primary-color">
                          No. de documento:
                        </div>
                        <div className="text-x-thin text-medium  primary-color">
                          No. de celular:
                        </div>
                        <div className="text-x-thin text-medium  primary-color">
                          Email:
                        </div>
                        <div className="text-x-thin text-medium  primary-color">
                          User ID:
                        </div>
                      </div>
                      <div
                        className="flex-col"
                        style={{ width: "55%", alignItems: "start" }}
                      >
                        <div className="text-x-thin text-medium primary-color">
                          {currentPagare.deudores[0].nombre}
                        </div>
                        <div className="text-x-thin text-medium primary-color">
                          {currentPagare.deudores[0].apellido}
                        </div>
                        <div className="text-x-thin text-medium primary-color">
                          {currentPagare.deudores[0].identification}
                        </div>
                        <div className="text-x-thin text-medium primary-color">
                          {currentPagare.deudores[0].phoneNumber}
                        </div>
                        <div className="text-x-thin text-medium primary-color">
                          {currentPagare.deudores[0].email}
                        </div>
                        <div className="text-x-thin text-medium primary-color">
                          {currentPagare.deudores[0].userId}
                        </div>
                      </div>
                    </div>
                    {currentPagare && currentPagare.deudores.length > 1 && (
                      <button
                        style={{
                          background: "rgba(0,0,0,0.4)",
                          borderRadius: "6px",
                          marginTop: "10px",
                          color: "white",
                          textAlign: "center",
                          border: "none",
                          fontSize: "12px",
                          fontWeight: "600",

                          padding: "3px 10px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setDeudoresModalOpen(true);
                        }}
                      >
                        Ver codeudores
                      </button>
                    )}
                  </div>
                ) : (
                  <div
                    className="flex-row"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      color: "white",
                      background: "rgba(0,0,0,0.2)",

                      boxSizing: "border-box",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: "7%",
                      paddingRight: "7%",
                    }}
                  >
                    <h6 style={{ color: "white" }}>
                      No se ha asignado un deudor a este pagaré
                    </h6>
                  </div>
                )}
              </div>
              <div
                className="flex-row"
                style={{
                  height: "20%",
                }}
              >
                <div
                  className="flex-row"
                  style={{
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <MediumSquare
                    parentWidth="32%"
                    title="Pagaré"
                    content={getDocument(
                      documentsLoading,
                      documents!,
                      "Pagare",
                      currentPagare.status,
                      setDocumentToVisualize
                    )}
                  />
                  {documents &&
                    documents.carta &&
                    documents.carta.documentBase64 && (
                      <MediumSquare
                        parentWidth="32%"
                        title="Carta de instrucciones"
                        content={getDocument(
                          documentsLoading,
                          documents!,
                          "Carta",
                          currentPagare.status,
                          setDocumentToVisualize
                        )}
                      />
                    )}
                </div>
              </div>
            </div>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "50%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  height: "10%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "6px",
                    color: "white",
                    textAlign: "center",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "600",
                    height: "40px",
                    width: "30%",
                    marginRight: "1%",
                    visibility: canBeFilledOut() ? "visible" : "hidden",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/services/pagares/fill/${currentPagare?.id}`);
                  }}
                >
                  Diligenciar
                </button>
                <button
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "6px",
                    color: "white",
                    textAlign: "center",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "600",
                    height: "40px",
                    width: "30%",
                    visibility: canBeEndosado() ? "visible" : "hidden",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/services/pagares/endosar/${currentPagare?.id}`);
                  }}
                >
                  Endosar
                </button>
                <button
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "6px",
                    color: "white",
                    textAlign: "center",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "600",
                    height: "40px",
                    width: "30%",
                    visibility:
                      // (roles.includes("pagare.endosos") &&
                      //   currentPagare.status === "Bloqueado por endoso") ||
                      // currentPagare.status === "BlockedByEndosoValidation"
                      //   ? "visible"
                      //   : "hidden",
                      "hidden",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/services/pagares/endosar/${currentPagare?.id}`);
                  }}
                >
                  Cancelar endoso
                </button>
              </div>
              <div
                className="flex-col"
                style={{
                  width: "96%",
                  height: "35%",
                  marginTop: "1%",
                  marginBottom: "2%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "29px",
                    textAlignLast: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <div className="text-normal text-medium primary-color-35">
                    Historial pagaré
                  </div>
                </div>
                <div
                  className="flex-col"
                  style={{
                    textAlign: "center",
                    color: "white",
                    background: "rgba(0,0,0,0.2)",
                    justifyContent: "center",
                    alignItems: "start",
                    boxSizing: "border-box",
                    maxHeight: "240px",
                    overflow: "auto",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      overflow: "auto",
                    }}
                  >
                    <thead
                      className="text-normal text-medium primary-color-35"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "30px",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <th></th>
                        <th>Acción</th>
                        <th>Fecha</th>
                        <th>Descripción</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody
                      className="text-normal text-x-small primary-color"
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {currentPagare.ledgerAnnotations.map((annotation, i) => {
                        return (
                          <tr
                            key={i}
                            className="list-hover"
                            style={{ height: "30px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <td></td>
                            <td>{getActionString(annotation.action)}</td>
                            <td>{formatDateStandar(annotation.fecha)}</td>
                            <td>{annotation.descripcion}</td>
                            <td></td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ width: "80%" }}>
                {documents?.extraDocuments &&
                  documents.extraDocuments.length > 0 && (
                    <>
                      <div
                        className="flex-col"
                        style={{
                          background: "rgba(0,0,0,0.4)",
                          height: "29px",
                          textAlignLast: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          boxSizing: "border-box",
                          borderRadius: "8px 8px 0 0 ",
                        }}
                      >
                        <div className="text-normal text-medium primary-color-35">
                          Otros documentos
                        </div>
                      </div>
                      <div
                        className="flex-col"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          paddingTop: "10px",
                          borderRadius: "0 0 8px 8px",
                          boxSizing: "border-box",

                          paddingBottom: "1%",
                        }}
                      >
                        <table style={{ borderCollapse: "collapse" }}>
                          <thead
                            className="text-normal text-medium primary-color-35"
                            style={{
                              background: "rgba(0,0,0,0.4)",
                              height: "30px",
                              textAlign: "center",
                            }}
                          >
                            <tr>
                              <th></th>
                              <th>Nombre</th>
                              <th>Descargar</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody
                            className="text-normal text-x-small primary-color"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {documents.extraDocuments.map(
                              (extraDocument, i) => {
                                return (
                                  <tr key={i} style={{ height: "30px" }}>
                                    <td></td>
                                    <td style={{ textTransform: "uppercase" }}>
                                      {extraDocument.name || ""}
                                    </td>

                                    <td>
                                      {downloading[i] === "pending" ? (
                                        <LoadingOverlay
                                          active={downloading[i] === "pending"}
                                          spinner={
                                            <ClipLoader
                                              color="white"
                                              size="20px"
                                            />
                                          }
                                        />
                                      ) : (
                                        <DownArrow
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            downloadAtatchment(
                                              extraDocument.fileName,
                                              i
                                            );
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                );
                              }
                            )}
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
              </div>
            </div>
            {documentToVisualize.documentBase64 !== "" ? (
              <div
                className="flex-col"
                style={{
                  position: "absolute",
                  left: "1%",
                  top: "10%",
                  height: "100%",
                  width: "100%",
                }}
              >
                <button
                  onClick={() =>
                    setDocumentToVisualize({
                      documentBase64: "",
                      documentName: "",
                      url: "",
                    })
                  }
                  style={{
                    border: "none",
                    textAlign: "center",
                    borderRadius: "8px",
                    fontSize: "25px",
                    background: "#1A617D",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingRight: "10%",
                    paddingLeft: "10%",
                    color: "white",
                  }}
                >
                  Cerrar <span style={{ textAlign: "end" }}>X</span>
                </button>
                <object
                  data={documentToVisualize.url}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  {" "}
                  <p>
                    Tu navegador no permite visualizar PDFs puedes descargar el
                    documento aquí
                    <a
                      onClick={() => {
                        fetch(documentToVisualize.url)
                          .then((res) => res.blob())
                          .then((blob) => {
                            fileDownload(
                              blob,
                              "Documento.pdf",
                              "application/pdf"
                            );
                          });
                      }}
                    >
                      Descargar
                    </a>
                  </p>
                </object>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
